
 
.ys-webrtc-sdk-ui .dial-wra {
    margin-right: 12px;
    padding-left: 14px;
    display: flex;
    width: 224px;
    height: 28px;
    border-radius: 16px;
    background: #f3f3f3;
    position: relative;
  }
  .ys-webrtc-sdk-ui .dial-wra .item-ipt {
    flex: 1;
  }
  .ys-webrtc-sdk-ui .dial-wra .item-ipt .ant-input-suffix {
    position: absolute;
    right: -2px;
    top: 5px;
  }
  .ys-webrtc-sdk-ui .dial-wra .item-ipt .ant-input-suffix .anticon {
    font-size: 12px;
    color: rgba(255, 255, 255);
    background-color: rgba(182, 182, 182);
    border-radius: 50%;
  }
  .ys-webrtc-sdk-ui .dial-wra .item-ipt .ant-input-suffix .anticon:hover {
    background-color: rgba(0, 0, 0, 0.45);
  }
  .ys-webrtc-sdk-ui .dial-wra .ysd-input {
    padding: 0;
    height: 28px;
    background-color: transparent;
    border: none;
  }
  .ys-webrtc-sdk-ui .dial-wra .ysd-input .ant-input {
    background-color: inherit;
    padding-right: 14px;
  }
  .ys-webrtc-sdk-ui .dial-wra .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
    border: unset;
    outline: 0;
    box-shadow: unset;
  }
  .ys-webrtc-sdk-ui .dial-wra .ant-input-affix-wrapper:hover {
    border-color: unset;
    border-right-width: unset !important;
  }
  .ys-webrtc-sdk-ui .dial-wra .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: unset;
  }
  .ys-webrtc-sdk-ui .dial-wra .item-ipt:focus,
  .ys-webrtc-sdk-ui .dial-wra .item-ipt:hover {
    border: unset;
    outline: 0;
    box-shadow: unset;
  }
  .ys-webrtc-sdk-ui .dial-wra .keyboard-icon {
    margin: 3px;
    padding: 2px 3px;
    font-size: 18px;
    color: #999;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  }
  .ys-webrtc-sdk-ui .dial-wra .keyboard-icon:hover,
  .ys-webrtc-sdk-ui .dial-wra .keyboard-icon.active {
    color: #0081cc;
  }
  .ys-webrtc-sdk-ui .dial-wra .item-loading-bg {
    width: 28px;
    height: 28px;
    border-radius: 14px;
    background-color: #0081cc;
  }
  .ys-webrtc-sdk-ui .dial-wra .item-loading-bg .item-loading {
    font-size: 24px;
    margin-top: 2px;
    display: block;
  }
  .ys-webrtc-sdk-ui .dial-wra .item-call {
    width: 28px;
    height: 28px;
    line-height: 28px;
    min-width: 28px;
    background-color: #0081cc;
  }
  .ys-webrtc-sdk-ui .dial-wra .item-call .item-icon {
    margin-left: -1px;
    font-size: 15px;
  }
  .ys-webrtc-sdk-ui .dial-wra.position {
    position: relative;
  }
  .ys-webrtc-sdk-ui .rtc-keyboard {
    /* position: absolute; */
    top: 40px !important;
    left: 10px !important;
  }
  .ys-webrtc-sdk-ui .rtc-keyboard .keyboard-pop {
    display: flex;
    padding: 10px 2px 0 13px;
    flex-wrap: wrap;
    width: 153px;
    height: 194px;
    background: #fff;
    box-shadow: 0 3px 25px #E7E8F0;
    border-radius: 5px;
    -webkit-user-select: none;
            user-select: none;
  }
  .ys-webrtc-sdk-ui .rtc-keyboard .keyboard-pop .item {
    margin-right: 11px;
    margin-bottom: 11px;
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 20px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
  }
  .ys-webrtc-sdk-ui .rtc-keyboard .keyboard-pop .item:hover {
    border-radius: 4px;
    box-shadow: 2px 2px 6px #E7E8F0;
  }
  .ys-webrtc-sdk-ui .rtc-keyboard .keyboard-pop .item:active {
    background-size: 35px 35px;
    color: #0081cc;
  }
  .ys-webrtc-sdk-ui .rtc-keyboard .keyboard-pop .item .item-num {
    height: 22px;
    line-height: 25px;
  }
  .ys-webrtc-sdk-ui .rtc-keyboard .keyboard-pop .item .item-letter {
    width: 35px;
    height: 12px;
    font-size: 8px;
    line-height: 12px;
    font-weight: 100;
    color: rgba(61, 74, 89, 0.4);
  }
  
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  .ys-webrtc-sdk-ui .scroll-bar-style {
    scrollbar-width: thin;
    scrollbar-color: #e6e9ec #fff;
    /* 定义滚动条轨道内阴影 */
    /* 定义滑块内阴影 */
    /* 滚动条 */
  }
  .ys-webrtc-sdk-ui .scroll-bar-style::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .scroll-bar-style::-webkit-scrollbar-thumb {
    background-color: #e6e9ec;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .scroll-bar-style::-webkit-scrollbar {
    width: 6px;
    padding-right: 50%;
    background-color: #fff;
    border-radius: 3px;
  }
  .ys-webrtc-sdk-ui [class^=ant-]::-ms-clear,
  .ys-webrtc-sdk-ui [class*= ant-]::-ms-clear,
  .ys-webrtc-sdk-ui [class^=ant-] input::-ms-clear,
  .ys-webrtc-sdk-ui [class*= ant-] input::-ms-clear,
  .ys-webrtc-sdk-ui [class^=ant-] input::-ms-reveal,
  .ys-webrtc-sdk-ui [class*= ant-] input::-ms-reveal {
    display: none;
  }
  .ys-webrtc-sdk-ui [class^=ant-],
  .ys-webrtc-sdk-ui [class*= ant-],
  .ys-webrtc-sdk-ui [class^=ant-] *,
  .ys-webrtc-sdk-ui [class*= ant-] *,
  .ys-webrtc-sdk-ui [class^=ant-] *::before,
  .ys-webrtc-sdk-ui [class*= ant-] *::before,
  .ys-webrtc-sdk-ui [class^=ant-] *::after,
  .ys-webrtc-sdk-ui [class*= ant-] *::after {
    box-sizing: border-box;
  }
  /* stylelint-disable at-rule-no-unknown */
  .ys-webrtc-sdk-ui html,
  .ys-webrtc-sdk-ui body {
    width: 100%;
    height: 100%;
  }
  .ys-webrtc-sdk-ui input::-ms-clear,
  .ys-webrtc-sdk-ui input::-ms-reveal {
    display: none;
  }
  .ys-webrtc-sdk-ui *,
  .ys-webrtc-sdk-ui *::before,
  .ys-webrtc-sdk-ui *::after {
    box-sizing: border-box;
  }
  .ys-webrtc-sdk-ui html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui body {
    margin: 0;
    color: #23282f;
    font-family: -apple-system, 'Helvetica Neue', 'Helvetica', 'PingFang SC', 'SegoeUI', 'Microsoft YaHei', '微软雅黑', 'Noto Sans CJK SC', 'Arial', sans-serif;
    font-variant: tabular-nums;
    background-color: #fff;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    font-size: 14px;
    line-height: 1.57142857;
  }
  .ys-webrtc-sdk-ui [tabindex='-1']:focus {
    outline: none !important;
  }
  .ys-webrtc-sdk-ui hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }
  .ys-webrtc-sdk-ui h1,
  .ys-webrtc-sdk-ui h2,
  .ys-webrtc-sdk-ui h3,
  .ys-webrtc-sdk-ui h4,
  .ys-webrtc-sdk-ui h5,
  .ys-webrtc-sdk-ui h6 {
    margin-top: 0;
    margin-bottom: 0.5em;
    color: #23282f;
    font-weight: 500;
  }
  .ys-webrtc-sdk-ui p {
    margin-top: 0;
    margin-bottom: 1em;
  }
  .ys-webrtc-sdk-ui abbr[title],
  .ys-webrtc-sdk-ui abbr[data-original-title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
            text-decoration: underline dotted;
    border-bottom: 0;
    cursor: help;
  }
  .ys-webrtc-sdk-ui address {
    margin-bottom: 1em;
    font-style: normal;
    line-height: inherit;
  }
  .ys-webrtc-sdk-ui input[type='text'],
  .ys-webrtc-sdk-ui input[type='password'],
  .ys-webrtc-sdk-ui input[type='number'],
  .ys-webrtc-sdk-ui textarea {
    -webkit-appearance: none;
  }
  .ys-webrtc-sdk-ui ol,
  .ys-webrtc-sdk-ui ul,
  .ys-webrtc-sdk-ui dl {
    margin-top: 0;
    margin-bottom: 1em;
  }
  .ys-webrtc-sdk-ui ol ol,
  .ys-webrtc-sdk-ui ul ul,
  .ys-webrtc-sdk-ui ol ul,
  .ys-webrtc-sdk-ui ul ol {
    margin-bottom: 0;
  }
  .ys-webrtc-sdk-ui dt {
    font-weight: 500;
  }
  .ys-webrtc-sdk-ui dd {
    margin-bottom: 0.5em;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui blockquote {
    margin: 0 0 1em;
  }
  .ys-webrtc-sdk-ui dfn {
    font-style: italic;
  }
  .ys-webrtc-sdk-ui b,
  .ys-webrtc-sdk-ui strong {
    font-weight: bolder;
  }
  .ys-webrtc-sdk-ui small {
    font-size: 80%;
  }
  .ys-webrtc-sdk-ui sub,
  .ys-webrtc-sdk-ui sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }
  .ys-webrtc-sdk-ui sub {
    bottom: -0.25em;
  }
  .ys-webrtc-sdk-ui sup {
    top: -0.5em;
  }
  .ys-webrtc-sdk-ui a {
    color: #006ee1;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    -webkit-text-decoration-skip: objects;
  }
  .ys-webrtc-sdk-ui a:hover {
    color: #268ded;
  }
  .ys-webrtc-sdk-ui a:active {
    color: #0054ba;
  }
  .ys-webrtc-sdk-ui a:active,
  .ys-webrtc-sdk-ui a:hover {
    text-decoration: none;
    outline: 0;
  }
  .ys-webrtc-sdk-ui a:focus {
    text-decoration: none;
    outline: 0;
  }
  .ys-webrtc-sdk-ui a[disabled] {
    color: #b1b9c3;
    cursor: not-allowed;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui pre,
  .ys-webrtc-sdk-ui code,
  .ys-webrtc-sdk-ui kbd,
  .ys-webrtc-sdk-ui samp {
    font-size: 1em;
    font-family: Courier, monospace;
  }
  .ys-webrtc-sdk-ui pre {
    margin-top: 0;
    margin-bottom: 1em;
    overflow: auto;
  }
  .ys-webrtc-sdk-ui figure {
    margin: 0 0 1em;
  }
  .ys-webrtc-sdk-ui img {
    vertical-align: middle;
    border-style: none;
  }
  .ys-webrtc-sdk-ui svg:not(:root) {
    overflow: hidden;
  }
  .ys-webrtc-sdk-ui a,
  .ys-webrtc-sdk-ui area,
  .ys-webrtc-sdk-ui button,
  .ys-webrtc-sdk-ui [role='button'],
  .ys-webrtc-sdk-ui input:not([type='range']),
  .ys-webrtc-sdk-ui label,
  .ys-webrtc-sdk-ui select,
  .ys-webrtc-sdk-ui summary,
  .ys-webrtc-sdk-ui textarea {
    touch-action: manipulation;
  }
  .ys-webrtc-sdk-ui table {
    border-collapse: collapse;
  }
  .ys-webrtc-sdk-ui caption {
    padding-top: 0.75em;
    padding-bottom: 0.3em;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
    caption-side: bottom;
  }
  .ys-webrtc-sdk-ui th {
    text-align: inherit;
  }
  .ys-webrtc-sdk-ui input,
  .ys-webrtc-sdk-ui button,
  .ys-webrtc-sdk-ui select,
  .ys-webrtc-sdk-ui optgroup,
  .ys-webrtc-sdk-ui textarea {
    margin: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
  }
  .ys-webrtc-sdk-ui button,
  .ys-webrtc-sdk-ui input {
    overflow: visible;
  }
  .ys-webrtc-sdk-ui button,
  .ys-webrtc-sdk-ui select {
    text-transform: none;
  }
  .ys-webrtc-sdk-ui button,
  .ys-webrtc-sdk-ui html [type="button"],
  .ys-webrtc-sdk-ui [type="reset"],
  .ys-webrtc-sdk-ui [type="submit"] {
    -webkit-appearance: button;
  }
  .ys-webrtc-sdk-ui button::-moz-focus-inner,
  .ys-webrtc-sdk-ui [type='button']::-moz-focus-inner,
  .ys-webrtc-sdk-ui [type='reset']::-moz-focus-inner,
  .ys-webrtc-sdk-ui [type='submit']::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }
  .ys-webrtc-sdk-ui input[type='radio'],
  .ys-webrtc-sdk-ui input[type='checkbox'] {
    box-sizing: border-box;
    padding: 0;
  }
  .ys-webrtc-sdk-ui input[type='date'],
  .ys-webrtc-sdk-ui input[type='time'],
  .ys-webrtc-sdk-ui input[type='datetime-local'],
  .ys-webrtc-sdk-ui input[type='month'] {
    -webkit-appearance: listbox;
  }
  .ys-webrtc-sdk-ui textarea {
    overflow: auto;
    resize: vertical;
  }
  .ys-webrtc-sdk-ui fieldset {
    min-width: 0;
    margin: 0;
    padding: 0;
    border: 0;
  }
  .ys-webrtc-sdk-ui legend {
    display: block;
    width: 100%;
    max-width: 100%;
    margin-bottom: 0.5em;
    padding: 0;
    color: inherit;
    font-size: 1.5em;
    line-height: inherit;
    white-space: normal;
  }
  .ys-webrtc-sdk-ui progress {
    vertical-align: baseline;
  }
  .ys-webrtc-sdk-ui [type='number']::-webkit-inner-spin-button,
  .ys-webrtc-sdk-ui [type='number']::-webkit-outer-spin-button {
    height: auto;
  }
  .ys-webrtc-sdk-ui [type='search'] {
    outline-offset: -2px;
    -webkit-appearance: none;
  }
  .ys-webrtc-sdk-ui [type='search']::-webkit-search-cancel-button,
  .ys-webrtc-sdk-ui [type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  .ys-webrtc-sdk-ui ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }
  .ys-webrtc-sdk-ui output {
    display: inline-block;
  }
  .ys-webrtc-sdk-ui summary {
    display: list-item;
  }
  .ys-webrtc-sdk-ui template {
    display: none;
  }
  .ys-webrtc-sdk-ui [hidden] {
    display: none !important;
  }
  .ys-webrtc-sdk-ui mark {
    padding: 0.2em;
    background-color: #fefff0;
  }
  .ys-webrtc-sdk-ui ::selection {
    color: #fff;
    background: #006ee1;
  }
  .ys-webrtc-sdk-ui .clearfix::before {
    display: table;
    content: '';
  }
  .ys-webrtc-sdk-ui .clearfix::after {
    display: table;
    clear: both;
    content: '';
  }
  .ys-webrtc-sdk-ui .flex {
    display: flex;
    align-items: center;
  }
  .ys-webrtc-sdk-ui .flex-item {
    flex: 1;
  }
  .ys-webrtc-sdk-ui ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: inherit;
  }
  /* 滚动槽 */
  .ys-webrtc-sdk-ui ::-webkit-scrollbar-track {
    border-radius: 100px;
  }
  /* 滚动条滑块 */
  .ys-webrtc-sdk-ui ::-webkit-scrollbar-thumb {
    background: #e6e9ec;
    border-radius: 100px;
  }
  .ys-webrtc-sdk-ui ::-webkit-scrollbar-button {
    height: 6px;
    background: transparent;
  }
  .ys-webrtc-sdk-ui .anticon {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .ys-webrtc-sdk-ui .anticon > * {
    line-height: 1;
  }
  .ys-webrtc-sdk-ui .anticon svg {
    display: inline-block;
  }
  .ys-webrtc-sdk-ui .anticon::before {
    display: none;
  }
  .ys-webrtc-sdk-ui .anticon .anticon-icon {
    display: block;
  }
  .ys-webrtc-sdk-ui .anticon[tabindex] {
    cursor: pointer;
  }
  .ys-webrtc-sdk-ui .anticon-spin::before {
    display: inline-block;
    -webkit-animation: loadingCircle 1s infinite linear;
            animation: loadingCircle 1s infinite linear;
  }
  .ys-webrtc-sdk-ui .anticon-spin {
    display: inline-block;
    -webkit-animation: loadingCircle 1s infinite linear;
            animation: loadingCircle 1s infinite linear;
  }
  .ys-webrtc-sdk-ui .ant-fade-enter,
  .ys-webrtc-sdk-ui .ant-fade-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-fade-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-fade-enter.ant-fade-enter-active,
  .ys-webrtc-sdk-ui .ant-fade-appear.ant-fade-appear-active {
    -webkit-animation-name: antFadeIn;
            animation-name: antFadeIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .ys-webrtc-sdk-ui .ant-fade-leave.ant-fade-leave-active {
    -webkit-animation-name: antFadeOut;
            animation-name: antFadeOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-fade-enter,
  .ys-webrtc-sdk-ui .ant-fade-appear {
    opacity: 0;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  .ys-webrtc-sdk-ui .ant-fade-leave {
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  @-webkit-keyframes antFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes antFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes antFadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes antFadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .ys-webrtc-sdk-ui .ant-move-up-enter,
  .ys-webrtc-sdk-ui .ant-move-up-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-move-up-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-move-up-enter.ant-move-up-enter-active,
  .ys-webrtc-sdk-ui .ant-move-up-appear.ant-move-up-appear-active {
    -webkit-animation-name: antMoveUpIn;
            animation-name: antMoveUpIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .ys-webrtc-sdk-ui .ant-move-up-leave.ant-move-up-leave-active {
    -webkit-animation-name: antMoveUpOut;
            animation-name: antMoveUpOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-move-up-enter,
  .ys-webrtc-sdk-ui .ant-move-up-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .ys-webrtc-sdk-ui .ant-move-up-leave {
    -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
            animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  }
  .ys-webrtc-sdk-ui .ant-move-down-enter,
  .ys-webrtc-sdk-ui .ant-move-down-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-move-down-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-move-down-enter.ant-move-down-enter-active,
  .ys-webrtc-sdk-ui .ant-move-down-appear.ant-move-down-appear-active {
    -webkit-animation-name: antMoveDownIn;
            animation-name: antMoveDownIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .ys-webrtc-sdk-ui .ant-move-down-leave.ant-move-down-leave-active {
    -webkit-animation-name: antMoveDownOut;
            animation-name: antMoveDownOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-move-down-enter,
  .ys-webrtc-sdk-ui .ant-move-down-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .ys-webrtc-sdk-ui .ant-move-down-leave {
    -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
            animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  }
  .ys-webrtc-sdk-ui .ant-move-left-enter,
  .ys-webrtc-sdk-ui .ant-move-left-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-move-left-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-move-left-enter.ant-move-left-enter-active,
  .ys-webrtc-sdk-ui .ant-move-left-appear.ant-move-left-appear-active {
    -webkit-animation-name: antMoveLeftIn;
            animation-name: antMoveLeftIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .ys-webrtc-sdk-ui .ant-move-left-leave.ant-move-left-leave-active {
    -webkit-animation-name: antMoveLeftOut;
            animation-name: antMoveLeftOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-move-left-enter,
  .ys-webrtc-sdk-ui .ant-move-left-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .ys-webrtc-sdk-ui .ant-move-left-leave {
    -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
            animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  }
  .ys-webrtc-sdk-ui .ant-move-right-enter,
  .ys-webrtc-sdk-ui .ant-move-right-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-move-right-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-move-right-enter.ant-move-right-enter-active,
  .ys-webrtc-sdk-ui .ant-move-right-appear.ant-move-right-appear-active {
    -webkit-animation-name: antMoveRightIn;
            animation-name: antMoveRightIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .ys-webrtc-sdk-ui .ant-move-right-leave.ant-move-right-leave-active {
    -webkit-animation-name: antMoveRightOut;
            animation-name: antMoveRightOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-move-right-enter,
  .ys-webrtc-sdk-ui .ant-move-right-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .ys-webrtc-sdk-ui .ant-move-right-leave {
    -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
            animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  }
  @-webkit-keyframes antMoveDownIn {
    0% {
      -webkit-transform: translateY(100%);
              transform: translateY(100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
  }
  @keyframes antMoveDownIn {
    0% {
      -webkit-transform: translateY(100%);
              transform: translateY(100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
  }
  @-webkit-keyframes antMoveDownOut {
    0% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(100%);
              transform: translateY(100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
  }
  @keyframes antMoveDownOut {
    0% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(100%);
              transform: translateY(100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
  }
  @-webkit-keyframes antMoveLeftIn {
    0% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
  }
  @keyframes antMoveLeftIn {
    0% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
  }
  @-webkit-keyframes antMoveLeftOut {
    0% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
  }
  @keyframes antMoveLeftOut {
    0% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
  }
  @-webkit-keyframes antMoveRightIn {
    0% {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
  }
  @keyframes antMoveRightIn {
    0% {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
  }
  @-webkit-keyframes antMoveRightOut {
    0% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
  }
  @keyframes antMoveRightOut {
    0% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
  }
  @-webkit-keyframes antMoveUpIn {
    0% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
  }
  @keyframes antMoveUpIn {
    0% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
  }
  @-webkit-keyframes antMoveUpOut {
    0% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
  }
  @keyframes antMoveUpOut {
    0% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
  }
  @-webkit-keyframes loadingCircle {
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes loadingCircle {
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  .ys-webrtc-sdk-ui [ant-click-animating='true'],
  .ys-webrtc-sdk-ui [ant-click-animating-without-extra-node='true'] {
    position: relative;
  }
  .ys-webrtc-sdk-ui html {
    --antd-wave-shadow-color: #006ee1;
    --scroll-bar: 0;
  }
  .ys-webrtc-sdk-ui [ant-click-animating-without-extra-node='true']::after,
  .ys-webrtc-sdk-ui .ant-click-animating-node {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    border-radius: inherit;
    box-shadow: 0 0 0 4px #006ee1;
    box-shadow: 0 0 0 4px var(--antd-wave-shadow-color);
    opacity: 0.2;
    -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
            animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    content: '';
    pointer-events: none;
  }
  @-webkit-keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 #006ee1;
      box-shadow: 0 0 0 4px var(--antd-wave-shadow-color);
    }
  }
  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 #006ee1;
      box-shadow: 0 0 0 4px var(--antd-wave-shadow-color);
    }
  }
  @-webkit-keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
  .ys-webrtc-sdk-ui .ant-slide-up-enter,
  .ys-webrtc-sdk-ui .ant-slide-up-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-slide-up-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-slide-up-enter.ant-slide-up-enter-active,
  .ys-webrtc-sdk-ui .ant-slide-up-appear.ant-slide-up-appear-active {
    -webkit-animation-name: antSlideUpIn;
            animation-name: antSlideUpIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .ys-webrtc-sdk-ui .ant-slide-up-leave.ant-slide-up-leave-active {
    -webkit-animation-name: antSlideUpOut;
            animation-name: antSlideUpOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-slide-up-enter,
  .ys-webrtc-sdk-ui .ant-slide-up-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
            animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  .ys-webrtc-sdk-ui .ant-slide-up-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  .ys-webrtc-sdk-ui .ant-slide-down-enter,
  .ys-webrtc-sdk-ui .ant-slide-down-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-slide-down-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-slide-down-enter.ant-slide-down-enter-active,
  .ys-webrtc-sdk-ui .ant-slide-down-appear.ant-slide-down-appear-active {
    -webkit-animation-name: antSlideDownIn;
            animation-name: antSlideDownIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .ys-webrtc-sdk-ui .ant-slide-down-leave.ant-slide-down-leave-active {
    -webkit-animation-name: antSlideDownOut;
            animation-name: antSlideDownOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-slide-down-enter,
  .ys-webrtc-sdk-ui .ant-slide-down-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
            animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  .ys-webrtc-sdk-ui .ant-slide-down-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  .ys-webrtc-sdk-ui .ant-slide-left-enter,
  .ys-webrtc-sdk-ui .ant-slide-left-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-slide-left-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-slide-left-enter.ant-slide-left-enter-active,
  .ys-webrtc-sdk-ui .ant-slide-left-appear.ant-slide-left-appear-active {
    -webkit-animation-name: antSlideLeftIn;
            animation-name: antSlideLeftIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .ys-webrtc-sdk-ui .ant-slide-left-leave.ant-slide-left-leave-active {
    -webkit-animation-name: antSlideLeftOut;
            animation-name: antSlideLeftOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-slide-left-enter,
  .ys-webrtc-sdk-ui .ant-slide-left-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
            animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  .ys-webrtc-sdk-ui .ant-slide-left-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  .ys-webrtc-sdk-ui .ant-slide-right-enter,
  .ys-webrtc-sdk-ui .ant-slide-right-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-slide-right-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-slide-right-enter.ant-slide-right-enter-active,
  .ys-webrtc-sdk-ui .ant-slide-right-appear.ant-slide-right-appear-active {
    -webkit-animation-name: antSlideRightIn;
            animation-name: antSlideRightIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .ys-webrtc-sdk-ui .ant-slide-right-leave.ant-slide-right-leave-active {
    -webkit-animation-name: antSlideRightOut;
            animation-name: antSlideRightOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-slide-right-enter,
  .ys-webrtc-sdk-ui .ant-slide-right-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
            animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  .ys-webrtc-sdk-ui .ant-slide-right-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  @-webkit-keyframes antSlideUpIn {
    0% {
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
  }
  @keyframes antSlideUpIn {
    0% {
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
  }
  @-webkit-keyframes antSlideUpOut {
    0% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
  }
  @keyframes antSlideUpOut {
    0% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
  }
  @-webkit-keyframes antSlideDownIn {
    0% {
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
  }
  @keyframes antSlideDownIn {
    0% {
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
  }
  @-webkit-keyframes antSlideDownOut {
    0% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 0;
    }
  }
  @keyframes antSlideDownOut {
    0% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 0;
    }
  }
  @-webkit-keyframes antSlideLeftIn {
    0% {
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
  }
  @keyframes antSlideLeftIn {
    0% {
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
  }
  @-webkit-keyframes antSlideLeftOut {
    0% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
  }
  @keyframes antSlideLeftOut {
    0% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
  }
  @-webkit-keyframes antSlideRightIn {
    0% {
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 1;
    }
  }
  @keyframes antSlideRightIn {
    0% {
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 1;
    }
  }
  @-webkit-keyframes antSlideRightOut {
    0% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 0;
    }
  }
  @keyframes antSlideRightOut {
    0% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 0;
    }
  }
  .ys-webrtc-sdk-ui .ant-zoom-enter,
  .ys-webrtc-sdk-ui .ant-zoom-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-zoom-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-zoom-enter.ant-zoom-enter-active,
  .ys-webrtc-sdk-ui .ant-zoom-appear.ant-zoom-appear-active {
    -webkit-animation-name: antZoomIn;
            animation-name: antZoomIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .ys-webrtc-sdk-ui .ant-zoom-leave.ant-zoom-leave-active {
    -webkit-animation-name: antZoomOut;
            animation-name: antZoomOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-zoom-enter,
  .ys-webrtc-sdk-ui .ant-zoom-appear {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .ys-webrtc-sdk-ui .ant-zoom-enter-prepare,
  .ys-webrtc-sdk-ui .ant-zoom-appear-prepare {
    -webkit-transform: none;
            transform: none;
  }
  .ys-webrtc-sdk-ui .ant-zoom-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
            animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .ys-webrtc-sdk-ui .ant-zoom-big-enter,
  .ys-webrtc-sdk-ui .ant-zoom-big-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-zoom-big-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-zoom-big-enter.ant-zoom-big-enter-active,
  .ys-webrtc-sdk-ui .ant-zoom-big-appear.ant-zoom-big-appear-active {
    -webkit-animation-name: antZoomBigIn;
            animation-name: antZoomBigIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .ys-webrtc-sdk-ui .ant-zoom-big-leave.ant-zoom-big-leave-active {
    -webkit-animation-name: antZoomBigOut;
            animation-name: antZoomBigOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-zoom-big-enter,
  .ys-webrtc-sdk-ui .ant-zoom-big-appear {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .ys-webrtc-sdk-ui .ant-zoom-big-enter-prepare,
  .ys-webrtc-sdk-ui .ant-zoom-big-appear-prepare {
    -webkit-transform: none;
            transform: none;
  }
  .ys-webrtc-sdk-ui .ant-zoom-big-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
            animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .ys-webrtc-sdk-ui .ant-zoom-big-fast-enter,
  .ys-webrtc-sdk-ui .ant-zoom-big-fast-appear {
    -webkit-animation-duration: 0.1s;
            animation-duration: 0.1s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-zoom-big-fast-leave {
    -webkit-animation-duration: 0.1s;
            animation-duration: 0.1s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-zoom-big-fast-enter.ant-zoom-big-fast-enter-active,
  .ys-webrtc-sdk-ui .ant-zoom-big-fast-appear.ant-zoom-big-fast-appear-active {
    -webkit-animation-name: antZoomBigIn;
            animation-name: antZoomBigIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .ys-webrtc-sdk-ui .ant-zoom-big-fast-leave.ant-zoom-big-fast-leave-active {
    -webkit-animation-name: antZoomBigOut;
            animation-name: antZoomBigOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-zoom-big-fast-enter,
  .ys-webrtc-sdk-ui .ant-zoom-big-fast-appear {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .ys-webrtc-sdk-ui .ant-zoom-big-fast-enter-prepare,
  .ys-webrtc-sdk-ui .ant-zoom-big-fast-appear-prepare {
    -webkit-transform: none;
            transform: none;
  }
  .ys-webrtc-sdk-ui .ant-zoom-big-fast-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
            animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .ys-webrtc-sdk-ui .ant-zoom-up-enter,
  .ys-webrtc-sdk-ui .ant-zoom-up-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-zoom-up-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-zoom-up-enter.ant-zoom-up-enter-active,
  .ys-webrtc-sdk-ui .ant-zoom-up-appear.ant-zoom-up-appear-active {
    -webkit-animation-name: antZoomUpIn;
            animation-name: antZoomUpIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .ys-webrtc-sdk-ui .ant-zoom-up-leave.ant-zoom-up-leave-active {
    -webkit-animation-name: antZoomUpOut;
            animation-name: antZoomUpOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-zoom-up-enter,
  .ys-webrtc-sdk-ui .ant-zoom-up-appear {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .ys-webrtc-sdk-ui .ant-zoom-up-enter-prepare,
  .ys-webrtc-sdk-ui .ant-zoom-up-appear-prepare {
    -webkit-transform: none;
            transform: none;
  }
  .ys-webrtc-sdk-ui .ant-zoom-up-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
            animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .ys-webrtc-sdk-ui .ant-zoom-down-enter,
  .ys-webrtc-sdk-ui .ant-zoom-down-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-zoom-down-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-zoom-down-enter.ant-zoom-down-enter-active,
  .ys-webrtc-sdk-ui .ant-zoom-down-appear.ant-zoom-down-appear-active {
    -webkit-animation-name: antZoomDownIn;
            animation-name: antZoomDownIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .ys-webrtc-sdk-ui .ant-zoom-down-leave.ant-zoom-down-leave-active {
    -webkit-animation-name: antZoomDownOut;
            animation-name: antZoomDownOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-zoom-down-enter,
  .ys-webrtc-sdk-ui .ant-zoom-down-appear {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .ys-webrtc-sdk-ui .ant-zoom-down-enter-prepare,
  .ys-webrtc-sdk-ui .ant-zoom-down-appear-prepare {
    -webkit-transform: none;
            transform: none;
  }
  .ys-webrtc-sdk-ui .ant-zoom-down-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
            animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .ys-webrtc-sdk-ui .ant-zoom-left-enter,
  .ys-webrtc-sdk-ui .ant-zoom-left-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-zoom-left-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-zoom-left-enter.ant-zoom-left-enter-active,
  .ys-webrtc-sdk-ui .ant-zoom-left-appear.ant-zoom-left-appear-active {
    -webkit-animation-name: antZoomLeftIn;
            animation-name: antZoomLeftIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .ys-webrtc-sdk-ui .ant-zoom-left-leave.ant-zoom-left-leave-active {
    -webkit-animation-name: antZoomLeftOut;
            animation-name: antZoomLeftOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-zoom-left-enter,
  .ys-webrtc-sdk-ui .ant-zoom-left-appear {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .ys-webrtc-sdk-ui .ant-zoom-left-enter-prepare,
  .ys-webrtc-sdk-ui .ant-zoom-left-appear-prepare {
    -webkit-transform: none;
            transform: none;
  }
  .ys-webrtc-sdk-ui .ant-zoom-left-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
            animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .ys-webrtc-sdk-ui .ant-zoom-right-enter,
  .ys-webrtc-sdk-ui .ant-zoom-right-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-zoom-right-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-zoom-right-enter.ant-zoom-right-enter-active,
  .ys-webrtc-sdk-ui .ant-zoom-right-appear.ant-zoom-right-appear-active {
    -webkit-animation-name: antZoomRightIn;
            animation-name: antZoomRightIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .ys-webrtc-sdk-ui .ant-zoom-right-leave.ant-zoom-right-leave-active {
    -webkit-animation-name: antZoomRightOut;
            animation-name: antZoomRightOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-zoom-right-enter,
  .ys-webrtc-sdk-ui .ant-zoom-right-appear {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .ys-webrtc-sdk-ui .ant-zoom-right-enter-prepare,
  .ys-webrtc-sdk-ui .ant-zoom-right-appear-prepare {
    -webkit-transform: none;
            transform: none;
  }
  .ys-webrtc-sdk-ui .ant-zoom-right-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
            animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  @-webkit-keyframes antZoomIn {
    0% {
      -webkit-transform: scale(0.2);
              transform: scale(0.2);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes antZoomIn {
    0% {
      -webkit-transform: scale(0.2);
              transform: scale(0.2);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @-webkit-keyframes antZoomOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0.2);
              transform: scale(0.2);
      opacity: 0;
    }
  }
  @keyframes antZoomOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0.2);
              transform: scale(0.2);
      opacity: 0;
    }
  }
  @-webkit-keyframes antZoomBigIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes antZoomBigIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @-webkit-keyframes antZoomBigOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      opacity: 0;
    }
  }
  @keyframes antZoomBigOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      opacity: 0;
    }
  }
  @-webkit-keyframes antZoomUpIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
    }
  }
  @keyframes antZoomUpIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
    }
  }
  @-webkit-keyframes antZoomUpOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
      opacity: 0;
    }
  }
  @keyframes antZoomUpOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
      opacity: 0;
    }
  }
  @-webkit-keyframes antZoomLeftIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
    }
  }
  @keyframes antZoomLeftIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
    }
  }
  @-webkit-keyframes antZoomLeftOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
      opacity: 0;
    }
  }
  @keyframes antZoomLeftOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
      opacity: 0;
    }
  }
  @-webkit-keyframes antZoomRightIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
    }
  }
  @keyframes antZoomRightIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
    }
  }
  @-webkit-keyframes antZoomRightOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
      opacity: 0;
    }
  }
  @keyframes antZoomRightOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
      opacity: 0;
    }
  }
  @-webkit-keyframes antZoomDownIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
    }
  }
  @keyframes antZoomDownIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
    }
  }
  @-webkit-keyframes antZoomDownOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      opacity: 0;
    }
  }
  @keyframes antZoomDownOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      opacity: 0;
    }
  }
  .ys-webrtc-sdk-ui .ant-motion-collapse-legacy {
    overflow: hidden;
  }
  .ys-webrtc-sdk-ui .ant-motion-collapse-legacy-active {
    transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  }
  .ys-webrtc-sdk-ui .ant-motion-collapse {
    overflow: hidden;
    transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  }
  
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  .ys-webrtc-sdk-ui .scroll-bar-style {
    scrollbar-width: thin;
    scrollbar-color: #e6e9ec #fff;
    /* 定义滚动条轨道内阴影 */
    /* 定义滑块内阴影 */
    /* 滚动条 */
  }
  .ys-webrtc-sdk-ui .scroll-bar-style::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .scroll-bar-style::-webkit-scrollbar-thumb {
    background-color: #e6e9ec;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .scroll-bar-style::-webkit-scrollbar {
    width: 6px;
    padding-right: 50%;
    background-color: #fff;
    border-radius: 3px;
  }
  .ys-webrtc-sdk-ui .ant-btn {
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 500;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    box-shadow: none;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
            user-select: none;
    touch-action: manipulation;
    height: 36px;
    padding: 6px 16px;
    font-size: 14px;
    border-radius: 12px;
    color: #006ee1;
    background: #fff;
    border-color: #006ee1;
  }
  .ys-webrtc-sdk-ui .ant-btn > .anticon {
    line-height: 1;
  }
  .ys-webrtc-sdk-ui .ant-btn,
  .ys-webrtc-sdk-ui .ant-btn:active,
  .ys-webrtc-sdk-ui .ant-btn:focus {
    outline: 0;
  }
  .ys-webrtc-sdk-ui .ant-btn:not([disabled]):hover {
    text-decoration: none;
  }
  .ys-webrtc-sdk-ui .ant-btn:not([disabled]):active {
    outline: 0;
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-btn[disabled] {
    cursor: not-allowed;
  }
  .ys-webrtc-sdk-ui .ant-btn[disabled] > * {
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-btn-lg {
    height: 44px;
    padding: 8.4px 20px;
    font-size: 16px;
    border-radius: 12px;
  }
  .ys-webrtc-sdk-ui .ant-btn-sm {
    height: 28px;
    padding: 3.6px 12px;
    font-size: 12px;
    border-radius: 8px;
    font-weight: 400;
  }
  .ys-webrtc-sdk-ui .ant-btn > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn:hover,
  .ys-webrtc-sdk-ui .ant-btn:focus {
    color: #268ded;
    background: #fff;
    border-color: #268ded;
  }
  .ys-webrtc-sdk-ui .ant-btn:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn:focus > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn:active {
    color: #0054ba;
    background: #fff;
    border-color: #0054ba;
  }
  .ys-webrtc-sdk-ui .ant-btn:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn[disabled],
  .ys-webrtc-sdk-ui .ant-btn[disabled]:hover,
  .ys-webrtc-sdk-ui .ant-btn[disabled]:focus,
  .ys-webrtc-sdk-ui .ant-btn[disabled]:active {
    color: #b1b9c3;
    background: #f6f8fa;
    border-color: #e6e9ec;
    text-shadow: none;
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-btn[disabled] > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn[disabled]:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn[disabled]:focus > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn[disabled] > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn[disabled]:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn[disabled]:focus > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn:hover,
  .ys-webrtc-sdk-ui .ant-btn:focus,
  .ys-webrtc-sdk-ui .ant-btn:active {
    text-decoration: none;
    background: #fff;
  }
  .ys-webrtc-sdk-ui .ant-btn > span {
    display: inline-block;
  }
  .ys-webrtc-sdk-ui .ant-btn-primary {
    color: #fff;
    background: #006ee1;
    border-color: #006ee1;
    text-shadow: none;
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-btn-primary > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-primary > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-primary:hover,
  .ys-webrtc-sdk-ui .ant-btn-primary:focus {
    color: #fff;
    background: #268ded;
    border-color: #268ded;
  }
  .ys-webrtc-sdk-ui .ant-btn-primary:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-primary:focus > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-primary:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-primary:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-primary:active {
    color: #fff;
    background: #0054ba;
    border-color: #0054ba;
  }
  .ys-webrtc-sdk-ui .ant-btn-primary:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-primary:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-primary[disabled],
  .ys-webrtc-sdk-ui .ant-btn-primary[disabled]:hover,
  .ys-webrtc-sdk-ui .ant-btn-primary[disabled]:focus,
  .ys-webrtc-sdk-ui .ant-btn-primary[disabled]:active {
    color: #b1b9c3;
    background: #f6f8fa;
    border-color: #e6e9ec;
    text-shadow: none;
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-btn-primary[disabled] > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-primary[disabled]:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-primary[disabled]:focus > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-primary[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-primary[disabled] > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-primary[disabled]:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-primary[disabled]:focus > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-primary[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
    border-right-color: #006ee1;
    border-left-color: #006ee1;
  }
  .ys-webrtc-sdk-ui .ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
    border-color: #006ee1;
  }
  .ys-webrtc-sdk-ui .ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
    border-right-color: #006ee1;
  }
  .ys-webrtc-sdk-ui .ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
    border-right-color: #006ee1;
  }
  .ys-webrtc-sdk-ui .ant-btn-group .ant-btn-primary:last-child:not(:first-child),
  .ys-webrtc-sdk-ui .ant-btn-group .ant-btn-primary + .ant-btn-primary {
    border-left-color: #006ee1;
  }
  .ys-webrtc-sdk-ui .ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
  .ys-webrtc-sdk-ui .ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
    border-left-color: #006ee1;
  }
  .ys-webrtc-sdk-ui .ant-btn-ghost {
    color: #23282f;
    background: #fff;
    border-color: #e6e9ec;
  }
  .ys-webrtc-sdk-ui .ant-btn-ghost > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-ghost > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-ghost:hover,
  .ys-webrtc-sdk-ui .ant-btn-ghost:focus {
    color: #268ded;
    background: #fff;
    border-color: #268ded;
  }
  .ys-webrtc-sdk-ui .ant-btn-ghost:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-ghost:focus > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-ghost:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-ghost:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-ghost:active {
    color: #0054ba;
    background: #fff;
    border-color: #0054ba;
  }
  .ys-webrtc-sdk-ui .ant-btn-ghost:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-ghost:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-ghost[disabled],
  .ys-webrtc-sdk-ui .ant-btn-ghost[disabled]:hover,
  .ys-webrtc-sdk-ui .ant-btn-ghost[disabled]:focus,
  .ys-webrtc-sdk-ui .ant-btn-ghost[disabled]:active {
    color: #b1b9c3;
    background: #f6f8fa;
    border-color: #e6e9ec;
    text-shadow: none;
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-btn-ghost[disabled] > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-ghost[disabled]:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-ghost[disabled]:focus > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-ghost[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-ghost[disabled] > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-ghost[disabled]:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-ghost[disabled]:focus > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-ghost[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-dashed {
    color: #006ee1;
    background: #fff;
    border-color: #006ee1;
    border-style: dashed;
  }
  .ys-webrtc-sdk-ui .ant-btn-dashed > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-dashed > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-dashed:hover,
  .ys-webrtc-sdk-ui .ant-btn-dashed:focus {
    color: #268ded;
    background: #fff;
    border-color: #268ded;
  }
  .ys-webrtc-sdk-ui .ant-btn-dashed:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-dashed:focus > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-dashed:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-dashed:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-dashed:active {
    color: #0054ba;
    background: #fff;
    border-color: #0054ba;
  }
  .ys-webrtc-sdk-ui .ant-btn-dashed:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-dashed:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-dashed[disabled],
  .ys-webrtc-sdk-ui .ant-btn-dashed[disabled]:hover,
  .ys-webrtc-sdk-ui .ant-btn-dashed[disabled]:focus,
  .ys-webrtc-sdk-ui .ant-btn-dashed[disabled]:active {
    color: #b1b9c3;
    background: #f6f8fa;
    border-color: #e6e9ec;
    text-shadow: none;
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-btn-dashed[disabled] > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-dashed[disabled]:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-dashed[disabled]:focus > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-dashed[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-dashed[disabled] > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-dashed[disabled]:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-dashed[disabled]:focus > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-dashed[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-danger {
    color: #fff;
    background: #e6492d;
    border-color: #e6492d;
    text-shadow: none;
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-btn-danger > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-danger > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-danger:hover,
  .ys-webrtc-sdk-ui .ant-btn-danger:focus {
    color: #fff;
    background: #f27457;
    border-color: #f27457;
  }
  .ys-webrtc-sdk-ui .ant-btn-danger:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-danger:focus > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-danger:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-danger:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-danger:active {
    color: #fff;
    background: #bf301d;
    border-color: #bf301d;
  }
  .ys-webrtc-sdk-ui .ant-btn-danger:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-danger:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-danger[disabled],
  .ys-webrtc-sdk-ui .ant-btn-danger[disabled]:hover,
  .ys-webrtc-sdk-ui .ant-btn-danger[disabled]:focus,
  .ys-webrtc-sdk-ui .ant-btn-danger[disabled]:active {
    color: #b1b9c3;
    background: #f6f8fa;
    border-color: #e6e9ec;
    text-shadow: none;
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-btn-danger[disabled] > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-danger[disabled]:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-danger[disabled]:focus > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-danger[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-danger[disabled] > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-danger[disabled]:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-danger[disabled]:focus > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-danger[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-link {
    color: #006ee1;
    background: transparent;
    border-color: transparent;
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-btn-link > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-link > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-link:hover,
  .ys-webrtc-sdk-ui .ant-btn-link:focus {
    color: #268ded;
    background: transparent;
    border-color: #268ded;
  }
  .ys-webrtc-sdk-ui .ant-btn-link:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-link:focus > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-link:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-link:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-link:active {
    color: #0054ba;
    background: transparent;
    border-color: #0054ba;
  }
  .ys-webrtc-sdk-ui .ant-btn-link:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-link:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-link[disabled],
  .ys-webrtc-sdk-ui .ant-btn-link[disabled]:hover,
  .ys-webrtc-sdk-ui .ant-btn-link[disabled]:focus,
  .ys-webrtc-sdk-ui .ant-btn-link[disabled]:active {
    color: #b1b9c3;
    background: #f6f8fa;
    border-color: #e6e9ec;
    text-shadow: none;
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-btn-link[disabled] > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-link[disabled]:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-link[disabled]:focus > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-link[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-link[disabled] > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-link[disabled]:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-link[disabled]:focus > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-link[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-link:hover {
    background: transparent;
  }
  .ys-webrtc-sdk-ui .ant-btn-link:hover,
  .ys-webrtc-sdk-ui .ant-btn-link:focus,
  .ys-webrtc-sdk-ui .ant-btn-link:active {
    border-color: transparent;
  }
  .ys-webrtc-sdk-ui .ant-btn-link[disabled],
  .ys-webrtc-sdk-ui .ant-btn-link[disabled]:hover,
  .ys-webrtc-sdk-ui .ant-btn-link[disabled]:focus,
  .ys-webrtc-sdk-ui .ant-btn-link[disabled]:active {
    color: #b1b9c3;
    background: transparent;
    border-color: transparent;
    text-shadow: none;
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-btn-link[disabled] > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-link[disabled]:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-link[disabled]:focus > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-link[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-link[disabled] > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-link[disabled]:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-link[disabled]:focus > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-link[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-text {
    color: #23282f;
    background: transparent;
    border-color: transparent;
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-btn-text > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-text > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-text:hover,
  .ys-webrtc-sdk-ui .ant-btn-text:focus {
    color: #268ded;
    background: transparent;
    border-color: #268ded;
  }
  .ys-webrtc-sdk-ui .ant-btn-text:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-text:focus > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-text:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-text:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-text:active {
    color: #0054ba;
    background: transparent;
    border-color: #0054ba;
  }
  .ys-webrtc-sdk-ui .ant-btn-text:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-text:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-text[disabled],
  .ys-webrtc-sdk-ui .ant-btn-text[disabled]:hover,
  .ys-webrtc-sdk-ui .ant-btn-text[disabled]:focus,
  .ys-webrtc-sdk-ui .ant-btn-text[disabled]:active {
    color: #b1b9c3;
    background: #f6f8fa;
    border-color: #e6e9ec;
    text-shadow: none;
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-btn-text[disabled] > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-text[disabled]:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-text[disabled]:focus > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-text[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-text[disabled] > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-text[disabled]:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-text[disabled]:focus > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-text[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-text:hover,
  .ys-webrtc-sdk-ui .ant-btn-text:focus {
    color: #23282f;
    background: transparent;
    border-color: transparent;
  }
  .ys-webrtc-sdk-ui .ant-btn-text:active {
    color: #23282f;
    background: rgba(0, 0, 0, 0.06);
    border-color: transparent;
  }
  .ys-webrtc-sdk-ui .ant-btn-text[disabled],
  .ys-webrtc-sdk-ui .ant-btn-text[disabled]:hover,
  .ys-webrtc-sdk-ui .ant-btn-text[disabled]:focus,
  .ys-webrtc-sdk-ui .ant-btn-text[disabled]:active {
    color: #c8cfd5;
    background: transparent;
    border-color: transparent;
    text-shadow: none;
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-btn-text[disabled] > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-text[disabled]:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-text[disabled]:focus > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-text[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-text[disabled] > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-text[disabled]:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-text[disabled]:focus > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-text[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous {
    color: #e6492d;
    background: #fff;
    border-color: #e6492d;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous:hover,
  .ys-webrtc-sdk-ui .ant-btn-dangerous:focus {
    color: #f27457;
    background: #fff;
    border-color: #f27457;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-dangerous:focus > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-dangerous:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous:active {
    color: #bf301d;
    background: #fff;
    border-color: #bf301d;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous[disabled],
  .ys-webrtc-sdk-ui .ant-btn-dangerous[disabled]:hover,
  .ys-webrtc-sdk-ui .ant-btn-dangerous[disabled]:focus,
  .ys-webrtc-sdk-ui .ant-btn-dangerous[disabled]:active {
    color: #b1b9c3;
    background: #f6f8fa;
    border-color: #e6e9ec;
    text-shadow: none;
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous[disabled] > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-dangerous[disabled]:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-dangerous[disabled]:focus > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-dangerous[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous[disabled] > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-dangerous[disabled]:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-dangerous[disabled]:focus > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-dangerous[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-primary {
    color: #fff;
    background: #e6492d;
    border-color: #e6492d;
    text-shadow: none;
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-primary > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-primary > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-primary:hover,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-primary:focus {
    color: #fff;
    background: #f27457;
    border-color: #f27457;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-primary:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-primary:focus > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-primary:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-primary:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-primary:active {
    color: #fff;
    background: #bf301d;
    border-color: #bf301d;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-primary:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-primary:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-primary[disabled],
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-primary[disabled]:hover,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-primary[disabled]:focus,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-primary[disabled]:active {
    color: #b1b9c3;
    background: #f6f8fa;
    border-color: #e6e9ec;
    text-shadow: none;
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link {
    color: #e6492d;
    background: transparent;
    border-color: transparent;
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link:hover,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link:focus {
    color: #268ded;
    background: transparent;
    border-color: #268ded;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link:focus > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link:active {
    color: #0054ba;
    background: transparent;
    border-color: #0054ba;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link[disabled],
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link[disabled]:hover,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link[disabled]:focus,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link[disabled]:active {
    color: #b1b9c3;
    background: #f6f8fa;
    border-color: #e6e9ec;
    text-shadow: none;
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link:hover,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link:focus {
    color: #f27457;
    background: transparent;
    border-color: transparent;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link:focus > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link:active {
    color: #bf301d;
    background: transparent;
    border-color: transparent;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link[disabled],
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link[disabled]:hover,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link[disabled]:focus,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link[disabled]:active {
    color: #b1b9c3;
    background: transparent;
    border-color: transparent;
    text-shadow: none;
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text {
    color: #e6492d;
    background: transparent;
    border-color: transparent;
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text:hover,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text:focus {
    color: #268ded;
    background: transparent;
    border-color: #268ded;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text:focus > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text:active {
    color: #0054ba;
    background: transparent;
    border-color: #0054ba;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text[disabled],
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text[disabled]:hover,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text[disabled]:focus,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text[disabled]:active {
    color: #b1b9c3;
    background: #f6f8fa;
    border-color: #e6e9ec;
    text-shadow: none;
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text:hover,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text:focus {
    color: #f27457;
    background: transparent;
    border-color: transparent;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text:focus > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text:active {
    color: #bf301d;
    background: rgba(0, 0, 0, 0.06);
    border-color: transparent;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text[disabled],
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text[disabled]:hover,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text[disabled]:focus,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text[disabled]:active {
    color: #b1b9c3;
    background: transparent;
    border-color: transparent;
    text-shadow: none;
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-icon-only {
    width: 36px;
    height: 36px;
    padding: 4.4px 0;
    font-size: 16px;
    border-radius: 12px;
    vertical-align: -1px;
  }
  .ys-webrtc-sdk-ui .ant-btn-icon-only > * {
    font-size: 16px;
  }
  .ys-webrtc-sdk-ui .ant-btn-icon-only.ant-btn-lg {
    width: 44px;
    height: 44px;
    padding: 6.9px 0;
    font-size: 18px;
    border-radius: 12px;
  }
  .ys-webrtc-sdk-ui .ant-btn-icon-only.ant-btn-lg > * {
    font-size: 18px;
  }
  .ys-webrtc-sdk-ui .ant-btn-icon-only.ant-btn-sm {
    width: 28px;
    height: 28px;
    padding: 2px 0;
    font-size: 14px;
    border-radius: 8px;
  }
  .ys-webrtc-sdk-ui .ant-btn-icon-only.ant-btn-sm > * {
    font-size: 14px;
  }
  .ys-webrtc-sdk-ui .ant-btn-round {
    height: 36px;
    padding: 6px 18px;
    font-size: 14px;
    border-radius: 36px;
  }
  .ys-webrtc-sdk-ui .ant-btn-round.ant-btn-lg {
    height: 44px;
    padding: 8.4px 22px;
    font-size: 16px;
    border-radius: 44px;
  }
  .ys-webrtc-sdk-ui .ant-btn-round.ant-btn-sm {
    height: 28px;
    padding: 3.6px 14px;
    font-size: 12px;
    border-radius: 28px;
  }
  .ys-webrtc-sdk-ui .ant-btn-round.ant-btn-icon-only {
    width: auto;
  }
  .ys-webrtc-sdk-ui .ant-btn-circle {
    min-width: 36px;
    padding-right: 0;
    padding-left: 0;
    text-align: center;
    border-radius: 50%;
  }
  .ys-webrtc-sdk-ui .ant-btn-circle.ant-btn-lg {
    min-width: 44px;
    border-radius: 50%;
  }
  .ys-webrtc-sdk-ui .ant-btn-circle.ant-btn-sm {
    min-width: 28px;
    border-radius: 50%;
  }
  .ys-webrtc-sdk-ui .ant-btn::before {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: 1;
    display: none;
    background: #fff;
    border-radius: inherit;
    opacity: 0.5;
    transition: opacity 0.2s;
    content: '';
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-btn .anticon {
    transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ys-webrtc-sdk-ui .ant-btn .anticon.anticon-plus > svg,
  .ys-webrtc-sdk-ui .ant-btn .anticon.anticon-minus > svg {
    shape-rendering: optimizeSpeed;
  }
  .ys-webrtc-sdk-ui .ant-btn.ant-btn-loading {
    position: relative;
    background: #0054ba;
  }
  .ys-webrtc-sdk-ui .ant-btn.ant-btn-loading.ant-btn-default {
    color: #0054ba;
    background: #fff;
    border-color: #0054ba;
  }
  .ys-webrtc-sdk-ui .ant-btn.ant-btn-loading.ant-btn-dashed {
    color: #0054ba;
    background: #fff;
    border-color: #0054ba;
  }
  .ys-webrtc-sdk-ui .ant-btn.ant-btn-loading.ant-btn-Ghost {
    color: #99a5b2;
    background: #f1f2f3;
    border-color: #c8cfd5;
  }
  .ys-webrtc-sdk-ui .ant-btn.ant-btn-loading.ant-btn-link {
    color: #0054ba;
    background: transparent;
    border-color: transparent;
  }
  .ys-webrtc-sdk-ui .ant-btn.ant-btn-loading:not([disabled]) {
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-btn.ant-btn-loading::before {
    display: block;
  }
  .ys-webrtc-sdk-ui .ant-btn > .ant-btn-loading-icon {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ys-webrtc-sdk-ui .ant-btn > .ant-btn-loading-icon .anticon {
    padding-right: 8px;
    -webkit-animation: none;
            animation: none;
  }
  .ys-webrtc-sdk-ui .ant-btn > .ant-btn-loading-icon .anticon svg {
    -webkit-animation: loadingCircle 1s infinite linear;
            animation: loadingCircle 1s infinite linear;
  }
  .ys-webrtc-sdk-ui .ant-btn > .ant-btn-loading-icon:only-child .anticon {
    padding-right: 0;
  }
  .ys-webrtc-sdk-ui .ant-btn-group {
    position: relative;
    display: inline-flex;
  }
  .ys-webrtc-sdk-ui .ant-btn-group > .ant-btn,
  .ys-webrtc-sdk-ui .ant-btn-group > span > .ant-btn {
    position: relative;
  }
  .ys-webrtc-sdk-ui .ant-btn-group > .ant-btn:hover,
  .ys-webrtc-sdk-ui .ant-btn-group > span > .ant-btn:hover,
  .ys-webrtc-sdk-ui .ant-btn-group > .ant-btn:focus,
  .ys-webrtc-sdk-ui .ant-btn-group > span > .ant-btn:focus,
  .ys-webrtc-sdk-ui .ant-btn-group > .ant-btn:active,
  .ys-webrtc-sdk-ui .ant-btn-group > span > .ant-btn:active {
    z-index: 2;
  }
  .ys-webrtc-sdk-ui .ant-btn-group > .ant-btn[disabled],
  .ys-webrtc-sdk-ui .ant-btn-group > span > .ant-btn[disabled] {
    z-index: 0;
  }
  .ys-webrtc-sdk-ui .ant-btn-group .ant-btn-icon-only {
    font-size: 14px;
  }
  .ys-webrtc-sdk-ui .ant-btn-group-lg > .ant-btn,
  .ys-webrtc-sdk-ui .ant-btn-group-lg > span > .ant-btn {
    height: 44px;
    padding: 8.4px 20px;
    font-size: 16px;
    border-radius: 0;
  }
  .ys-webrtc-sdk-ui .ant-btn-group-lg .ant-btn.ant-btn-icon-only {
    width: 44px;
    height: 44px;
    padding-right: 0;
    padding-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-btn-group-sm > .ant-btn,
  .ys-webrtc-sdk-ui .ant-btn-group-sm > span > .ant-btn {
    height: 28px;
    padding: 2px 12px;
    font-size: 14px;
    border-radius: 0;
  }
  .ys-webrtc-sdk-ui .ant-btn-group-sm > .ant-btn > .anticon,
  .ys-webrtc-sdk-ui .ant-btn-group-sm > span > .ant-btn > .anticon {
    font-size: 14px;
  }
  .ys-webrtc-sdk-ui .ant-btn-group-sm .ant-btn.ant-btn-icon-only {
    width: 28px;
    height: 28px;
    padding-right: 0;
    padding-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-btn-group .ant-btn + .ant-btn,
  .ys-webrtc-sdk-ui .ant-btn + .ant-btn-group,
  .ys-webrtc-sdk-ui .ant-btn-group span + .ant-btn,
  .ys-webrtc-sdk-ui .ant-btn-group .ant-btn + span,
  .ys-webrtc-sdk-ui .ant-btn-group > span + span,
  .ys-webrtc-sdk-ui .ant-btn-group + .ant-btn,
  .ys-webrtc-sdk-ui .ant-btn-group + .ant-btn-group {
    margin-left: -1px;
  }
  .ys-webrtc-sdk-ui .ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
    border-left-color: transparent;
  }
  .ys-webrtc-sdk-ui .ant-btn-group .ant-btn {
    border-radius: 0;
  }
  .ys-webrtc-sdk-ui .ant-btn-group > .ant-btn:first-child,
  .ys-webrtc-sdk-ui .ant-btn-group > span:first-child > .ant-btn {
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-btn-group > .ant-btn:only-child {
    border-radius: 12px;
  }
  .ys-webrtc-sdk-ui .ant-btn-group > span:only-child > .ant-btn {
    border-radius: 12px;
  }
  .ys-webrtc-sdk-ui .ant-btn-group > .ant-btn:first-child:not(:last-child),
  .ys-webrtc-sdk-ui .ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  .ys-webrtc-sdk-ui .ant-btn-group > .ant-btn:last-child:not(:first-child),
  .ys-webrtc-sdk-ui .ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  .ys-webrtc-sdk-ui .ant-btn-group-sm > .ant-btn:only-child {
    border-radius: 8px;
  }
  .ys-webrtc-sdk-ui .ant-btn-group-sm > span:only-child > .ant-btn {
    border-radius: 8px;
  }
  .ys-webrtc-sdk-ui .ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
  .ys-webrtc-sdk-ui .ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .ys-webrtc-sdk-ui .ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
  .ys-webrtc-sdk-ui .ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .ys-webrtc-sdk-ui .ant-btn-group > .ant-btn-group {
    float: left;
  }
  .ys-webrtc-sdk-ui .ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
    border-radius: 0;
  }
  .ys-webrtc-sdk-ui .ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
    padding-right: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ys-webrtc-sdk-ui .ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
    padding-left: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ys-webrtc-sdk-ui .ant-btn-rtl.ant-btn-group .ant-btn + .ant-btn,
  .ys-webrtc-sdk-ui .ant-btn-rtl.ant-btn + .ant-btn-group,
  .ys-webrtc-sdk-ui .ant-btn-rtl.ant-btn-group span + .ant-btn,
  .ys-webrtc-sdk-ui .ant-btn-rtl.ant-btn-group .ant-btn + span,
  .ys-webrtc-sdk-ui .ant-btn-rtl.ant-btn-group > span + span,
  .ys-webrtc-sdk-ui .ant-btn-rtl.ant-btn-group + .ant-btn,
  .ys-webrtc-sdk-ui .ant-btn-rtl.ant-btn-group + .ant-btn-group,
  .ys-webrtc-sdk-ui .ant-btn-group-rtl.ant-btn-group .ant-btn + .ant-btn,
  .ys-webrtc-sdk-ui .ant-btn-group-rtl.ant-btn + .ant-btn-group,
  .ys-webrtc-sdk-ui .ant-btn-group-rtl.ant-btn-group span + .ant-btn,
  .ys-webrtc-sdk-ui .ant-btn-group-rtl.ant-btn-group .ant-btn + span,
  .ys-webrtc-sdk-ui .ant-btn-group-rtl.ant-btn-group > span + span,
  .ys-webrtc-sdk-ui .ant-btn-group-rtl.ant-btn-group + .ant-btn,
  .ys-webrtc-sdk-ui .ant-btn-group-rtl.ant-btn-group + .ant-btn-group {
    margin-right: -1px;
    margin-left: auto;
  }
  .ys-webrtc-sdk-ui .ant-btn-group.ant-btn-group-rtl {
    direction: rtl;
  }
  .ys-webrtc-sdk-ui .ant-btn-group-rtl.ant-btn-group > .ant-btn:first-child:not(:last-child),
  .ys-webrtc-sdk-ui .ant-btn-group-rtl.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
    border-top-left-radius: 0;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 0;
  }
  .ys-webrtc-sdk-ui .ant-btn-group-rtl.ant-btn-group > .ant-btn:last-child:not(:first-child),
  .ys-webrtc-sdk-ui .ant-btn-group-rtl.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
    border-top-left-radius: 12px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 12px;
  }
  .ys-webrtc-sdk-ui .ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
  .ys-webrtc-sdk-ui .ant-btn-group-rtl.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
    border-top-left-radius: 0;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 0;
  }
  .ys-webrtc-sdk-ui .ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
  .ys-webrtc-sdk-ui .ant-btn-group-rtl.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
    border-top-left-radius: 8px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 8px;
  }
  .ys-webrtc-sdk-ui .ant-btn:focus > span,
  .ys-webrtc-sdk-ui .ant-btn:active > span {
    position: relative;
  }
  .ys-webrtc-sdk-ui .ant-btn > .anticon + span,
  .ys-webrtc-sdk-ui .ant-btn > span + .anticon {
    margin-left: 8px;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost {
    color: #fff;
    background: transparent !important;
    border-color: #fff;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-primary {
    color: #006ee1;
    background: transparent;
    border-color: #006ee1;
    text-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-primary > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-primary > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-primary:hover,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-primary:focus {
    color: #268ded;
    background: transparent;
    border-color: #268ded;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-primary:active {
    color: #0054ba;
    background: transparent;
    border-color: #0054ba;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-primary:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-primary[disabled],
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-primary[disabled]:active {
    color: #b1b9c3;
    background: #f6f8fa;
    border-color: #e6e9ec;
    text-shadow: none;
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-danger {
    color: #e6492d;
    background: transparent;
    border-color: #e6492d;
    text-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-danger > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-danger > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-danger:hover,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-danger:focus {
    color: #f27457;
    background: transparent;
    border-color: #f27457;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-danger:active {
    color: #bf301d;
    background: transparent;
    border-color: #bf301d;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-danger:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-danger[disabled],
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-danger[disabled]:active {
    color: #b1b9c3;
    background: #f6f8fa;
    border-color: #e6e9ec;
    text-shadow: none;
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous {
    color: #e6492d;
    background: transparent;
    border-color: #e6492d;
    text-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous:hover,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous:focus {
    color: #f27457;
    background: transparent;
    border-color: #f27457;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous:active {
    color: #bf301d;
    background: transparent;
    border-color: #bf301d;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous[disabled],
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active {
    color: #b1b9c3;
    background: #f6f8fa;
    border-color: #e6e9ec;
    text-shadow: none;
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
    color: #e6492d;
    background: transparent;
    border-color: transparent;
    text-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus {
    color: #f27457;
    background: transparent;
    border-color: transparent;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
    color: #bf301d;
    background: transparent;
    border-color: transparent;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active {
    color: #b1b9c3;
    background: #f6f8fa;
    border-color: #e6e9ec;
    text-shadow: none;
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
    color: currentColor;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-btn-two-chinese-chars::first-letter {
    letter-spacing: 0.34em;
  }
  .ys-webrtc-sdk-ui .ant-btn-two-chinese-chars > *:not(.anticon) {
    margin-right: -0.34em;
    letter-spacing: 0.34em;
  }
  .ys-webrtc-sdk-ui .ant-btn-block {
    width: 100%;
  }
  .ys-webrtc-sdk-ui .ant-btn:empty {
    display: inline-block;
    width: 0;
    visibility: hidden;
    content: '\a0';
  }
  .ys-webrtc-sdk-ui .ysd-btn-square {
    width: 36px;
    height: 36px;
    padding: 4.4px 0;
    font-size: 16px;
    border-radius: 12px;
  }
  .ys-webrtc-sdk-ui .ysd-btn-square > * {
    font-size: 16px;
  }
  .ys-webrtc-sdk-ui .ysd-btn-square.ant-btn-lg {
    width: 44px;
    height: 44px;
    padding: 6.9px 0;
    font-size: 18px;
    border-radius: 12px;
  }
  .ys-webrtc-sdk-ui .ysd-btn-square.ant-btn-lg > * {
    font-size: 18px;
  }
  .ys-webrtc-sdk-ui .ysd-btn-square.ant-btn-sm {
    width: 28px;
    height: 28px;
    padding: 2px 0;
    font-size: 14px;
    border-radius: 8px;
  }
  .ys-webrtc-sdk-ui .ysd-btn-square.ant-btn-sm > * {
    font-size: 14px;
  }
  .ys-webrtc-sdk-ui a.ant-btn {
    padding-top: 0.1px;
    line-height: 34px;
  }
  .ys-webrtc-sdk-ui a.ant-btn-lg {
    line-height: 42px;
  }
  .ys-webrtc-sdk-ui a.ant-btn-sm {
    line-height: 26px;
  }
  .ys-webrtc-sdk-ui .ant-btn-rtl {
    direction: rtl;
  }
  .ys-webrtc-sdk-ui .ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
  .ys-webrtc-sdk-ui .ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary {
    border-right-color: #006ee1;
    border-left-color: #006ee1;
  }
  .ys-webrtc-sdk-ui .ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
  .ys-webrtc-sdk-ui .ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
    border-right-color: #006ee1;
    border-left-color: #006ee1;
  }
  .ys-webrtc-sdk-ui .ant-btn-rtl.ant-btn > .ant-btn-loading-icon .anticon {
    padding-right: 0;
    padding-left: 8px;
  }
  .ys-webrtc-sdk-ui .ant-btn > .ant-btn-loading-icon:only-child .anticon {
    padding-right: 0;
    padding-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-btn-rtl.ant-btn > .anticon + span,
  .ys-webrtc-sdk-ui .ant-btn-rtl.ant-btn > span + .anticon {
    margin-right: 8px;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ysd-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
  }
  .ys-webrtc-sdk-ui .ysd-btn-primary-disabled:not(.ant-btn-dangerous) {
    color: #fff !important;
    background-color: #0054ba !important;
    border: none !important;
    opacity: 0.5;
  }
  .ys-webrtc-sdk-ui .ysd-btn-default-disabled:not(.ant-btn-dangerous),
  .ys-webrtc-sdk-ui .ysd-btn-dashed-disabled:not(.ant-btn-dangerous) {
    color: #0054ba !important;
    background-color: #fff !important;
    border-color: #0054ba !important;
    opacity: 0.5;
  }
  .ys-webrtc-sdk-ui .ysd-btn-Ghost-disabled:not(.ant-btn-dangerous) {
    color: #99a5b2 !important;
    background-color: #f1f2f3 !important;
    border-color: #c8cfd5 !important;
  }
  .ys-webrtc-sdk-ui .ysd-btn-link-disabled:not(.ant-btn-dangerous) {
    color: #0054ba !important;
    opacity: 0.5;
  }
  .ys-webrtc-sdk-ui .ysd-btn-success {
    color: #fff;
    background-color: #21bf73;
    border: none;
    border-top-color: #21bf73 !important;
  }
  .ys-webrtc-sdk-ui .ysd-btn-success:hover,
  .ys-webrtc-sdk-ui .ysd-btn-success:focus {
    background-color: #43cc85;
  }
  .ys-webrtc-sdk-ui .ysd-btn-success:active {
    background-color: #12995c;
  }
  .ys-webrtc-sdk-ui .ysd-btn-success-disabled {
    color: #fff !important;
    background-color: #12995c !important;
  }
  .ys-webrtc-sdk-ui .ysd-btn-success-disabled {
    opacity: 0.5;
  }
  .ys-webrtc-sdk-ui .ysd-btn-warning {
    color: #fff;
    background-color: #ffb400;
    border: none;
    border-top-color: #ffb400 !important;
  }
  .ys-webrtc-sdk-ui .ysd-btn-warning:hover,
  .ys-webrtc-sdk-ui .ysd-btn-warning:focus {
    background-color: #ffc629;
  }
  .ys-webrtc-sdk-ui .ysd-btn-warning:active {
    background-color: #d99100;
  }
  .ys-webrtc-sdk-ui .ysd-btn-warning-disabled {
    color: #fff !important;
    background-color: #fff0a3 !important;
  }
  .ys-webrtc-sdk-ui .ysd-btn-warning-disabled {
    background-color: #fff0a3 !important;
  }
  .ys-webrtc-sdk-ui .ysd-btn-error {
    color: #fff;
    background-color: #e6492d;
    border: none;
    border-top-color: #e6492d !important;
  }
  .ys-webrtc-sdk-ui .ysd-btn-error:hover,
  .ys-webrtc-sdk-ui .ysd-btn-error:focus {
    background-color: #f27457;
  }
  .ys-webrtc-sdk-ui .ysd-btn-error:active {
    background-color: #bf301d;
  }
  .ys-webrtc-sdk-ui .ysd-btn-error-disabled {
    color: #fff !important;
    background-color: #bf301d !important;
  }
  .ys-webrtc-sdk-ui .ysd-btn-error-disabled {
    opacity: 0.5;
  }
  .ys-webrtc-sdk-ui .ysd-btn-success.ant-btn-dangerous,
  .ys-webrtc-sdk-ui .ysd-btn-warning.ant-btn-dangerous,
  .ys-webrtc-sdk-ui .ysd-btn-error.ant-btn-dangerous {
    border-top-color: #e6492d !important;
  }
  .ys-webrtc-sdk-ui .ysd-btn-text {
    min-width: auto;
  }
  .ys-webrtc-sdk-ui .ysd-btn-prefix .anticon,
  .ys-webrtc-sdk-ui .ysd-btn-suffix .anticon {
    position: relative;
    top: 1px;
  }
  .ys-webrtc-sdk-ui .ysd-btn-prefix {
    margin-right: 4px;
  }
  .ys-webrtc-sdk-ui .ysd-btn-prefix > span,
  .ys-webrtc-sdk-ui .ysd-btn-prefix > div {
    line-height: inherit;
  }
  .ys-webrtc-sdk-ui .ysd-btn-suffix {
    margin-left: 4px;
  }
  .ys-webrtc-sdk-ui .ysd-btn-suffix > span,
  .ys-webrtc-sdk-ui .ysd-btn-suffix > div {
    line-height: inherit;
  }
  .ys-webrtc-sdk-ui .ysd-btn.ant-btn .anticon {
    color: inherit !important;
    font-size: 16px;
    line-height: 1;
  }
  .ys-webrtc-sdk-ui .ysd-btn.ant-btn-sm .anticon {
    font-size: 12px;
  }
  .ys-webrtc-sdk-ui .ysd-btn > .anticon + span,
  .ys-webrtc-sdk-ui .ysd-btn > span + .anticon {
    margin-left: 4px;
  }
  .ys-webrtc-sdk-ui .ant-btn-primary {
    border: none;
  }
  .ys-webrtc-sdk-ui .ant-btn-circle {
    padding-right: 0;
    padding-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ysd-btn-success {
    color: #21bf73;
    text-shadow: none;
    background-color: transparent !important ;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ysd-btn-success:hover,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ysd-btn-success:focus {
    color: #43cc85;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ysd-btn-success:active {
    color: #12995c;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ysd-btn-warning {
    color: #ffb400;
    text-shadow: none;
    background-color: transparent !important ;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ysd-btn-warning:hover,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ysd-btn-warning:focus {
    color: #ffc629;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ysd-btn-warning:active {
    color: #d99100;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ysd-btn-error {
    color: #e6492d;
    text-shadow: none;
    background-color: transparent !important ;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ysd-btn-error:hover,
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ysd-btn-error:focus {
    color: #f27457;
  }
  .ys-webrtc-sdk-ui .ant-btn-background-ghost.ysd-btn-error:active {
    color: #bf301d;
  }
  .ys-webrtc-sdk-ui .ant-btn:not(.ant-btn-circle):not(.ysd-btn-square) {
    min-width: 72px;
    padding: 0 16px;
  }
  .ys-webrtc-sdk-ui .ant-btn:not(.ant-btn-circle):not(.ysd-btn-square) > .anticon + span,
  .ys-webrtc-sdk-ui .ant-btn:not(.ant-btn-circle):not(.ysd-btn-square) > span + .anticon {
    margin-left: 8px;
  }
  .ys-webrtc-sdk-ui .ant-btn-sm:not(.ant-btn-circle):not(.ysd-btn-square) {
    min-width: 56px;
    padding: 0 12px;
  }
  .ys-webrtc-sdk-ui .ant-btn-sm:not(.ant-btn-circle):not(.ysd-btn-square) > .anticon + span,
  .ys-webrtc-sdk-ui .ant-btn-sm:not(.ant-btn-circle):not(.ysd-btn-square) > span + .anticon {
    margin-left: 4px;
  }
  .ys-webrtc-sdk-ui .ant-btn-lg:not(.ant-btn-circle):not(.ysd-btn-square) {
    min-width: 88px;
    padding: 0 20px;
  }
  .ys-webrtc-sdk-ui .ant-btn-lg:not(.ant-btn-circle):not(.ysd-btn-square) > .anticon + span,
  .ys-webrtc-sdk-ui .ant-btn-lg:not(.ant-btn-circle):not(.ysd-btn-square) > span + .anticon {
    margin-left: 8px;
  }
  
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  .ys-webrtc-sdk-ui .scroll-bar-style {
    scrollbar-width: thin;
    scrollbar-color: #e6e9ec #fff;
    /* 定义滚动条轨道内阴影 */
    /* 定义滑块内阴影 */
    /* 滚动条 */
  }
  .ys-webrtc-sdk-ui .scroll-bar-style::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .scroll-bar-style::-webkit-scrollbar-thumb {
    background-color: #e6e9ec;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .scroll-bar-style::-webkit-scrollbar {
    width: 6px;
    padding-right: 50%;
    background-color: #fff;
    border-radius: 3px;
  }
  .ys-webrtc-sdk-ui .ant-tooltip {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #23282f;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    font-size: 14px;
    line-height: 1.57142857;
    position: absolute;
    z-index: 1070;
    display: block;
    max-width: 250px;
    visibility: visible;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-hidden {
    display: none;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-placement-top,
  .ys-webrtc-sdk-ui .ant-tooltip-placement-topLeft,
  .ys-webrtc-sdk-ui .ant-tooltip-placement-topRight {
    padding-bottom: 9px;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-placement-right,
  .ys-webrtc-sdk-ui .ant-tooltip-placement-rightTop,
  .ys-webrtc-sdk-ui .ant-tooltip-placement-rightBottom {
    padding-left: 9px;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-placement-bottom,
  .ys-webrtc-sdk-ui .ant-tooltip-placement-bottomLeft,
  .ys-webrtc-sdk-ui .ant-tooltip-placement-bottomRight {
    padding-top: 9px;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-placement-left,
  .ys-webrtc-sdk-ui .ant-tooltip-placement-leftTop,
  .ys-webrtc-sdk-ui .ant-tooltip-placement-leftBottom {
    padding-right: 9px;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-inner {
    min-width: 30px;
    max-height: 600px;
    padding: 6px 8px;
    overflow-y: auto;
    color: #fff;
    text-align: left;
    text-decoration: none;
    word-wrap: break-word;
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 8px;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.02), 0 2px 6px 6px rgba(0, 0, 0, 0.02), 0 2px 6px 0 rgba(0, 0, 0, 0.06);
  }
  .ys-webrtc-sdk-ui .ant-tooltip-arrow {
    position: absolute;
    display: block;
    width: 20.14213562px;
    height: 20.14213562px;
    overflow: hidden;
    background: transparent;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-arrow-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 10px;
    height: 10px;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.9);
    content: '';
    pointer-events: auto;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-placement-top .ant-tooltip-arrow,
  .ys-webrtc-sdk-ui .ant-tooltip-placement-topLeft .ant-tooltip-arrow,
  .ys-webrtc-sdk-ui .ant-tooltip-placement-topRight .ant-tooltip-arrow {
    bottom: -11.14213562px;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-placement-top .ant-tooltip-arrow-content,
  .ys-webrtc-sdk-ui .ant-tooltip-placement-topLeft .ant-tooltip-arrow-content,
  .ys-webrtc-sdk-ui .ant-tooltip-placement-topRight .ant-tooltip-arrow-content {
    border-radius: 0 0 3px 0;
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
    -webkit-transform: translateY(-10.07106781px) rotate(45deg);
            transform: translateY(-10.07106781px) rotate(45deg);
  }
  .ys-webrtc-sdk-ui .ant-tooltip-placement-top .ant-tooltip-arrow {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .ys-webrtc-sdk-ui .ant-tooltip-placement-topLeft .ant-tooltip-arrow {
    left: 13px;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-placement-topRight .ant-tooltip-arrow {
    right: 13px;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-placement-right .ant-tooltip-arrow,
  .ys-webrtc-sdk-ui .ant-tooltip-placement-rightTop .ant-tooltip-arrow,
  .ys-webrtc-sdk-ui .ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
    left: -11.14213562px;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-placement-right .ant-tooltip-arrow-content,
  .ys-webrtc-sdk-ui .ant-tooltip-placement-rightTop .ant-tooltip-arrow-content,
  .ys-webrtc-sdk-ui .ant-tooltip-placement-rightBottom .ant-tooltip-arrow-content {
    border-radius: 0 0 0 3px;
    box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
    -webkit-transform: translateX(10.07106781px) rotate(45deg);
            transform: translateX(10.07106781px) rotate(45deg);
  }
  .ys-webrtc-sdk-ui .ant-tooltip-placement-right .ant-tooltip-arrow {
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .ys-webrtc-sdk-ui .ant-tooltip-placement-rightTop .ant-tooltip-arrow {
    top: 5px;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
    bottom: 5px;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-placement-left .ant-tooltip-arrow,
  .ys-webrtc-sdk-ui .ant-tooltip-placement-leftTop .ant-tooltip-arrow,
  .ys-webrtc-sdk-ui .ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
    right: -11.14213562px;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-placement-left .ant-tooltip-arrow-content,
  .ys-webrtc-sdk-ui .ant-tooltip-placement-leftTop .ant-tooltip-arrow-content,
  .ys-webrtc-sdk-ui .ant-tooltip-placement-leftBottom .ant-tooltip-arrow-content {
    border-radius: 0 3px 0 0;
    box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
    -webkit-transform: translateX(-10.07106781px) rotate(45deg);
            transform: translateX(-10.07106781px) rotate(45deg);
  }
  .ys-webrtc-sdk-ui .ant-tooltip-placement-left .ant-tooltip-arrow {
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .ys-webrtc-sdk-ui .ant-tooltip-placement-leftTop .ant-tooltip-arrow {
    top: 5px;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
    bottom: 5px;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-placement-bottom .ant-tooltip-arrow,
  .ys-webrtc-sdk-ui .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
  .ys-webrtc-sdk-ui .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
    top: -11.14213562px;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-placement-bottom .ant-tooltip-arrow-content,
  .ys-webrtc-sdk-ui .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow-content,
  .ys-webrtc-sdk-ui .ant-tooltip-placement-bottomRight .ant-tooltip-arrow-content {
    border-radius: 3px 0 0 0;
    box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
    -webkit-transform: translateY(10.07106781px) rotate(45deg);
            transform: translateY(10.07106781px) rotate(45deg);
  }
  .ys-webrtc-sdk-ui .ant-tooltip-placement-bottom .ant-tooltip-arrow {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .ys-webrtc-sdk-ui .ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
    left: 13px;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
    right: 13px;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-pink .ant-tooltip-inner {
    background-color: #eb2f96;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-pink .ant-tooltip-arrow-content {
    background-color: #eb2f96;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-magenta .ant-tooltip-inner {
    background-color: #eb2f96;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-magenta .ant-tooltip-arrow-content {
    background-color: #eb2f96;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-red .ant-tooltip-inner {
    background-color: #e6492d;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-red .ant-tooltip-arrow-content {
    background-color: #e6492d;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-volcano .ant-tooltip-inner {
    background-color: #fa541c;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-volcano .ant-tooltip-arrow-content {
    background-color: #fa541c;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-orange .ant-tooltip-inner {
    background-color: #ffb400;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-orange .ant-tooltip-arrow-content {
    background-color: #ffb400;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-yellow .ant-tooltip-inner {
    background-color: #fbe63c;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-yellow .ant-tooltip-arrow-content {
    background-color: #fbe63c;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-gold .ant-tooltip-inner {
    background-color: #faad14;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-gold .ant-tooltip-arrow-content {
    background-color: #faad14;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-cyan .ant-tooltip-inner {
    background-color: #13c2c2;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-cyan .ant-tooltip-arrow-content {
    background-color: #13c2c2;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-lime .ant-tooltip-inner {
    background-color: #a0d911;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-lime .ant-tooltip-arrow-content {
    background-color: #a0d911;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-green .ant-tooltip-inner {
    background-color: #21bf73;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-green .ant-tooltip-arrow-content {
    background-color: #21bf73;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-blue .ant-tooltip-inner {
    background-color: #006ee1;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-blue .ant-tooltip-arrow-content {
    background-color: #006ee1;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-geekblue .ant-tooltip-inner {
    background-color: #2f54eb;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-geekblue .ant-tooltip-arrow-content {
    background-color: #2f54eb;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-purple .ant-tooltip-inner {
    background-color: #722ed1;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-purple .ant-tooltip-arrow-content {
    background-color: #722ed1;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-rtl {
    direction: rtl;
  }
  .ys-webrtc-sdk-ui .ant-tooltip-rtl .ant-tooltip-inner {
    text-align: right;
  }
  .ys-webrtc-sdk-ui .ysd-tooltip-ellipsis {
    max-width: 400px;
  }
  .ys-webrtc-sdk-ui .ysd-tooltip-ellipsis > * {
    color: #fff;
  }
  .ys-webrtc-sdk-ui .ysd-tooltip-ellipsis-content-single {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
  }
  .ys-webrtc-sdk-ui .ysd-tooltip-ellipsis-content-multi {
    display: -webkit-box;
    width: 100%;
    overflow: hidden;
    word-break: break-all;
    -webkit-box-orient: vertical;
  }
  .ys-webrtc-sdk-ui .ysd-tooltip-small .ant-tooltip-inner {
    padding: 4px 8px;
    font-size: 12px;
  }
  .ys-webrtc-sdk-ui .ysd-tooltip-middle .ant-tooltip-inner {
    padding: 6px 8px;
    font-size: 14px;
  }
  .ys-webrtc-sdk-ui .ysd-tooltip-large .ant-tooltip-inner {
    padding: 8px 12px;
    font-size: 16px;
  }
  .ys-webrtc-sdk-ui .ysd-tooltip-white .ant-tooltip-inner {
    color: #000;
  }
  
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  .ys-webrtc-sdk-ui .scroll-bar-style {
    scrollbar-width: thin;
    scrollbar-color: #e6e9ec #fff;
    /* 定义滚动条轨道内阴影 */
    /* 定义滑块内阴影 */
    /* 滚动条 */
  }
  .ys-webrtc-sdk-ui .scroll-bar-style::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .scroll-bar-style::-webkit-scrollbar-thumb {
    background-color: #e6e9ec;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .scroll-bar-style::-webkit-scrollbar {
    width: 6px;
    padding-right: 50%;
    background-color: #fff;
    border-radius: 3px;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
    color: #e6492d;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
    color: #fff;
    background-color: #e6492d;
  }
  .ys-webrtc-sdk-ui .ant-dropdown {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #23282f;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    font-size: 14px;
    line-height: 1.57142857;
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 1050;
    display: block;
  }
  .ys-webrtc-sdk-ui .ant-dropdown::before {
    position: absolute;
    top: 6px;
    right: 0;
    bottom: 6px;
    left: -7px;
    z-index: -9999;
    opacity: 0.0001;
    content: ' ';
  }
  .ys-webrtc-sdk-ui .ant-dropdown-wrap {
    position: relative;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-wrap .ant-btn > .anticon-down {
    font-size: 10px;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-wrap .anticon-down::before {
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-wrap-open .anticon-down::before {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  .ys-webrtc-sdk-ui .ant-dropdown-hidden,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-hidden {
    display: none;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-placement-topCenter,
  .ys-webrtc-sdk-ui .ant-dropdown-placement-topLeft,
  .ys-webrtc-sdk-ui .ant-dropdown-placement-topRight {
    padding-bottom: 4px;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-placement-bottomCenter,
  .ys-webrtc-sdk-ui .ant-dropdown-placement-bottomLeft,
  .ys-webrtc-sdk-ui .ant-dropdown-placement-bottomRight {
    padding-top: 4px;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-arrow {
    position: absolute;
    z-index: 1;
    display: block;
    width: 14.14213562px;
    height: 14.14213562px;
    background: transparent;
    border-style: solid;
    border-width: 7.07106781px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  .ys-webrtc-sdk-ui .ant-dropdown-placement-topCenter > .ant-dropdown-arrow,
  .ys-webrtc-sdk-ui .ant-dropdown-placement-topLeft > .ant-dropdown-arrow,
  .ys-webrtc-sdk-ui .ant-dropdown-placement-topRight > .ant-dropdown-arrow {
    bottom: -3.8px;
    border-top-color: transparent;
    border-right-color: #fff;
    border-bottom-color: #fff;
    border-left-color: transparent;
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  }
  .ys-webrtc-sdk-ui .ant-dropdown-placement-topCenter > .ant-dropdown-arrow {
    left: 50%;
    -webkit-transform: translateX(-50%) rotate(45deg);
            transform: translateX(-50%) rotate(45deg);
  }
  .ys-webrtc-sdk-ui .ant-dropdown-placement-topLeft > .ant-dropdown-arrow {
    left: 16px;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-placement-topRight > .ant-dropdown-arrow {
    right: 16px;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow,
  .ys-webrtc-sdk-ui .ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
  .ys-webrtc-sdk-ui .ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
    top: -4px;
    border-top-color: #fff;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: #fff;
    box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
  }
  .ys-webrtc-sdk-ui .ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow {
    left: 50%;
    -webkit-transform: translateX(-50%) rotate(45deg);
            transform: translateX(-50%) rotate(45deg);
  }
  .ys-webrtc-sdk-ui .ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow {
    left: 16px;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
    right: 16px;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu {
    position: relative;
    margin: 0;
    padding: 4px 0;
    text-align: left;
    list-style-type: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 12px;
    outline: none;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.02), 0 2px 6px 6px rgba(0, 0, 0, 0.02), 0 2px 6px 0 rgba(0, 0, 0, 0.06);
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-item .ant-dropdown-menu-item-icon {
    color: #778090;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-item:hover .ant-dropdown-menu-item-icon {
    color: #268ded;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-item-selected .ant-dropdown-menu-item-icon {
    color: inherit;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-item-group-title {
    padding: 5px 12px;
    color: rgba(0, 0, 0, 0.6);
    transition: all 0.3s;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-submenu-popup {
    position: absolute;
    z-index: 1050;
    background: transparent;
    box-shadow: none;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-submenu-popup ul,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-submenu-popup li {
    list-style: none;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-item,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-submenu-title {
    clear: both;
    margin: 0;
    padding: 5px 12px;
    color: #23282f;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    white-space: nowrap;
    cursor: pointer;
    transition: all 0.3s;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-item > .anticon:first-child,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-submenu-title > .anticon:first-child,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-item > a > .anticon:first-child,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-submenu-title > a > .anticon:first-child,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-item > span > .anticon:first-child,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-submenu-title > span > .anticon:first-child {
    min-width: 12px;
    margin-right: 8px;
    font-size: 16px;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-item > a,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-submenu-title > a {
    display: block;
    margin: -5px -12px;
    padding: 5px 12px;
    color: #23282f;
    transition: all 0.3s;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-item > a:hover,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-submenu-title > a:hover {
    color: #268ded;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-item > .anticon + span > a,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-submenu-title > .anticon + span > a {
    color: #23282f;
    transition: all 0.3s;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-item > .anticon + span > a:hover,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-submenu-title > .anticon + span > a:hover {
    color: #268ded;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-item-selected,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-submenu-title-selected,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-item-selected > a,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-submenu-title-selected > a {
    color: #23282f;
    background-color: #f6f8fa;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-item:hover,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-submenu-title:hover {
    color: #268ded;
    background-color: #e6f6ff;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-item-disabled,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-submenu-title-disabled {
    color: #c8cfd5;
    cursor: not-allowed;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-item-disabled:hover,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-submenu-title-disabled:hover {
    color: #c8cfd5;
    background-color: #fff;
    cursor: not-allowed;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-item-disabled > .anticon + span > a,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-submenu-title-disabled > .anticon + span > a,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-item-disabled > a,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-submenu-title-disabled > a {
    position: relative;
    color: #c8cfd5;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-item-disabled > .anticon + span > a::after,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-submenu-title-disabled > .anticon + span > a::after,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-item-disabled > a::after,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-submenu-title-disabled > a::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: not-allowed;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-item-divider,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-submenu-title-divider {
    height: 1px;
    margin: 4px 0;
    overflow: hidden;
    line-height: 0;
    background-color: #e6e9ec;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
    position: absolute;
    right: 16px;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    margin-right: 0 !important;
    color: rgba(0, 0, 0, 0.6);
    font-size: 10px;
    font-style: normal;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-item-group-list {
    margin: 0 8px;
    padding: 0;
    list-style: none;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-submenu-title {
    padding-right: 36px;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-submenu-vertical {
    position: relative;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
    position: absolute;
    top: 0;
    left: 100%;
    min-width: 100%;
    margin-left: 4px;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
    color: #b1b9c3;
    background-color: #fff;
    cursor: not-allowed;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
    color: #006ee1;
  }
  .ys-webrtc-sdk-ui .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomLeft,
  .ys-webrtc-sdk-ui .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomLeft,
  .ys-webrtc-sdk-ui .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomCenter,
  .ys-webrtc-sdk-ui .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomCenter,
  .ys-webrtc-sdk-ui .ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomRight,
  .ys-webrtc-sdk-ui .ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomRight {
    -webkit-animation-name: antSlideUpIn;
            animation-name: antSlideUpIn;
  }
  .ys-webrtc-sdk-ui .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topLeft,
  .ys-webrtc-sdk-ui .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topLeft,
  .ys-webrtc-sdk-ui .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topCenter,
  .ys-webrtc-sdk-ui .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topCenter,
  .ys-webrtc-sdk-ui .ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topRight,
  .ys-webrtc-sdk-ui .ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topRight {
    -webkit-animation-name: antSlideDownIn;
            animation-name: antSlideDownIn;
  }
  .ys-webrtc-sdk-ui .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomLeft,
  .ys-webrtc-sdk-ui .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomCenter,
  .ys-webrtc-sdk-ui .ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomRight {
    -webkit-animation-name: antSlideUpOut;
            animation-name: antSlideUpOut;
  }
  .ys-webrtc-sdk-ui .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topLeft,
  .ys-webrtc-sdk-ui .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topCenter,
  .ys-webrtc-sdk-ui .ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topRight {
    -webkit-animation-name: antSlideDownOut;
            animation-name: antSlideDownOut;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-trigger > .anticon.anticon-down,
  .ys-webrtc-sdk-ui .ant-dropdown-link > .anticon.anticon-down,
  .ys-webrtc-sdk-ui .ant-dropdown-button > .anticon.anticon-down {
    font-size: 10px;
    vertical-align: baseline;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-button {
    white-space: nowrap;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child):not(.ant-btn-icon-only) {
    padding-right: 8px;
    padding-left: 8px;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-dark,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-dark .ant-dropdown-menu {
    background: #001529;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-dark .ant-dropdown-menu-item,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-dark .ant-dropdown-menu-item > a,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a {
    color: rgba(255, 255, 255, 0.6);
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a .ant-dropdown-menu-submenu-arrow::after {
    color: rgba(255, 255, 255, 0.6);
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a:hover {
    color: #fff;
    background: transparent;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
  .ys-webrtc-sdk-ui .ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
    color: #fff;
    background: #006ee1;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-rtl {
    direction: rtl;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-rtl.ant-dropdown::before {
    right: -7px;
    left: 0;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu.ant-dropdown-menu-rtl {
    direction: rtl;
    text-align: right;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-rtl .ant-dropdown-menu-item-group-title {
    direction: rtl;
    text-align: right;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-submenu-popup.ant-dropdown-menu-submenu-rtl {
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-rtl .ant-dropdown-menu-submenu-popup ul,
  .ys-webrtc-sdk-ui .ant-dropdown-rtl .ant-dropdown-menu-submenu-popup li {
    text-align: right;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-rtl .ant-dropdown-menu-item,
  .ys-webrtc-sdk-ui .ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
    text-align: right;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-rtl .ant-dropdown-menu-item > .anticon:first-child,
  .ys-webrtc-sdk-ui .ant-dropdown-rtl .ant-dropdown-menu-submenu-title > .anticon:first-child,
  .ys-webrtc-sdk-ui .ant-dropdown-rtl .ant-dropdown-menu-item > span > .anticon:first-child,
  .ys-webrtc-sdk-ui .ant-dropdown-rtl .ant-dropdown-menu-submenu-title > span > .anticon:first-child {
    margin-right: 0;
    margin-left: 8px;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
    right: auto;
    left: 8px;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
  .ys-webrtc-sdk-ui .ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
    margin-left: 0 !important;
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
  }
  .ys-webrtc-sdk-ui .ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
    padding-right: 12px;
    padding-left: 24px;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-rtl .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
    right: 100%;
    left: 0;
    margin-right: 4px;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown .anticon {
    vertical-align: middle;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ysd-menu .ysd-dropdown-menu-group-item {
    height: 32px;
    margin-bottom: 0 !important;
    padding: 4px 8px;
    color: #99a5b2;
    font-size: 12px;
    cursor: default;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ysd-menu .ysd-dropdown-menu-group-item:hover {
    color: #99a5b2;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu {
    padding: 8px;
    border-radius: 12px;
    scrollbar-width: thin;
    scrollbar-color: #e6e9ec #fff;
    /* 定义滚动条轨道内阴影 */
    /* 定义滑块内阴影 */
    /* 滚动条 */
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu::-webkit-scrollbar-thumb {
    background-color: #e6e9ec;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu::-webkit-scrollbar {
    width: 6px;
    padding-right: 50%;
    background-color: #fff;
    border-radius: 3px;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-root {
    min-width: 128px;
    max-height: 360px;
    overflow-y: auto;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-item,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-submenu-title {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 8px 8px;
    color: #23282f;
    font-size: 14px;
    line-height: 20px;
    border-radius: 8px;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-item:hover,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-submenu-title:hover {
    color: #268ded;
    background-color: #e6f6ff;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-item:hover .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-submenu-title:hover .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    color: #268ded;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-item-selected,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-submenu-title-selected,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-item-selected > a,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-submenu-title-selected > a {
    color: #23282f;
    background-color: #f6f8fa;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-item-disabled,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-submenu-title-disabled {
    color: #c8cfd5;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-item-disabled:hover,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-submenu-title-disabled:hover {
    color: #c8cfd5;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-item-disabled > .anticon + span > a,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-submenu-title-disabled > .anticon + span > a,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-item-disabled *,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-submenu-title-disabled * {
    position: relative;
    color: #c8cfd5;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-submenu-open .ant-dropdown-menu-item,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-submenu-open .ant-dropdown-menu-submenu-title {
    color: #268ded;
    background-color: #e6f6ff;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-submenu-open .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-submenu-open .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    color: #268ded;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title {
    color: #c8cfd5;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item-selected,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title-selected,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item-selected > a,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title-selected > a {
    color: #c8cfd5;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    color: #c8cfd5;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item:hover,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title:hover {
    color: #c8cfd5;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item:hover .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title:hover .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    color: #c8cfd5;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-item:not(:last-child),
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-submenu:not(:last-child) {
    margin-bottom: 4px;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu-submenu-title {
    padding-right: 32px;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu .ant-dropdown-menu-submenu-expand-icon {
    right: 8px;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay .ant-dropdown-menu .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    position: relative;
    top: 1px;
    color: #778090;
    font-size: 16px;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu {
    padding: 8px;
    border-radius: 8px;
    scrollbar-width: thin;
    scrollbar-color: #e6e9ec #fff;
    /* 定义滚动条轨道内阴影 */
    /* 定义滑块内阴影 */
    /* 滚动条 */
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu::-webkit-scrollbar-thumb {
    background-color: #e6e9ec;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu::-webkit-scrollbar {
    width: 6px;
    padding-right: 50%;
    background-color: #fff;
    border-radius: 3px;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-root {
    min-width: 128px;
    max-height: 360px;
    overflow-y: auto;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-item,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-submenu-title {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 6px 8px;
    color: #23282f;
    font-size: 12px;
    line-height: 20px;
    border-radius: 4px;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-item:hover,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-submenu-title:hover {
    color: #268ded;
    background-color: #e6f6ff;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-item:hover .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-submenu-title:hover .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    color: #268ded;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-item-selected,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-submenu-title-selected,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-item-selected > a,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-submenu-title-selected > a {
    color: #23282f;
    background-color: #f6f8fa;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-item-disabled,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-submenu-title-disabled {
    color: #c8cfd5;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-item-disabled:hover,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-submenu-title-disabled:hover {
    color: #c8cfd5;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-item-disabled > .anticon + span > a,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-submenu-title-disabled > .anticon + span > a,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-item-disabled *,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-submenu-title-disabled * {
    position: relative;
    color: #c8cfd5;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-submenu-open .ant-dropdown-menu-item,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-submenu-open .ant-dropdown-menu-submenu-title {
    color: #268ded;
    background-color: #e6f6ff;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-submenu-open .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-submenu-open .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    color: #268ded;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title {
    color: #c8cfd5;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item-selected,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title-selected,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item-selected > a,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title-selected > a {
    color: #c8cfd5;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    color: #c8cfd5;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item:hover,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title:hover {
    color: #c8cfd5;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item:hover .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title:hover .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    color: #c8cfd5;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-item:not(:last-child),
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-submenu:not(:last-child) {
    margin-bottom: 4px;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu-submenu-title {
    padding-right: 32px;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu .ant-dropdown-menu-submenu-expand-icon {
    right: 8px;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-small .ant-dropdown-menu .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    position: relative;
    top: 1px;
    color: #778090;
    font-size: 16px;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu {
    padding: 8px;
    border-radius: 12px;
    scrollbar-width: thin;
    scrollbar-color: #e6e9ec #fff;
    /* 定义滚动条轨道内阴影 */
    /* 定义滑块内阴影 */
    /* 滚动条 */
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu::-webkit-scrollbar-thumb {
    background-color: #e6e9ec;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu::-webkit-scrollbar {
    width: 6px;
    padding-right: 50%;
    background-color: #fff;
    border-radius: 3px;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-root {
    min-width: 128px;
    max-height: 360px;
    overflow-y: auto;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-item,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-submenu-title {
    display: flex;
    align-items: center;
    height: 44px;
    padding: 12px 8px;
    color: #23282f;
    font-size: 14px;
    line-height: 20px;
    border-radius: 8px;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-item:hover,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-submenu-title:hover {
    color: #268ded;
    background-color: #e6f6ff;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-item:hover .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-submenu-title:hover .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    color: #268ded;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-item-selected,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-submenu-title-selected,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-item-selected > a,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-submenu-title-selected > a {
    color: #23282f;
    background-color: #f6f8fa;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-item-disabled,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-submenu-title-disabled {
    color: #c8cfd5;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-item-disabled:hover,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-submenu-title-disabled:hover {
    color: #c8cfd5;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-item-disabled > .anticon + span > a,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-submenu-title-disabled > .anticon + span > a,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-item-disabled *,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-submenu-title-disabled * {
    position: relative;
    color: #c8cfd5;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-submenu-open .ant-dropdown-menu-item,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-submenu-open .ant-dropdown-menu-submenu-title {
    color: #268ded;
    background-color: #e6f6ff;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-submenu-open .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-submenu-open .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    color: #268ded;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title {
    color: #c8cfd5;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item-selected,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title-selected,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item-selected > a,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title-selected > a {
    color: #c8cfd5;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    color: #c8cfd5;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item:hover,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title:hover {
    color: #c8cfd5;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item:hover .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title:hover .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    color: #c8cfd5;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-item:not(:last-child),
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-submenu:not(:last-child) {
    margin-bottom: 4px;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu-submenu-title {
    padding-right: 32px;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu .ant-dropdown-menu-submenu-expand-icon {
    right: 8px;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-overlay.ysd-dropdown-overlay-large .ant-dropdown-menu .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    position: relative;
    top: 1px;
    color: #778090;
    font-size: 16px;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-button .ant-btn-default:not(.ant-btn[disabled]) {
    color: #23282f;
    border-color: #e6e9ec;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-button .ant-btn-default:not(.ant-btn[disabled]):hover,
  .ys-webrtc-sdk-ui .ysd-dropdown-button .ant-btn-default:not(.ant-btn[disabled]):focus {
    color: #268ded;
    border-color: #268ded;
  }
  .ys-webrtc-sdk-ui .ysd-dropdown-button .ant-btn-default:not(.ant-btn[disabled]):active {
    color: #0054ba;
    border-color: #0054ba;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle {
    padding-left: 12px;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu {
    padding: 8px;
    border-radius: 12px;
    scrollbar-width: thin;
    scrollbar-color: #e6e9ec #fff;
    /* 定义滚动条轨道内阴影 */
    /* 定义滑块内阴影 */
    /* 滚动条 */
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu::-webkit-scrollbar-thumb {
    background-color: #e6e9ec;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu::-webkit-scrollbar {
    width: 6px;
    padding-right: 50%;
    background-color: #fff;
    border-radius: 3px;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-root {
    min-width: 128px;
    max-height: 360px;
    overflow-y: auto;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-item,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-submenu-title {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 8px 8px;
    color: #23282f;
    font-size: 14px;
    line-height: 20px;
    border-radius: 8px;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-item:hover,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-submenu-title:hover {
    color: #268ded;
    background-color: #e6f6ff;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-item:hover .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-submenu-title:hover .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    color: #268ded;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-item-selected,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-submenu-title-selected,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-item-selected > a,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-submenu-title-selected > a {
    color: #23282f;
    background-color: #f6f8fa;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-item-disabled,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-submenu-title-disabled {
    color: #c8cfd5;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-item-disabled:hover,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-submenu-title-disabled:hover {
    color: #c8cfd5;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-item-disabled > .anticon + span > a,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-submenu-title-disabled > .anticon + span > a,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-item-disabled *,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-submenu-title-disabled * {
    position: relative;
    color: #c8cfd5;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-submenu-open .ant-dropdown-menu-item,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-submenu-open .ant-dropdown-menu-submenu-title {
    color: #268ded;
    background-color: #e6f6ff;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-submenu-open .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-submenu-open .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    color: #268ded;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title {
    color: #c8cfd5;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item-selected,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title-selected,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item-selected > a,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title-selected > a {
    color: #c8cfd5;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    color: #c8cfd5;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item:hover,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title:hover {
    color: #c8cfd5;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item:hover .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title:hover .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    color: #c8cfd5;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-item:not(:last-child),
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-submenu:not(:last-child) {
    margin-bottom: 4px;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu-submenu-title {
    padding-right: 32px;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu .ant-dropdown-menu-submenu-expand-icon {
    right: 8px;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-middle .ant-dropdown-menu .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    position: relative;
    top: 1px;
    color: #778090;
    font-size: 16px;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small {
    padding-left: 12px;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu {
    padding: 8px;
    border-radius: 8px;
    scrollbar-width: thin;
    scrollbar-color: #e6e9ec #fff;
    /* 定义滚动条轨道内阴影 */
    /* 定义滑块内阴影 */
    /* 滚动条 */
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu::-webkit-scrollbar-thumb {
    background-color: #e6e9ec;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu::-webkit-scrollbar {
    width: 6px;
    padding-right: 50%;
    background-color: #fff;
    border-radius: 3px;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-root {
    min-width: 128px;
    max-height: 360px;
    overflow-y: auto;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-item,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-submenu-title {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 6px 8px;
    color: #23282f;
    font-size: 12px;
    line-height: 20px;
    border-radius: 4px;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-item:hover,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-submenu-title:hover {
    color: #268ded;
    background-color: #e6f6ff;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-item:hover .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-submenu-title:hover .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    color: #268ded;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-item-selected,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-submenu-title-selected,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-item-selected > a,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-submenu-title-selected > a {
    color: #23282f;
    background-color: #f6f8fa;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-item-disabled,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-submenu-title-disabled {
    color: #c8cfd5;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-item-disabled:hover,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-submenu-title-disabled:hover {
    color: #c8cfd5;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-item-disabled > .anticon + span > a,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-submenu-title-disabled > .anticon + span > a,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-item-disabled *,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-submenu-title-disabled * {
    position: relative;
    color: #c8cfd5;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-submenu-open .ant-dropdown-menu-item,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-submenu-open .ant-dropdown-menu-submenu-title {
    color: #268ded;
    background-color: #e6f6ff;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-submenu-open .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-submenu-open .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    color: #268ded;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title {
    color: #c8cfd5;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item-selected,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title-selected,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item-selected > a,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title-selected > a {
    color: #c8cfd5;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    color: #c8cfd5;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item:hover,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title:hover {
    color: #c8cfd5;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item:hover .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title:hover .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    color: #c8cfd5;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-item:not(:last-child),
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-submenu:not(:last-child) {
    margin-bottom: 4px;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu-submenu-title {
    padding-right: 32px;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu .ant-dropdown-menu-submenu-expand-icon {
    right: 8px;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-small .ant-dropdown-menu .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    position: relative;
    top: 1px;
    color: #778090;
    font-size: 16px;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large {
    padding-left: 12px;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu {
    padding: 8px;
    border-radius: 12px;
    scrollbar-width: thin;
    scrollbar-color: #e6e9ec #fff;
    /* 定义滚动条轨道内阴影 */
    /* 定义滑块内阴影 */
    /* 滚动条 */
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu::-webkit-scrollbar-thumb {
    background-color: #e6e9ec;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu::-webkit-scrollbar {
    width: 6px;
    padding-right: 50%;
    background-color: #fff;
    border-radius: 3px;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-root {
    min-width: 128px;
    max-height: 360px;
    overflow-y: auto;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-item,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-submenu-title {
    display: flex;
    align-items: center;
    height: 44px;
    padding: 12px 8px;
    color: #23282f;
    font-size: 14px;
    line-height: 20px;
    border-radius: 8px;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-item:hover,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-submenu-title:hover {
    color: #268ded;
    background-color: #e6f6ff;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-item:hover .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-submenu-title:hover .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    color: #268ded;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-item-selected,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-submenu-title-selected,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-item-selected > a,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-submenu-title-selected > a {
    color: #23282f;
    background-color: #f6f8fa;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-item-disabled,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-submenu-title-disabled {
    color: #c8cfd5;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-item-disabled:hover,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-submenu-title-disabled:hover {
    color: #c8cfd5;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-item-disabled > .anticon + span > a,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-submenu-title-disabled > .anticon + span > a,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-item-disabled *,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-submenu-title-disabled * {
    position: relative;
    color: #c8cfd5;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-submenu-open .ant-dropdown-menu-item,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-submenu-open .ant-dropdown-menu-submenu-title {
    color: #268ded;
    background-color: #e6f6ff;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-submenu-open .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-submenu-open .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    color: #268ded;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title {
    color: #c8cfd5;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item-selected,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title-selected,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item-selected > a,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title-selected > a {
    color: #c8cfd5;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    color: #c8cfd5;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item:hover,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title:hover {
    color: #c8cfd5;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-item:hover .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title:hover .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    color: #c8cfd5;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-item:not(:last-child),
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-submenu:not(:last-child) {
    margin-bottom: 4px;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu-submenu-title {
    padding-right: 32px;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu .ant-dropdown-menu-submenu-expand-icon {
    right: 8px;
  }
  .ys-webrtc-sdk-ui .ysd-menu-sub-menu-overlay-large .ant-dropdown-menu .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
    position: relative;
    top: 1px;
    color: #778090;
    font-size: 16px;
  }
  .ys-webrtc-sdk-ui .ysd-menu-second-sub-menu-overlay {
    padding: 0 !important;
    padding-left: 12px !important;
    box-shadow: none !important;
  }
  .ys-webrtc-sdk-ui .ysd-menu-second-sub-menu-overlay .ant-dropdown-menu {
    margin: 0 !important;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-submenu-open .ysd-menu-second-sub-menu-overlay .ant-dropdown-menu-item {
    color: #23282f;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-submenu-open .ysd-menu-second-sub-menu-overlay .ant-dropdown-menu-item:hover {
    color: #268ded;
    background-color: #e6f6ff;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-submenu-open .ysd-menu-second-sub-menu-overlay .ant-dropdown-menu-item-disabled {
    color: #c8cfd5;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ant-dropdown-menu-submenu-open .ysd-menu-second-sub-menu-overlay .ant-dropdown-menu-item-disabled:hover {
    color: #c8cfd5;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ant-btn-default.ysd-btn.ant-dropdown-trigger.ysd-dropdown:not(.ant-btn[disabled]) {
    color: #23282f;
    border-color: #e6e9ec;
  }
  .ys-webrtc-sdk-ui .ant-btn-default.ysd-btn.ant-dropdown-trigger.ysd-dropdown:not(.ant-btn[disabled]):hover,
  .ys-webrtc-sdk-ui .ant-btn-default.ysd-btn.ant-dropdown-trigger.ysd-dropdown:not(.ant-btn[disabled]):focus {
    color: #268ded;
    border-color: #268ded;
  }
  .ys-webrtc-sdk-ui .ant-btn-default.ysd-btn.ant-dropdown-trigger.ysd-dropdown:not(.ant-btn[disabled]):active {
    color: #0054ba;
    border-color: #0054ba;
  }
  
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  .ys-webrtc-sdk-ui .scroll-bar-style {
    scrollbar-width: thin;
    scrollbar-color: #e6e9ec #fff;
    /* 定义滚动条轨道内阴影 */
    /* 定义滑块内阴影 */
    /* 滚动条 */
  }
  .ys-webrtc-sdk-ui .scroll-bar-style::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .scroll-bar-style::-webkit-scrollbar-thumb {
    background-color: #e6e9ec;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .scroll-bar-style::-webkit-scrollbar {
    width: 6px;
    padding-right: 50%;
    background-color: #fff;
    border-radius: 3px;
  }
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 6px 12px;
    color: #23282f;
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #e6e9ec;
    border-radius: 12px;
    transition: all 0.3s;
    display: inline-flex;
  }
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper::-webkit-input-placeholder {
    color: #c8cfd5;
  }
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper::placeholder {
    color: #c8cfd5;
  }
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper:placeholder-shown {
    text-overflow: ellipsis;
  }
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper:hover {
    border-color: #268ded;
    border-right-width: 1px !important;
  }
  .ys-webrtc-sdk-ui .ant-input-rtl .ant-input-affix-wrapper:hover {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper:focus,
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper-focused {
    border-color: #0054ba;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 3px rgba(0, 110, 225, 0.1);
  }
  .ys-webrtc-sdk-ui .ant-input-rtl .ant-input-affix-wrapper:focus,
  .ys-webrtc-sdk-ui .ant-input-rtl .ant-input-affix-wrapper-focused {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper-disabled {
    color: #99a5b2;
    background-color: #f1f2f3;
    cursor: not-allowed;
    opacity: 1;
  }
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper-disabled:hover {
    border-color: #e6e9ec;
    border-right-width: 1px !important;
  }
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper[disabled] {
    color: #99a5b2;
    background-color: #f1f2f3;
    cursor: not-allowed;
    opacity: 1;
  }
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper[disabled]:hover {
    border-color: #e6e9ec;
    border-right-width: 1px !important;
  }
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper-borderless,
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper-borderless:hover,
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper-borderless:focus,
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper-borderless-focused,
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper-borderless-disabled,
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper-borderless[disabled] {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui textarea.ant-input-affix-wrapper {
    max-width: 100%;
    height: auto;
    min-height: 36px;
    line-height: 1.5715;
    vertical-align: bottom;
    transition: all 0.3s, height 0s;
  }
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper-lg {
    padding: 10px 12px;
    font-size: 14px;
    line-height: 1.5715;
  }
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper-sm {
    padding: 2px 12px;
    font-size: 14px;
    line-height: 1.5715;
    border-radius: 8px;
  }
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper-rtl {
    direction: rtl;
  }
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #268ded;
    border-right-width: 1px !important;
    z-index: 1;
  }
  .ys-webrtc-sdk-ui .ant-input-rtl .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ys-webrtc-sdk-ui .ant-input-search-with-button .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    z-index: 0;
  }
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper-focused,
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper:focus {
    z-index: 1;
  }
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper-disabled .ant-input[disabled] {
    background: transparent;
  }
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper > input.ant-input {
    padding: 0;
    line-height: 1;
    border: none;
    outline: none;
    -webkit-transform: translateY(1px);
            transform: translateY(1px);
  }
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper > input.ant-input:focus {
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper::before {
    width: 0;
    visibility: hidden;
    content: '\a0';
  }
  .ys-webrtc-sdk-ui .ant-input-prefix,
  .ys-webrtc-sdk-ui .ant-input-suffix {
    display: flex;
    flex: none;
    align-items: center;
    line-height: 1;
  }
  .ys-webrtc-sdk-ui .ant-input-prefix {
    margin-right: 8px;
  }
  .ys-webrtc-sdk-ui .ant-input-suffix {
    margin-left: 8px;
  }
  .ys-webrtc-sdk-ui .ant-input-suffix .ant-input-clear-icon {
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-input-clear-icon {
    margin: 0 8px;
    color: #b1b9c3;
    font-size: 20px;
    vertical-align: -1px;
    cursor: pointer;
    transition: color 0.3s;
  }
  .ys-webrtc-sdk-ui .ant-input-clear-icon:hover {
    color: rgba(0, 0, 0, 0.6);
  }
  .ys-webrtc-sdk-ui .ant-input-clear-icon:active {
    color: #23282f;
  }
  .ys-webrtc-sdk-ui .ant-input-clear-icon-hidden {
    visibility: hidden;
  }
  .ys-webrtc-sdk-ui .ant-input-clear-icon:last-child {
    margin-right: 0;
  }
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper-textarea-with-clear-btn {
    padding: 0 !important;
    border: 0 !important;
  }
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper-textarea-with-clear-btn .ant-input-clear-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 1;
  }
  .ys-webrtc-sdk-ui .ant-input {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    line-height: 1.57142857;
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 6px 12px;
    color: #23282f;
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #e6e9ec;
    border-radius: 12px;
    transition: all 0.3s;
  }
  .ys-webrtc-sdk-ui .ant-input::-webkit-input-placeholder {
    color: #c8cfd5;
  }
  .ys-webrtc-sdk-ui .ant-input::placeholder {
    color: #c8cfd5;
  }
  .ys-webrtc-sdk-ui .ant-input:placeholder-shown {
    text-overflow: ellipsis;
  }
  .ys-webrtc-sdk-ui .ant-input:hover {
    border-color: #268ded;
    border-right-width: 1px !important;
  }
  .ys-webrtc-sdk-ui .ant-input-rtl .ant-input:hover {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ys-webrtc-sdk-ui .ant-input:focus,
  .ys-webrtc-sdk-ui .ant-input-focused {
    border-color: #0054ba;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 3px rgba(0, 110, 225, 0.1);
  }
  .ys-webrtc-sdk-ui .ant-input-rtl .ant-input:focus,
  .ys-webrtc-sdk-ui .ant-input-rtl .ant-input-focused {
    border-right-width: 0;
    border-left-width: 1px !important;
  }
  .ys-webrtc-sdk-ui .ant-input-disabled {
    color: #99a5b2;
    background-color: #f1f2f3;
    cursor: not-allowed;
    opacity: 1;
  }
  .ys-webrtc-sdk-ui .ant-input-disabled:hover {
    border-color: #e6e9ec;
    border-right-width: 1px !important;
  }
  .ys-webrtc-sdk-ui .ant-input[disabled] {
    color: #99a5b2;
    background-color: #f1f2f3;
    cursor: not-allowed;
    opacity: 1;
  }
  .ys-webrtc-sdk-ui .ant-input[disabled]:hover {
    border-color: #e6e9ec;
    border-right-width: 1px !important;
  }
  .ys-webrtc-sdk-ui .ant-input-borderless,
  .ys-webrtc-sdk-ui .ant-input-borderless:hover,
  .ys-webrtc-sdk-ui .ant-input-borderless:focus,
  .ys-webrtc-sdk-ui .ant-input-borderless-focused,
  .ys-webrtc-sdk-ui .ant-input-borderless-disabled,
  .ys-webrtc-sdk-ui .ant-input-borderless[disabled] {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui textarea.ant-input {
    max-width: 100%;
    height: auto;
    min-height: 36px;
    line-height: 1.5715;
    vertical-align: bottom;
    transition: all 0.3s, height 0s;
  }
  .ys-webrtc-sdk-ui .ant-input-lg {
    padding: 10px 12px;
    font-size: 14px;
    line-height: 1.5715;
  }
  .ys-webrtc-sdk-ui .ant-input-sm {
    padding: 2px 12px;
    font-size: 14px;
    line-height: 1.5715;
    border-radius: 8px;
  }
  .ys-webrtc-sdk-ui .ant-input-rtl {
    direction: rtl;
  }
  .ys-webrtc-sdk-ui .ant-input-group {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #23282f;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    font-size: 14px;
    line-height: 1.57142857;
    position: relative;
    display: table;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }
  .ys-webrtc-sdk-ui .ant-input-group[class*='col-'] {
    float: none;
    padding-right: 0;
    padding-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-input-group > [class*='col-'] {
    padding-right: 8px;
  }
  .ys-webrtc-sdk-ui .ant-input-group > [class*='col-']:last-child {
    padding-right: 0;
  }
  .ys-webrtc-sdk-ui .ant-input-group-addon,
  .ys-webrtc-sdk-ui .ant-input-group-wrap,
  .ys-webrtc-sdk-ui .ant-input-group > .ant-input {
    display: table-cell;
  }
  .ys-webrtc-sdk-ui .ant-input-group-addon:not(:first-child):not(:last-child),
  .ys-webrtc-sdk-ui .ant-input-group-wrap:not(:first-child):not(:last-child),
  .ys-webrtc-sdk-ui .ant-input-group > .ant-input:not(:first-child):not(:last-child) {
    border-radius: 0;
  }
  .ys-webrtc-sdk-ui .ant-input-group-addon,
  .ys-webrtc-sdk-ui .ant-input-group-wrap {
    width: 1px;
    white-space: nowrap;
    vertical-align: middle;
  }
  .ys-webrtc-sdk-ui .ant-input-group-wrap > * {
    display: block !important;
  }
  .ys-webrtc-sdk-ui .ant-input-group .ant-input {
    float: left;
    width: 100%;
    margin-bottom: 0;
    text-align: inherit;
  }
  .ys-webrtc-sdk-ui .ant-input-group .ant-input:focus {
    z-index: 1;
    border-right-width: 1px;
  }
  .ys-webrtc-sdk-ui .ant-input-group .ant-input:hover {
    z-index: 1;
    border-right-width: 1px;
  }
  .ys-webrtc-sdk-ui .ant-input-search-with-button .ant-input-group .ant-input:hover {
    z-index: 0;
  }
  .ys-webrtc-sdk-ui .ant-input-group-addon {
    position: relative;
    padding: 0 12px;
    color: #23282f;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    background-color: #f6f8fa;
    border: 1px solid #e6e9ec;
    border-radius: 12px;
    transition: all 0.3s;
  }
  .ys-webrtc-sdk-ui .ant-input-group-addon .ant-select {
    margin: -7px -12px;
  }
  .ys-webrtc-sdk-ui .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    background-color: inherit;
    border: 1px solid transparent;
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-input-group-addon .ant-select-open .ant-select-selector,
  .ys-webrtc-sdk-ui .ant-input-group-addon .ant-select-focused .ant-select-selector {
    color: #006ee1;
  }
  .ys-webrtc-sdk-ui .ant-input-group > .ant-input:first-child,
  .ys-webrtc-sdk-ui .ant-input-group-addon:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ys-webrtc-sdk-ui .ant-input-group > .ant-input:first-child .ant-select .ant-select-selector,
  .ys-webrtc-sdk-ui .ant-input-group-addon:first-child .ant-select .ant-select-selector {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ys-webrtc-sdk-ui .ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ys-webrtc-sdk-ui .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ys-webrtc-sdk-ui .ant-input-group-addon:first-child {
    border-right: 0;
  }
  .ys-webrtc-sdk-ui .ant-input-group-addon:last-child {
    border-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-input-group > .ant-input:last-child,
  .ys-webrtc-sdk-ui .ant-input-group-addon:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ys-webrtc-sdk-ui .ant-input-group > .ant-input:last-child .ant-select .ant-select-selector,
  .ys-webrtc-sdk-ui .ant-input-group-addon:last-child .ant-select .ant-select-selector {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ys-webrtc-sdk-ui .ant-input-group-lg .ant-input,
  .ys-webrtc-sdk-ui .ant-input-group-lg > .ant-input-group-addon {
    padding: 10px 12px;
    font-size: 14px;
    line-height: 1.5715;
  }
  .ys-webrtc-sdk-ui .ant-input-group-sm .ant-input,
  .ys-webrtc-sdk-ui .ant-input-group-sm > .ant-input-group-addon {
    padding: 2px 12px;
    font-size: 14px;
    line-height: 1.5715;
    border-radius: 8px;
  }
  .ys-webrtc-sdk-ui .ant-input-group-lg .ant-select-single .ant-select-selector {
    height: 44px;
  }
  .ys-webrtc-sdk-ui .ant-input-group-sm .ant-select-single .ant-select-selector {
    height: 28px;
  }
  .ys-webrtc-sdk-ui .ant-input-group .ant-input-affix-wrapper:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ys-webrtc-sdk-ui .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ys-webrtc-sdk-ui .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact {
    display: block;
  }
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact::before {
    display: table;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact::after {
    display: table;
    clear: both;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
    border-right-width: 1px;
  }
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):hover {
    z-index: 1;
  }
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):focus {
    z-index: 1;
  }
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > * {
    display: inline-block;
    float: none;
    vertical-align: top;
    border-radius: 0;
  }
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > .ant-input-affix-wrapper {
    display: inline-flex;
  }
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > .ant-picker-range {
    display: inline-flex;
  }
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > *:not(:last-child) {
    margin-right: -1px;
    border-right-width: 1px;
  }
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact .ant-input {
    float: none;
  }
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector,
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input {
    border-right-width: 1px;
    border-radius: 0;
  }
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:hover,
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:hover,
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:hover,
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:hover {
    z-index: 1;
  }
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:focus,
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:focus,
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:focus,
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:focus {
    z-index: 1;
  }
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > .ant-select-focused {
    z-index: 1;
  }
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > .ant-select > .ant-select-arrow {
    z-index: 1;
  }
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > *:first-child,
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > *:last-child,
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
    border-right-width: 1px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input {
    vertical-align: top;
  }
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper {
    margin-left: -1px;
  }
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper .ant-input-affix-wrapper {
    border-radius: 0;
  }
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input-group-addon > .ant-input-search-button {
    border-radius: 0;
  }
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
    border-radius: 12px 0 0 12px;
  }
  .ys-webrtc-sdk-ui .ant-input-group > .ant-input-rtl:first-child,
  .ys-webrtc-sdk-ui .ant-input-group-rtl .ant-input-group-addon:first-child {
    border-radius: 0 12px 12px 0;
  }
  .ys-webrtc-sdk-ui .ant-input-group-rtl .ant-input-group-addon:first-child {
    border-right: 1px solid #e6e9ec;
    border-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-input-group-rtl .ant-input-group-addon:last-child {
    border-right: 0;
    border-left: 1px solid #e6e9ec;
  }
  .ys-webrtc-sdk-ui .ant-input-group-rtl.ant-input-group > .ant-input:last-child,
  .ys-webrtc-sdk-ui .ant-input-group-rtl.ant-input-group-addon:last-child {
    border-radius: 12px 0 0 12px;
  }
  .ys-webrtc-sdk-ui .ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
    border-radius: 12px 0 0 12px;
  }
  .ys-webrtc-sdk-ui .ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-radius: 0 12px 12px 0;
  }
  .ys-webrtc-sdk-ui .ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:not(:last-child) {
    margin-right: 0;
    margin-left: -1px;
    border-left-width: 1px;
  }
  .ys-webrtc-sdk-ui .ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:first-child,
  .ys-webrtc-sdk-ui .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
  .ys-webrtc-sdk-ui .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
  .ys-webrtc-sdk-ui .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
    border-radius: 0 12px 12px 0;
  }
  .ys-webrtc-sdk-ui .ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:last-child,
  .ys-webrtc-sdk-ui .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
  .ys-webrtc-sdk-ui .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
  .ys-webrtc-sdk-ui .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
  .ys-webrtc-sdk-ui .ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
    border-left-width: 1px;
    border-radius: 12px 0 0 12px;
  }
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl + .ant-input-group-wrapper-rtl {
    margin-right: -1px;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
    border-radius: 0 12px 12px 0;
  }
  .ys-webrtc-sdk-ui .ant-input-group-wrapper {
    display: inline-block;
    width: 100%;
    text-align: start;
    vertical-align: top;
  }
  .ys-webrtc-sdk-ui .ant-input-password-icon {
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    transition: all 0.3s;
  }
  .ys-webrtc-sdk-ui .ant-input-password-icon:hover {
    color: rgba(0, 0, 0, 0.85);
  }
  .ys-webrtc-sdk-ui .ant-input[type='color'] {
    height: 36px;
  }
  .ys-webrtc-sdk-ui .ant-input[type='color'].ant-input-lg {
    height: 44px;
  }
  .ys-webrtc-sdk-ui .ant-input[type='color'].ant-input-sm {
    height: 28px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  .ys-webrtc-sdk-ui .ant-input-textarea-show-count::after {
    float: right;
    color: rgba(0, 0, 0, 0.6);
    white-space: nowrap;
    content: attr(data-count);
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-input-search .ant-input:hover,
  .ys-webrtc-sdk-ui .ant-input-search .ant-input:focus {
    border-color: #268ded;
  }
  .ys-webrtc-sdk-ui .ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
  .ys-webrtc-sdk-ui .ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
    border-left-color: #268ded;
  }
  .ys-webrtc-sdk-ui .ant-input-search .ant-input-affix-wrapper {
    border-radius: 0;
  }
  .ys-webrtc-sdk-ui .ant-input-search .ant-input-lg {
    line-height: 1.5713;
  }
  .ys-webrtc-sdk-ui .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    left: -1px;
    padding: 0;
    border: 0;
  }
  .ys-webrtc-sdk-ui .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0 12px 12px 0;
  }
  .ys-webrtc-sdk-ui .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
    color: #778090;
  }
  .ys-webrtc-sdk-ui .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary).ant-btn-loading::before {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .ys-webrtc-sdk-ui .ant-input-search-button {
    height: 36px;
  }
  .ys-webrtc-sdk-ui .ant-input-search-button:hover,
  .ys-webrtc-sdk-ui .ant-input-search-button:focus {
    z-index: 1;
  }
  .ys-webrtc-sdk-ui .ant-input-search-large .ant-input-search-button {
    height: 44px;
  }
  .ys-webrtc-sdk-ui .ant-input-search-small .ant-input-search-button {
    height: 28px;
  }
  .ys-webrtc-sdk-ui .ant-input-group-wrapper-rtl {
    direction: rtl;
  }
  .ys-webrtc-sdk-ui .ant-input-group-rtl {
    direction: rtl;
  }
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper.ant-input-affix-wrapper-rtl > input.ant-input {
    border: none;
    outline: none;
  }
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper-rtl .ant-input-prefix {
    margin: 0 0 0 8px;
  }
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper-rtl .ant-input-suffix {
    margin: 0 8px 0 0;
  }
  .ys-webrtc-sdk-ui .ant-input-textarea-rtl {
    direction: rtl;
  }
  .ys-webrtc-sdk-ui .ant-input-textarea-rtl.ant-input-textarea-show-count::after {
    text-align: left;
  }
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper-rtl .ant-input-clear-icon:last-child {
    margin-right: 8px;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper-rtl .ant-input-clear-icon {
    right: auto;
    left: 8px;
  }
  .ys-webrtc-sdk-ui .ant-input-search-rtl {
    direction: rtl;
  }
  .ys-webrtc-sdk-ui .ant-input-search-rtl .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
  .ys-webrtc-sdk-ui .ant-input-search-rtl .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
    border-right-color: #268ded;
    border-left-color: #e6e9ec;
  }
  .ys-webrtc-sdk-ui .ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper:hover,
  .ys-webrtc-sdk-ui .ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper-focused {
    border-right-color: #268ded;
  }
  .ys-webrtc-sdk-ui .ant-input-search-rtl > .ant-input-group > .ant-input-group-addon {
    right: -1px;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-input-search-rtl > .ant-input-group > .ant-input-group-addon .ant-input-search-button {
    border-radius: 12px 0 0 12px;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ys-webrtc-sdk-ui .ant-input {
      height: 36px;
    }
    .ys-webrtc-sdk-ui .ant-input-lg {
      height: 44px;
    }
    .ys-webrtc-sdk-ui .ant-input-sm {
      height: 28px;
    }
    .ys-webrtc-sdk-ui .ant-input-affix-wrapper > input.ant-input {
      height: auto;
    }
  }
  .ys-webrtc-sdk-ui .default-btn-color {
    border-color: #e6e9ec;
  }
  .ys-webrtc-sdk-ui .default-btn-color:hover,
  .ys-webrtc-sdk-ui .default-btn-color:focus {
    border-color: #268ded;
  }
  .ys-webrtc-sdk-ui .default-btn-color:active {
    border-color: #0054ba;
  }
  .ys-webrtc-sdk-ui .ysd-input .ant-input {
    line-height: 22px;
  }
  .ys-webrtc-sdk-ui .ysd-input .ant-input-prefix {
    max-width: 30%;
    margin-right: 8px;
    color: #778090;
    -webkit-transform: translateY(1px);
            transform: translateY(1px);
  }
  .ys-webrtc-sdk-ui .ysd-input .ant-input-suffix {
    max-width: 30%;
    margin-left: 8px;
    color: #778090;
    -webkit-transform: translateY(1px);
            transform: translateY(1px);
  }
  .ys-webrtc-sdk-ui .ysd-input .ant-input-group-addon .ant-btn {
    border-color: #e6e9ec;
  }
  .ys-webrtc-sdk-ui .ysd-input .ant-input-group-addon .ant-btn:hover,
  .ys-webrtc-sdk-ui .ysd-input .ant-input-group-addon .ant-btn:focus {
    border-color: #268ded;
  }
  .ys-webrtc-sdk-ui .ysd-input .ant-input-group-addon .ant-btn:active {
    border-color: #0054ba;
  }
  .ys-webrtc-sdk-ui .ysd-input-textarea {
    padding: 4px 22px 4px 8px;
    scrollbar-width: thin;
    scrollbar-color: #e6e9ec #fff;
  }
  .ys-webrtc-sdk-ui .ysd-input-search .ant-input-search-button {
    border-color: #e6e9ec;
  }
  .ys-webrtc-sdk-ui .ysd-input-search .ant-input-search-button:hover,
  .ys-webrtc-sdk-ui .ysd-input-search .ant-input-search-button:focus {
    border-color: #268ded;
  }
  .ys-webrtc-sdk-ui .ysd-input-search .ant-input-search-button:active {
    border-color: #0054ba;
  }
  .ys-webrtc-sdk-ui .ysd-input-password .ant-input-suffix .ysd-icon {
    color: #b1b9c3;
  }
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper-textarea-with-clear-btn .ant-input-clear-icon {
    right: 12px;
    margin: 0;
  }
  .ys-webrtc-sdk-ui .ysd-input.ant-input {
    color: #23282f;
    border-radius: 12px;
  }
  .ys-webrtc-sdk-ui .ysd-input.ant-input:focus,
  .ys-webrtc-sdk-ui .ysd-input.ant-input-focused {
    border-color: #0054ba;
  }
  .ys-webrtc-sdk-ui .ysd-input.ant-input-sm {
    padding-top: 2px;
    padding-bottom: 2px;
    font-size: 14px;
    border-radius: 8px;
  }
  .ys-webrtc-sdk-ui .ysd-input.ant-input-lg {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
  }
  .ys-webrtc-sdk-ui .ysd-input.ant-input-affix-wrapper-disabled,
  .ys-webrtc-sdk-ui .ysd-input.ant-input[disabled] {
    color: #99a5b2;
    border-color: #c8cfd5;
  }
  .ys-webrtc-sdk-ui .ysd-input-textarea {
    padding: 8px 12px;
    color: #353d45;
  }
  .ys-webrtc-sdk-ui .ysd-input-textarea:focus,
  .ys-webrtc-sdk-ui .ysd-input-textarea-focused {
    border-color: #0054ba;
  }
  .ys-webrtc-sdk-ui .ysd-input-textarea-affix-wrapper-disabled,
  .ys-webrtc-sdk-ui .ysd-input-textarea[disabled] {
    color: #99a5b2;
    border-color: #c8cfd5;
  }
  .ys-webrtc-sdk-ui .ysd-input .ant-input {
    border-radius: 12px;
  }
  .ys-webrtc-sdk-ui .ysd-input .ant-input-group .ant-input-group-addon {
    border-radius: 12px;
  }
  .ys-webrtc-sdk-ui .ysd-input .ant-input-group .ant-input-group-addon .ysd-icon {
    color: #778090;
  }
  .ys-webrtc-sdk-ui .ant-input {
    border-radius: 12px;
  }
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper .ant-input {
    border-radius: 0;
  }
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper .ysd-input-textarea {
    border-radius: 12px;
  }
  .ys-webrtc-sdk-ui .ant-input-suffix .ant-input-clear-icon,
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper-textarea-with-clear-btn .ant-input-clear-icon {
    color: #778090;
  }
  .ys-webrtc-sdk-ui .ant-input-suffix .ant-input-clear-icon:hover,
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper-textarea-with-clear-btn .ant-input-clear-icon:hover {
    color: #99a5b2;
  }
  .ys-webrtc-sdk-ui .ant-input-suffix .ant-input-clear-icon:active,
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper-textarea-with-clear-btn .ant-input-clear-icon:active {
    color: #353d45;
  }
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper-textarea-with-clear-btn .ant-input {
    padding: 8px 40px 8px 12px;
  }
  .ys-webrtc-sdk-ui .ant-input-group > .ant-input:first-child,
  .ys-webrtc-sdk-ui .ant-input-group-addon:first-child {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .ys-webrtc-sdk-ui .ant-input-group > .ant-input:last-child,
  .ys-webrtc-sdk-ui .ant-input-group-addon:last-child {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .ys-webrtc-sdk-ui .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-radius: 12px;
  }
  .ys-webrtc-sdk-ui .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .ys-webrtc-sdk-ui .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
    border-radius: 0 12px 12px 0;
  }
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > *:first-child,
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
    border-radius: 0;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > *:last-child,
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
  .ys-webrtc-sdk-ui .ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
    border-radius: 0;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper .ysd-icon,
  .ys-webrtc-sdk-ui .ant-input-group-affix-wrapper .ysd-icon,
  .ys-webrtc-sdk-ui .ant-input-search-affix-wrapper .ysd-icon,
  .ys-webrtc-sdk-ui .ant-input-password-affix-wrapper .ysd-icon,
  .ys-webrtc-sdk-ui .ant-input-textarea-affix-wrapper .ysd-icon {
    color: #778090;
    font-size: 20px;
  }
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper .ysd-icon.ysd-icon-default-feedback,
  .ys-webrtc-sdk-ui .ant-input-group-affix-wrapper .ysd-icon.ysd-icon-default-feedback,
  .ys-webrtc-sdk-ui .ant-input-search-affix-wrapper .ysd-icon.ysd-icon-default-feedback,
  .ys-webrtc-sdk-ui .ant-input-password-affix-wrapper .ysd-icon.ysd-icon-default-feedback,
  .ys-webrtc-sdk-ui .ant-input-textarea-affix-wrapper .ysd-icon.ysd-icon-default-feedback {
    color: #778090;
  }
  .ys-webrtc-sdk-ui .ant-input-affix-wrapper-sm .ysd-icon,
  .ys-webrtc-sdk-ui .ant-input-group-affix-wrapper-sm .ysd-icon,
  .ys-webrtc-sdk-ui .ant-input-search-affix-wrapper-sm .ysd-icon,
  .ys-webrtc-sdk-ui .ant-input-password-affix-wrapper-sm .ysd-icon,
  .ys-webrtc-sdk-ui .ant-input-textarea-affix-wrapper-sm .ysd-icon {
    font-size: 16px;
  }
  .ys-webrtc-sdk-ui .ant-input .ant-input-wrapper .ysd-icon,
  .ys-webrtc-sdk-ui .ant-input-group .ant-input-wrapper .ysd-icon,
  .ys-webrtc-sdk-ui .ant-input-search .ant-input-wrapper .ysd-icon,
  .ys-webrtc-sdk-ui .ant-input-password .ant-input-wrapper .ysd-icon,
  .ys-webrtc-sdk-ui .ant-input-textarea .ant-input-wrapper .ysd-icon {
    font-size: 20px;
  }
  .ys-webrtc-sdk-ui .ant-input .ant-input-wrapper-sm .ysd-icon,
  .ys-webrtc-sdk-ui .ant-input-group .ant-input-wrapper-sm .ysd-icon,
  .ys-webrtc-sdk-ui .ant-input-search .ant-input-wrapper-sm .ysd-icon,
  .ys-webrtc-sdk-ui .ant-input-password .ant-input-wrapper-sm .ysd-icon,
  .ys-webrtc-sdk-ui .ant-input-textarea .ant-input-wrapper-sm .ysd-icon {
    font-size: 16px;
  }
  
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  .ys-webrtc-sdk-ui [class^=ant-]::-ms-clear,
  .ys-webrtc-sdk-ui [class*= ant-]::-ms-clear,
  .ys-webrtc-sdk-ui [class^=ant-] input::-ms-clear,
  .ys-webrtc-sdk-ui [class*= ant-] input::-ms-clear,
  .ys-webrtc-sdk-ui [class^=ant-] input::-ms-reveal,
  .ys-webrtc-sdk-ui [class*= ant-] input::-ms-reveal {
    display: none;
  }
  .ys-webrtc-sdk-ui [class^=ant-],
  .ys-webrtc-sdk-ui [class*= ant-],
  .ys-webrtc-sdk-ui [class^=ant-] *,
  .ys-webrtc-sdk-ui [class*= ant-] *,
  .ys-webrtc-sdk-ui [class^=ant-] *::before,
  .ys-webrtc-sdk-ui [class*= ant-] *::before,
  .ys-webrtc-sdk-ui [class^=ant-] *::after,
  .ys-webrtc-sdk-ui [class*= ant-] *::after {
    box-sizing: border-box;
  }
  /* stylelint-disable at-rule-no-unknown */
  .ys-webrtc-sdk-ui html,
  .ys-webrtc-sdk-ui body {
    width: 100%;
    height: 100%;
  }
  .ys-webrtc-sdk-ui input::-ms-clear,
  .ys-webrtc-sdk-ui input::-ms-reveal {
    display: none;
  }
  .ys-webrtc-sdk-ui *,
  .ys-webrtc-sdk-ui *::before,
  .ys-webrtc-sdk-ui *::after {
    box-sizing: border-box;
  }
  .ys-webrtc-sdk-ui html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui body {
    margin: 0;
    color: #23282f;
    font-family: -apple-system, 'Helvetica Neue', 'Helvetica', 'PingFang SC', 'SegoeUI', 'Microsoft YaHei', '微软雅黑', 'Noto Sans CJK SC', 'Arial', sans-serif;
    font-variant: tabular-nums;
    background-color: #fff;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    font-size: 14px;
    line-height: 1.57142857;
  }
  .ys-webrtc-sdk-ui [tabindex='-1']:focus {
    outline: none !important;
  }
  .ys-webrtc-sdk-ui hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }
  .ys-webrtc-sdk-ui h1,
  .ys-webrtc-sdk-ui h2,
  .ys-webrtc-sdk-ui h3,
  .ys-webrtc-sdk-ui h4,
  .ys-webrtc-sdk-ui h5,
  .ys-webrtc-sdk-ui h6 {
    margin-top: 0;
    margin-bottom: 0.5em;
    color: #23282f;
    font-weight: 500;
  }
  .ys-webrtc-sdk-ui p {
    margin-top: 0;
    margin-bottom: 1em;
  }
  .ys-webrtc-sdk-ui abbr[title],
  .ys-webrtc-sdk-ui abbr[data-original-title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
            text-decoration: underline dotted;
    border-bottom: 0;
    cursor: help;
  }
  .ys-webrtc-sdk-ui address {
    margin-bottom: 1em;
    font-style: normal;
    line-height: inherit;
  }
  .ys-webrtc-sdk-ui input[type='text'],
  .ys-webrtc-sdk-ui input[type='password'],
  .ys-webrtc-sdk-ui input[type='number'],
  .ys-webrtc-sdk-ui textarea {
    -webkit-appearance: none;
  }
  .ys-webrtc-sdk-ui ol,
  .ys-webrtc-sdk-ui ul,
  .ys-webrtc-sdk-ui dl {
    margin-top: 0;
    margin-bottom: 1em;
  }
  .ys-webrtc-sdk-ui ol ol,
  .ys-webrtc-sdk-ui ul ul,
  .ys-webrtc-sdk-ui ol ul,
  .ys-webrtc-sdk-ui ul ol {
    margin-bottom: 0;
  }
  .ys-webrtc-sdk-ui dt {
    font-weight: 500;
  }
  .ys-webrtc-sdk-ui dd {
    margin-bottom: 0.5em;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui blockquote {
    margin: 0 0 1em;
  }
  .ys-webrtc-sdk-ui dfn {
    font-style: italic;
  }
  .ys-webrtc-sdk-ui b,
  .ys-webrtc-sdk-ui strong {
    font-weight: bolder;
  }
  .ys-webrtc-sdk-ui small {
    font-size: 80%;
  }
  .ys-webrtc-sdk-ui sub,
  .ys-webrtc-sdk-ui sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
  }
  .ys-webrtc-sdk-ui sub {
    bottom: -0.25em;
  }
  .ys-webrtc-sdk-ui sup {
    top: -0.5em;
  }
  .ys-webrtc-sdk-ui a {
    color: #006ee1;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    -webkit-text-decoration-skip: objects;
  }
  .ys-webrtc-sdk-ui a:hover {
    color: #268ded;
  }
  .ys-webrtc-sdk-ui a:active {
    color: #0054ba;
  }
  .ys-webrtc-sdk-ui a:active,
  .ys-webrtc-sdk-ui a:hover {
    text-decoration: none;
    outline: 0;
  }
  .ys-webrtc-sdk-ui a:focus {
    text-decoration: none;
    outline: 0;
  }
  .ys-webrtc-sdk-ui a[disabled] {
    color: #b1b9c3;
    cursor: not-allowed;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui pre,
  .ys-webrtc-sdk-ui code,
  .ys-webrtc-sdk-ui kbd,
  .ys-webrtc-sdk-ui samp {
    font-size: 1em;
    font-family: Courier, monospace;
  }
  .ys-webrtc-sdk-ui pre {
    margin-top: 0;
    margin-bottom: 1em;
    overflow: auto;
  }
  .ys-webrtc-sdk-ui figure {
    margin: 0 0 1em;
  }
  .ys-webrtc-sdk-ui img {
    vertical-align: middle;
    border-style: none;
  }
  .ys-webrtc-sdk-ui svg:not(:root) {
    overflow: hidden;
  }
  .ys-webrtc-sdk-ui a,
  .ys-webrtc-sdk-ui area,
  .ys-webrtc-sdk-ui button,
  .ys-webrtc-sdk-ui [role='button'],
  .ys-webrtc-sdk-ui input:not([type='range']),
  .ys-webrtc-sdk-ui label,
  .ys-webrtc-sdk-ui select,
  .ys-webrtc-sdk-ui summary,
  .ys-webrtc-sdk-ui textarea {
    touch-action: manipulation;
  }
  .ys-webrtc-sdk-ui table {
    border-collapse: collapse;
  }
  .ys-webrtc-sdk-ui caption {
    padding-top: 0.75em;
    padding-bottom: 0.3em;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
    caption-side: bottom;
  }
  .ys-webrtc-sdk-ui th {
    text-align: inherit;
  }
  .ys-webrtc-sdk-ui input,
  .ys-webrtc-sdk-ui button,
  .ys-webrtc-sdk-ui select,
  .ys-webrtc-sdk-ui optgroup,
  .ys-webrtc-sdk-ui textarea {
    margin: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
  }
  .ys-webrtc-sdk-ui button,
  .ys-webrtc-sdk-ui input {
    overflow: visible;
  }
  .ys-webrtc-sdk-ui button,
  .ys-webrtc-sdk-ui select {
    text-transform: none;
  }
  .ys-webrtc-sdk-ui button,
  .ys-webrtc-sdk-ui html [type="button"],
  .ys-webrtc-sdk-ui [type="reset"],
  .ys-webrtc-sdk-ui [type="submit"] {
    -webkit-appearance: button;
  }
  .ys-webrtc-sdk-ui button::-moz-focus-inner,
  .ys-webrtc-sdk-ui [type='button']::-moz-focus-inner,
  .ys-webrtc-sdk-ui [type='reset']::-moz-focus-inner,
  .ys-webrtc-sdk-ui [type='submit']::-moz-focus-inner {
    padding: 0;
    border-style: none;
  }
  .ys-webrtc-sdk-ui input[type='radio'],
  .ys-webrtc-sdk-ui input[type='checkbox'] {
    box-sizing: border-box;
    padding: 0;
  }
  .ys-webrtc-sdk-ui input[type='date'],
  .ys-webrtc-sdk-ui input[type='time'],
  .ys-webrtc-sdk-ui input[type='datetime-local'],
  .ys-webrtc-sdk-ui input[type='month'] {
    -webkit-appearance: listbox;
  }
  .ys-webrtc-sdk-ui textarea {
    overflow: auto;
    resize: vertical;
  }
  .ys-webrtc-sdk-ui fieldset {
    min-width: 0;
    margin: 0;
    padding: 0;
    border: 0;
  }
  .ys-webrtc-sdk-ui legend {
    display: block;
    width: 100%;
    max-width: 100%;
    margin-bottom: 0.5em;
    padding: 0;
    color: inherit;
    font-size: 1.5em;
    line-height: inherit;
    white-space: normal;
  }
  .ys-webrtc-sdk-ui progress {
    vertical-align: baseline;
  }
  .ys-webrtc-sdk-ui [type='number']::-webkit-inner-spin-button,
  .ys-webrtc-sdk-ui [type='number']::-webkit-outer-spin-button {
    height: auto;
  }
  .ys-webrtc-sdk-ui [type='search'] {
    outline-offset: -2px;
    -webkit-appearance: none;
  }
  .ys-webrtc-sdk-ui [type='search']::-webkit-search-cancel-button,
  .ys-webrtc-sdk-ui [type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
  }
  .ys-webrtc-sdk-ui ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
  }
  .ys-webrtc-sdk-ui output {
    display: inline-block;
  }
  .ys-webrtc-sdk-ui summary {
    display: list-item;
  }
  .ys-webrtc-sdk-ui template {
    display: none;
  }
  .ys-webrtc-sdk-ui [hidden] {
    display: none !important;
  }
  .ys-webrtc-sdk-ui mark {
    padding: 0.2em;
    background-color: #fefff0;
  }
  .ys-webrtc-sdk-ui ::selection {
    color: #fff;
    background: #006ee1;
  }
  .ys-webrtc-sdk-ui .clearfix::before {
    display: table;
    content: '';
  }
  .ys-webrtc-sdk-ui .clearfix::after {
    display: table;
    clear: both;
    content: '';
  }
  .ys-webrtc-sdk-ui .flex {
    display: flex;
    align-items: center;
  }
  .ys-webrtc-sdk-ui .flex-item {
    flex: 1;
  }
  .ys-webrtc-sdk-ui ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: inherit;
  }
  /* 滚动槽 */
  .ys-webrtc-sdk-ui ::-webkit-scrollbar-track {
    border-radius: 100px;
  }
  /* 滚动条滑块 */
  .ys-webrtc-sdk-ui ::-webkit-scrollbar-thumb {
    background: #e6e9ec;
    border-radius: 100px;
  }
  .ys-webrtc-sdk-ui ::-webkit-scrollbar-button {
    height: 6px;
    background: transparent;
  }
  .ys-webrtc-sdk-ui .anticon {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .ys-webrtc-sdk-ui .anticon > * {
    line-height: 1;
  }
  .ys-webrtc-sdk-ui .anticon svg {
    display: inline-block;
  }
  .ys-webrtc-sdk-ui .anticon::before {
    display: none;
  }
  .ys-webrtc-sdk-ui .anticon .anticon-icon {
    display: block;
  }
  .ys-webrtc-sdk-ui .anticon[tabindex] {
    cursor: pointer;
  }
  .ys-webrtc-sdk-ui .anticon-spin::before {
    display: inline-block;
    -webkit-animation: loadingCircle 1s infinite linear;
            animation: loadingCircle 1s infinite linear;
  }
  .ys-webrtc-sdk-ui .anticon-spin {
    display: inline-block;
    -webkit-animation: loadingCircle 1s infinite linear;
            animation: loadingCircle 1s infinite linear;
  }
  .ys-webrtc-sdk-ui .ant-fade-enter,
  .ys-webrtc-sdk-ui .ant-fade-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-fade-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-fade-enter.ant-fade-enter-active,
  .ys-webrtc-sdk-ui .ant-fade-appear.ant-fade-appear-active {
    -webkit-animation-name: antFadeIn;
            animation-name: antFadeIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .ys-webrtc-sdk-ui .ant-fade-leave.ant-fade-leave-active {
    -webkit-animation-name: antFadeOut;
            animation-name: antFadeOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-fade-enter,
  .ys-webrtc-sdk-ui .ant-fade-appear {
    opacity: 0;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  .ys-webrtc-sdk-ui .ant-fade-leave {
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  @-webkit-keyframes antFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes antFadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @-webkit-keyframes antFadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes antFadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .ys-webrtc-sdk-ui .ant-move-up-enter,
  .ys-webrtc-sdk-ui .ant-move-up-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-move-up-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-move-up-enter.ant-move-up-enter-active,
  .ys-webrtc-sdk-ui .ant-move-up-appear.ant-move-up-appear-active {
    -webkit-animation-name: antMoveUpIn;
            animation-name: antMoveUpIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .ys-webrtc-sdk-ui .ant-move-up-leave.ant-move-up-leave-active {
    -webkit-animation-name: antMoveUpOut;
            animation-name: antMoveUpOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-move-up-enter,
  .ys-webrtc-sdk-ui .ant-move-up-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .ys-webrtc-sdk-ui .ant-move-up-leave {
    -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
            animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  }
  .ys-webrtc-sdk-ui .ant-move-down-enter,
  .ys-webrtc-sdk-ui .ant-move-down-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-move-down-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-move-down-enter.ant-move-down-enter-active,
  .ys-webrtc-sdk-ui .ant-move-down-appear.ant-move-down-appear-active {
    -webkit-animation-name: antMoveDownIn;
            animation-name: antMoveDownIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .ys-webrtc-sdk-ui .ant-move-down-leave.ant-move-down-leave-active {
    -webkit-animation-name: antMoveDownOut;
            animation-name: antMoveDownOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-move-down-enter,
  .ys-webrtc-sdk-ui .ant-move-down-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .ys-webrtc-sdk-ui .ant-move-down-leave {
    -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
            animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  }
  .ys-webrtc-sdk-ui .ant-move-left-enter,
  .ys-webrtc-sdk-ui .ant-move-left-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-move-left-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-move-left-enter.ant-move-left-enter-active,
  .ys-webrtc-sdk-ui .ant-move-left-appear.ant-move-left-appear-active {
    -webkit-animation-name: antMoveLeftIn;
            animation-name: antMoveLeftIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .ys-webrtc-sdk-ui .ant-move-left-leave.ant-move-left-leave-active {
    -webkit-animation-name: antMoveLeftOut;
            animation-name: antMoveLeftOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-move-left-enter,
  .ys-webrtc-sdk-ui .ant-move-left-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .ys-webrtc-sdk-ui .ant-move-left-leave {
    -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
            animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  }
  .ys-webrtc-sdk-ui .ant-move-right-enter,
  .ys-webrtc-sdk-ui .ant-move-right-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-move-right-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-move-right-enter.ant-move-right-enter-active,
  .ys-webrtc-sdk-ui .ant-move-right-appear.ant-move-right-appear-active {
    -webkit-animation-name: antMoveRightIn;
            animation-name: antMoveRightIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .ys-webrtc-sdk-ui .ant-move-right-leave.ant-move-right-leave-active {
    -webkit-animation-name: antMoveRightOut;
            animation-name: antMoveRightOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-move-right-enter,
  .ys-webrtc-sdk-ui .ant-move-right-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .ys-webrtc-sdk-ui .ant-move-right-leave {
    -webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
            animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  }
  @-webkit-keyframes antMoveDownIn {
    0% {
      -webkit-transform: translateY(100%);
              transform: translateY(100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
  }
  @keyframes antMoveDownIn {
    0% {
      -webkit-transform: translateY(100%);
              transform: translateY(100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
  }
  @-webkit-keyframes antMoveDownOut {
    0% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(100%);
              transform: translateY(100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
  }
  @keyframes antMoveDownOut {
    0% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(100%);
              transform: translateY(100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
  }
  @-webkit-keyframes antMoveLeftIn {
    0% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
  }
  @keyframes antMoveLeftIn {
    0% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
  }
  @-webkit-keyframes antMoveLeftOut {
    0% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
  }
  @keyframes antMoveLeftOut {
    0% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
  }
  @-webkit-keyframes antMoveRightIn {
    0% {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
  }
  @keyframes antMoveRightIn {
    0% {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
  }
  @-webkit-keyframes antMoveRightOut {
    0% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
  }
  @keyframes antMoveRightOut {
    0% {
      -webkit-transform: translateX(0%);
              transform: translateX(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(100%);
              transform: translateX(100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
  }
  @-webkit-keyframes antMoveUpIn {
    0% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
  }
  @keyframes antMoveUpIn {
    0% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
  }
  @-webkit-keyframes antMoveUpOut {
    0% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
  }
  @keyframes antMoveUpOut {
    0% {
      -webkit-transform: translateY(0%);
              transform: translateY(0%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(-100%);
              transform: translateY(-100%);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
      opacity: 0;
    }
  }
  @-webkit-keyframes loadingCircle {
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes loadingCircle {
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  .ys-webrtc-sdk-ui [ant-click-animating='true'],
  .ys-webrtc-sdk-ui [ant-click-animating-without-extra-node='true'] {
    position: relative;
  }
  .ys-webrtc-sdk-ui html {
    --antd-wave-shadow-color: #006ee1;
    --scroll-bar: 0;
  }
  .ys-webrtc-sdk-ui [ant-click-animating-without-extra-node='true']::after,
  .ys-webrtc-sdk-ui .ant-click-animating-node {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    border-radius: inherit;
    box-shadow: 0 0 0 4px #006ee1;
    box-shadow: 0 0 0 4px var(--antd-wave-shadow-color);
    opacity: 0.2;
    -webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
            animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    content: '';
    pointer-events: none;
  }
  @-webkit-keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 #006ee1;
      box-shadow: 0 0 0 4px var(--antd-wave-shadow-color);
    }
  }
  @keyframes waveEffect {
    100% {
      box-shadow: 0 0 0 #006ee1;
      box-shadow: 0 0 0 4px var(--antd-wave-shadow-color);
    }
  }
  @-webkit-keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
  @keyframes fadeEffect {
    100% {
      opacity: 0;
    }
  }
  .ys-webrtc-sdk-ui .ant-slide-up-enter,
  .ys-webrtc-sdk-ui .ant-slide-up-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-slide-up-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-slide-up-enter.ant-slide-up-enter-active,
  .ys-webrtc-sdk-ui .ant-slide-up-appear.ant-slide-up-appear-active {
    -webkit-animation-name: antSlideUpIn;
            animation-name: antSlideUpIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .ys-webrtc-sdk-ui .ant-slide-up-leave.ant-slide-up-leave-active {
    -webkit-animation-name: antSlideUpOut;
            animation-name: antSlideUpOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-slide-up-enter,
  .ys-webrtc-sdk-ui .ant-slide-up-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
            animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  .ys-webrtc-sdk-ui .ant-slide-up-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  .ys-webrtc-sdk-ui .ant-slide-down-enter,
  .ys-webrtc-sdk-ui .ant-slide-down-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-slide-down-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-slide-down-enter.ant-slide-down-enter-active,
  .ys-webrtc-sdk-ui .ant-slide-down-appear.ant-slide-down-appear-active {
    -webkit-animation-name: antSlideDownIn;
            animation-name: antSlideDownIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .ys-webrtc-sdk-ui .ant-slide-down-leave.ant-slide-down-leave-active {
    -webkit-animation-name: antSlideDownOut;
            animation-name: antSlideDownOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-slide-down-enter,
  .ys-webrtc-sdk-ui .ant-slide-down-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
            animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  .ys-webrtc-sdk-ui .ant-slide-down-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  .ys-webrtc-sdk-ui .ant-slide-left-enter,
  .ys-webrtc-sdk-ui .ant-slide-left-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-slide-left-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-slide-left-enter.ant-slide-left-enter-active,
  .ys-webrtc-sdk-ui .ant-slide-left-appear.ant-slide-left-appear-active {
    -webkit-animation-name: antSlideLeftIn;
            animation-name: antSlideLeftIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .ys-webrtc-sdk-ui .ant-slide-left-leave.ant-slide-left-leave-active {
    -webkit-animation-name: antSlideLeftOut;
            animation-name: antSlideLeftOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-slide-left-enter,
  .ys-webrtc-sdk-ui .ant-slide-left-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
            animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  .ys-webrtc-sdk-ui .ant-slide-left-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  .ys-webrtc-sdk-ui .ant-slide-right-enter,
  .ys-webrtc-sdk-ui .ant-slide-right-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-slide-right-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-slide-right-enter.ant-slide-right-enter-active,
  .ys-webrtc-sdk-ui .ant-slide-right-appear.ant-slide-right-appear-active {
    -webkit-animation-name: antSlideRightIn;
            animation-name: antSlideRightIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .ys-webrtc-sdk-ui .ant-slide-right-leave.ant-slide-right-leave-active {
    -webkit-animation-name: antSlideRightOut;
            animation-name: antSlideRightOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-slide-right-enter,
  .ys-webrtc-sdk-ui .ant-slide-right-appear {
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
            animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  }
  .ys-webrtc-sdk-ui .ant-slide-right-leave {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
            animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  }
  @-webkit-keyframes antSlideUpIn {
    0% {
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
  }
  @keyframes antSlideUpIn {
    0% {
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
  }
  @-webkit-keyframes antSlideUpOut {
    0% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
  }
  @keyframes antSlideUpOut {
    0% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
  }
  @-webkit-keyframes antSlideDownIn {
    0% {
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
  }
  @keyframes antSlideDownIn {
    0% {
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
  }
  @-webkit-keyframes antSlideDownOut {
    0% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 0;
    }
  }
  @keyframes antSlideDownOut {
    0% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleY(0.8);
              transform: scaleY(0.8);
      -webkit-transform-origin: 100% 100%;
              transform-origin: 100% 100%;
      opacity: 0;
    }
  }
  @-webkit-keyframes antSlideLeftIn {
    0% {
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
  }
  @keyframes antSlideLeftIn {
    0% {
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
  }
  @-webkit-keyframes antSlideLeftOut {
    0% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
  }
  @keyframes antSlideLeftOut {
    0% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
      opacity: 0;
    }
  }
  @-webkit-keyframes antSlideRightIn {
    0% {
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 1;
    }
  }
  @keyframes antSlideRightIn {
    0% {
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 1;
    }
  }
  @-webkit-keyframes antSlideRightOut {
    0% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 0;
    }
  }
  @keyframes antSlideRightOut {
    0% {
      -webkit-transform: scaleX(1);
              transform: scaleX(1);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 1;
    }
    100% {
      -webkit-transform: scaleX(0.8);
              transform: scaleX(0.8);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
      opacity: 0;
    }
  }
  .ys-webrtc-sdk-ui .ant-zoom-enter,
  .ys-webrtc-sdk-ui .ant-zoom-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-zoom-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-zoom-enter.ant-zoom-enter-active,
  .ys-webrtc-sdk-ui .ant-zoom-appear.ant-zoom-appear-active {
    -webkit-animation-name: antZoomIn;
            animation-name: antZoomIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .ys-webrtc-sdk-ui .ant-zoom-leave.ant-zoom-leave-active {
    -webkit-animation-name: antZoomOut;
            animation-name: antZoomOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-zoom-enter,
  .ys-webrtc-sdk-ui .ant-zoom-appear {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .ys-webrtc-sdk-ui .ant-zoom-enter-prepare,
  .ys-webrtc-sdk-ui .ant-zoom-appear-prepare {
    -webkit-transform: none;
            transform: none;
  }
  .ys-webrtc-sdk-ui .ant-zoom-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
            animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .ys-webrtc-sdk-ui .ant-zoom-big-enter,
  .ys-webrtc-sdk-ui .ant-zoom-big-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-zoom-big-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-zoom-big-enter.ant-zoom-big-enter-active,
  .ys-webrtc-sdk-ui .ant-zoom-big-appear.ant-zoom-big-appear-active {
    -webkit-animation-name: antZoomBigIn;
            animation-name: antZoomBigIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .ys-webrtc-sdk-ui .ant-zoom-big-leave.ant-zoom-big-leave-active {
    -webkit-animation-name: antZoomBigOut;
            animation-name: antZoomBigOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-zoom-big-enter,
  .ys-webrtc-sdk-ui .ant-zoom-big-appear {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .ys-webrtc-sdk-ui .ant-zoom-big-enter-prepare,
  .ys-webrtc-sdk-ui .ant-zoom-big-appear-prepare {
    -webkit-transform: none;
            transform: none;
  }
  .ys-webrtc-sdk-ui .ant-zoom-big-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
            animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .ys-webrtc-sdk-ui .ant-zoom-big-fast-enter,
  .ys-webrtc-sdk-ui .ant-zoom-big-fast-appear {
    -webkit-animation-duration: 0.1s;
            animation-duration: 0.1s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-zoom-big-fast-leave {
    -webkit-animation-duration: 0.1s;
            animation-duration: 0.1s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-zoom-big-fast-enter.ant-zoom-big-fast-enter-active,
  .ys-webrtc-sdk-ui .ant-zoom-big-fast-appear.ant-zoom-big-fast-appear-active {
    -webkit-animation-name: antZoomBigIn;
            animation-name: antZoomBigIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .ys-webrtc-sdk-ui .ant-zoom-big-fast-leave.ant-zoom-big-fast-leave-active {
    -webkit-animation-name: antZoomBigOut;
            animation-name: antZoomBigOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-zoom-big-fast-enter,
  .ys-webrtc-sdk-ui .ant-zoom-big-fast-appear {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .ys-webrtc-sdk-ui .ant-zoom-big-fast-enter-prepare,
  .ys-webrtc-sdk-ui .ant-zoom-big-fast-appear-prepare {
    -webkit-transform: none;
            transform: none;
  }
  .ys-webrtc-sdk-ui .ant-zoom-big-fast-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
            animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .ys-webrtc-sdk-ui .ant-zoom-up-enter,
  .ys-webrtc-sdk-ui .ant-zoom-up-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-zoom-up-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-zoom-up-enter.ant-zoom-up-enter-active,
  .ys-webrtc-sdk-ui .ant-zoom-up-appear.ant-zoom-up-appear-active {
    -webkit-animation-name: antZoomUpIn;
            animation-name: antZoomUpIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .ys-webrtc-sdk-ui .ant-zoom-up-leave.ant-zoom-up-leave-active {
    -webkit-animation-name: antZoomUpOut;
            animation-name: antZoomUpOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-zoom-up-enter,
  .ys-webrtc-sdk-ui .ant-zoom-up-appear {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .ys-webrtc-sdk-ui .ant-zoom-up-enter-prepare,
  .ys-webrtc-sdk-ui .ant-zoom-up-appear-prepare {
    -webkit-transform: none;
            transform: none;
  }
  .ys-webrtc-sdk-ui .ant-zoom-up-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
            animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .ys-webrtc-sdk-ui .ant-zoom-down-enter,
  .ys-webrtc-sdk-ui .ant-zoom-down-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-zoom-down-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-zoom-down-enter.ant-zoom-down-enter-active,
  .ys-webrtc-sdk-ui .ant-zoom-down-appear.ant-zoom-down-appear-active {
    -webkit-animation-name: antZoomDownIn;
            animation-name: antZoomDownIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .ys-webrtc-sdk-ui .ant-zoom-down-leave.ant-zoom-down-leave-active {
    -webkit-animation-name: antZoomDownOut;
            animation-name: antZoomDownOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-zoom-down-enter,
  .ys-webrtc-sdk-ui .ant-zoom-down-appear {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .ys-webrtc-sdk-ui .ant-zoom-down-enter-prepare,
  .ys-webrtc-sdk-ui .ant-zoom-down-appear-prepare {
    -webkit-transform: none;
            transform: none;
  }
  .ys-webrtc-sdk-ui .ant-zoom-down-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
            animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .ys-webrtc-sdk-ui .ant-zoom-left-enter,
  .ys-webrtc-sdk-ui .ant-zoom-left-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-zoom-left-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-zoom-left-enter.ant-zoom-left-enter-active,
  .ys-webrtc-sdk-ui .ant-zoom-left-appear.ant-zoom-left-appear-active {
    -webkit-animation-name: antZoomLeftIn;
            animation-name: antZoomLeftIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .ys-webrtc-sdk-ui .ant-zoom-left-leave.ant-zoom-left-leave-active {
    -webkit-animation-name: antZoomLeftOut;
            animation-name: antZoomLeftOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-zoom-left-enter,
  .ys-webrtc-sdk-ui .ant-zoom-left-appear {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .ys-webrtc-sdk-ui .ant-zoom-left-enter-prepare,
  .ys-webrtc-sdk-ui .ant-zoom-left-appear-prepare {
    -webkit-transform: none;
            transform: none;
  }
  .ys-webrtc-sdk-ui .ant-zoom-left-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
            animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  .ys-webrtc-sdk-ui .ant-zoom-right-enter,
  .ys-webrtc-sdk-ui .ant-zoom-right-appear {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-zoom-right-leave {
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
  }
  .ys-webrtc-sdk-ui .ant-zoom-right-enter.ant-zoom-right-enter-active,
  .ys-webrtc-sdk-ui .ant-zoom-right-appear.ant-zoom-right-appear-active {
    -webkit-animation-name: antZoomRightIn;
            animation-name: antZoomRightIn;
    -webkit-animation-play-state: running;
            animation-play-state: running;
  }
  .ys-webrtc-sdk-ui .ant-zoom-right-leave.ant-zoom-right-leave-active {
    -webkit-animation-name: antZoomRightOut;
            animation-name: antZoomRightOut;
    -webkit-animation-play-state: running;
            animation-play-state: running;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-zoom-right-enter,
  .ys-webrtc-sdk-ui .ant-zoom-right-appear {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
    -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
            animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  }
  .ys-webrtc-sdk-ui .ant-zoom-right-enter-prepare,
  .ys-webrtc-sdk-ui .ant-zoom-right-appear-prepare {
    -webkit-transform: none;
            transform: none;
  }
  .ys-webrtc-sdk-ui .ant-zoom-right-leave {
    -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
            animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
  @-webkit-keyframes antZoomIn {
    0% {
      -webkit-transform: scale(0.2);
              transform: scale(0.2);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes antZoomIn {
    0% {
      -webkit-transform: scale(0.2);
              transform: scale(0.2);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @-webkit-keyframes antZoomOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0.2);
              transform: scale(0.2);
      opacity: 0;
    }
  }
  @keyframes antZoomOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0.2);
              transform: scale(0.2);
      opacity: 0;
    }
  }
  @-webkit-keyframes antZoomBigIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes antZoomBigIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @-webkit-keyframes antZoomBigOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      opacity: 0;
    }
  }
  @keyframes antZoomBigOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      opacity: 0;
    }
  }
  @-webkit-keyframes antZoomUpIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
    }
  }
  @keyframes antZoomUpIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
    }
  }
  @-webkit-keyframes antZoomUpOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
      opacity: 0;
    }
  }
  @keyframes antZoomUpOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 50% 0%;
              transform-origin: 50% 0%;
      opacity: 0;
    }
  }
  @-webkit-keyframes antZoomLeftIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
    }
  }
  @keyframes antZoomLeftIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
    }
  }
  @-webkit-keyframes antZoomLeftOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
      opacity: 0;
    }
  }
  @keyframes antZoomLeftOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 0% 50%;
              transform-origin: 0% 50%;
      opacity: 0;
    }
  }
  @-webkit-keyframes antZoomRightIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
    }
  }
  @keyframes antZoomRightIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
    }
  }
  @-webkit-keyframes antZoomRightOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
      opacity: 0;
    }
  }
  @keyframes antZoomRightOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 100% 50%;
              transform-origin: 100% 50%;
      opacity: 0;
    }
  }
  @-webkit-keyframes antZoomDownIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
    }
  }
  @keyframes antZoomDownIn {
    0% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
    }
  }
  @-webkit-keyframes antZoomDownOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      opacity: 0;
    }
  }
  @keyframes antZoomDownOut {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
    }
    100% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
      opacity: 0;
    }
  }
  .ys-webrtc-sdk-ui .ant-motion-collapse-legacy {
    overflow: hidden;
  }
  .ys-webrtc-sdk-ui .ant-motion-collapse-legacy-active {
    transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  }
  .ys-webrtc-sdk-ui .ant-motion-collapse {
    overflow: hidden;
    transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
  }
  .ys-webrtc-sdk-ui .scroll-bar-style {
    scrollbar-width: thin;
    scrollbar-color: #e6e9ec #fff;
    /* 定义滚动条轨道内阴影 */
    /* 定义滑块内阴影 */
    /* 滚动条 */
  }
  .ys-webrtc-sdk-ui .scroll-bar-style::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .scroll-bar-style::-webkit-scrollbar-thumb {
    background-color: #e6e9ec;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .scroll-bar-style::-webkit-scrollbar {
    width: 6px;
    padding-right: 50%;
    background-color: #fff;
    border-radius: 3px;
  }
  .ys-webrtc-sdk-ui .primary-feedback {
    cursor: pointer;
  }
  .ys-webrtc-sdk-ui .primary-feedback:hover {
    color: #268ded;
  }
  .ys-webrtc-sdk-ui .primary-feedback:active {
    color: #0054ba;
  }
  .ys-webrtc-sdk-ui .ysd-icon {
    font-size: 16px;
    transition: all 0.3s;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-disabled {
    color: #b1b9c3 !important;
    cursor: not-allowed !important;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-disabled:hover > svg,
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-disabled:active > svg {
    color: #b1b9c3 !important;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-light {
    color: #c8cfd5;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-extra-light {
    color: #99a5b2;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-success {
    color: #21bf73;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-success:hover {
    color: #43cc85;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-success:active {
    color: #12995c;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-primary {
    color: #006ee1;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-primary:hover {
    color: #268ded;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-primary:active {
    color: #0054ba;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-error {
    color: #e6492d;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-error > svg {
    color: #e6492d;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-error:hover {
    color: #f27457;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-error:active {
    color: #bf301d;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-warning {
    color: #ffb400;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-warning:hover {
    color: #ffc629;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-warning:active {
    color: #d99100;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-light-feedback {
    color: #c8cfd5;
    cursor: pointer;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-light-feedback:hover {
    color: #268ded;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-light-feedback:active {
    color: #0054ba;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-extra-light-feedback {
    color: #99a5b2;
    cursor: pointer;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-extra-light-feedback:hover {
    color: #b1b9c3;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-extra-light-feedback:active {
    color: #778090;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-extra-light-feedback:hover {
    color: #268ded;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-extra-light-feedback:active {
    color: #0054ba;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-default-feedback {
    cursor: pointer;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-default-feedback:hover {
    color: #268ded;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-default-feedback:active {
    color: #0054ba;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-primary-feedback {
    color: #006ee1;
    cursor: pointer;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-primary-feedback:hover {
    color: #268ded;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-primary-feedback:active {
    color: #0054ba;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-primary-feedback:hover {
    color: #268ded;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-primary-feedback:active {
    color: #0054ba;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-error-feedback {
    color: #e6492d;
    cursor: pointer;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-error-feedback:hover {
    color: #f27457;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-error-feedback:hover > svg {
    color: #f27457;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-error-feedback:active {
    color: #bf301d;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-error-feedback:active > svg {
    color: #bf301d;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-warning-feedback {
    color: #ffb400;
    cursor: pointer;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-warning-feedback:hover {
    color: #ffc629;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-warning-feedback:active {
    color: #d99100;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-action {
    color: #778090;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-action:hover {
    color: #268ded;
  }
  .ys-webrtc-sdk-ui .ysd-icon.ysd-icon-action:active {
    color: #006ee1;
  }
  .ys-webrtc-sdk-ui .ysd-icon .anticon-spin {
    -webkit-animation: none;
            animation: none;
  }
  .ys-webrtc-sdk-ui .ysd-icon-spin {
    -webkit-animation: loadingCircle 1s infinite linear;
            animation: loadingCircle 1s infinite linear;
  }
  
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  .ys-webrtc-sdk-ui .scroll-bar-style {
    scrollbar-width: thin;
    scrollbar-color: #e6e9ec #fff;
    /* 定义滚动条轨道内阴影 */
    /* 定义滑块内阴影 */
    /* 滚动条 */
  }
  .ys-webrtc-sdk-ui .scroll-bar-style::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .scroll-bar-style::-webkit-scrollbar-thumb {
    background-color: #e6e9ec;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .scroll-bar-style::-webkit-scrollbar {
    width: 6px;
    padding-right: 50%;
    background-color: #fff;
    border-radius: 3px;
  }
  .ys-webrtc-sdk-ui .ant-message {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #23282f;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    font-size: 14px;
    line-height: 1.57142857;
    position: fixed;
    top: 8px;
    left: 0;
    z-index: 1010;
    width: 100%;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-message-notice {
    padding: 8px;
    text-align: center;
  }
  .ys-webrtc-sdk-ui .ant-message-notice-content {
    display: inline-block;
    padding: 12px 16px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.02), 0 2px 6px 6px rgba(0, 0, 0, 0.02), 0 2px 6px 0 rgba(0, 0, 0, 0.06);
    pointer-events: all;
  }
  .ys-webrtc-sdk-ui .ant-message-success .anticon {
    color: #21bf73;
  }
  .ys-webrtc-sdk-ui .ant-message-error .anticon {
    color: #e6492d;
  }
  .ys-webrtc-sdk-ui .ant-message-warning .anticon {
    color: #ffb400;
  }
  .ys-webrtc-sdk-ui .ant-message-info .anticon,
  .ys-webrtc-sdk-ui .ant-message-loading .anticon {
    color: #006ee1;
  }
  .ys-webrtc-sdk-ui .ant-message .anticon {
    position: relative;
    top: 1px;
    margin-right: 8px;
    font-size: 16px;
  }
  .ys-webrtc-sdk-ui .ant-message-notice.move-up-leave.move-up-leave-active {
    -webkit-animation-name: MessageMoveOut;
            animation-name: MessageMoveOut;
    -webkit-animation-duration: 0.3s;
            animation-duration: 0.3s;
  }
  @-webkit-keyframes MessageMoveOut {
    0% {
      max-height: 150px;
      padding: 8px;
      opacity: 1;
    }
    100% {
      max-height: 0;
      padding: 0;
      opacity: 0;
    }
  }
  @keyframes MessageMoveOut {
    0% {
      max-height: 150px;
      padding: 8px;
      opacity: 1;
    }
    100% {
      max-height: 0;
      padding: 0;
      opacity: 0;
    }
  }
  .ys-webrtc-sdk-ui .ant-message-rtl {
    direction: rtl;
  }
  .ys-webrtc-sdk-ui .ant-message-rtl span {
    direction: rtl;
  }
  .ys-webrtc-sdk-ui .ant-message-rtl .anticon {
    margin-right: 0;
    margin-left: 8px;
  }
  .ys-webrtc-sdk-ui .ysd-message .ant-message-notice-content .anticon {
    top: 0;
    font-size: 20px;
    vertical-align: top;
  }
  .ys-webrtc-sdk-ui .ysd-message .ant-message-notice-content .ant-message-custom-content {
    line-height: 20px;
  }
  .ys-webrtc-sdk-ui .ysd-message .ant-message-notice-content .ysd-message-icon-close {
    top: 2px;
    margin-right: 0;
    margin-left: 12px;
    font-size: 16px;
  }
  .ys-webrtc-sdk-ui .ysd-message .ant-message-notice-content .ysd-message-icon-close.anticon {
    color: #353d45;
    cursor: pointer;
  }
  .ys-webrtc-sdk-ui .ysd-message .ant-message-notice-content .ysd-message-icon-close.anticon:hover {
    color: #268ded;
  }
  .ys-webrtc-sdk-ui .ysd-message .ant-message-notice-content .ysd-message-icon-close.anticon:active {
    color: #0054ba;
  }
  .ys-webrtc-sdk-ui .ysd-message .ant-message-info .anticon {
    color: #006ee1;
  }
  .ys-webrtc-sdk-ui .ysd-message .ant-message-success .anticon {
    color: #21bf73;
  }
  .ys-webrtc-sdk-ui .ysd-message .ant-message-error .anticon {
    color: #e6492d;
  }
  .ys-webrtc-sdk-ui .ysd-message .ant-message-warning .anticon {
    color: #ffb400;
  }
  .ys-webrtc-sdk-ui .ysd-message .ant-message-notice-content {
    border-radius: 12px;
    box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.08), 0 16px 24px 2px rgba(0, 0, 0, 0.04), 0 6px 30px 5px rgba(0, 0, 0, 0.05);
  }
  .ys-webrtc-sdk-ui .ysd-message .ant-message-notice-content .ant-message-custom-content {
    line-height: 24px;
  }
  .ys-webrtc-sdk-ui .ysd-message .ant-message-notice-content .anticon {
    font-size: 24px;
  }
  .ys-webrtc-sdk-ui .ysd-message-icon-close {
    color: #778090 !important;
    font-size: 20px !important;
  }
  .ys-webrtc-sdk-ui div.ant-message {
    top: 40px;
  }
  
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  .ys-webrtc-sdk-ui .scroll-bar-style {
    scrollbar-width: thin;
    scrollbar-color: #e6e9ec #fff;
    /* 定义滚动条轨道内阴影 */
    /* 定义滑块内阴影 */
    /* 滚动条 */
  }
  .ys-webrtc-sdk-ui .scroll-bar-style::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .scroll-bar-style::-webkit-scrollbar-thumb {
    background-color: #e6e9ec;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .scroll-bar-style::-webkit-scrollbar {
    width: 6px;
    padding-right: 50%;
    background-color: #fff;
    border-radius: 3px;
  }
  .ys-webrtc-sdk-ui .ant-popover {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #23282f;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    font-size: 14px;
    line-height: 1.57142857;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1030;
    font-weight: normal;
    white-space: normal;
    text-align: left;
    cursor: auto;
    -webkit-user-select: text;
            user-select: text;
  }
  .ys-webrtc-sdk-ui .ant-popover::after {
    position: absolute;
    background: rgba(255, 255, 255, 0.01);
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-popover-hidden {
    display: none;
  }
  .ys-webrtc-sdk-ui .ant-popover-placement-top,
  .ys-webrtc-sdk-ui .ant-popover-placement-topLeft,
  .ys-webrtc-sdk-ui .ant-popover-placement-topRight {
    padding-bottom: 9px;
  }
  .ys-webrtc-sdk-ui .ant-popover-placement-right,
  .ys-webrtc-sdk-ui .ant-popover-placement-rightTop,
  .ys-webrtc-sdk-ui .ant-popover-placement-rightBottom {
    padding-left: 9px;
  }
  .ys-webrtc-sdk-ui .ant-popover-placement-bottom,
  .ys-webrtc-sdk-ui .ant-popover-placement-bottomLeft,
  .ys-webrtc-sdk-ui .ant-popover-placement-bottomRight {
    padding-top: 9px;
  }
  .ys-webrtc-sdk-ui .ant-popover-placement-left,
  .ys-webrtc-sdk-ui .ant-popover-placement-leftTop,
  .ys-webrtc-sdk-ui .ant-popover-placement-leftBottom {
    padding-right: 9px;
  }
  .ys-webrtc-sdk-ui .ant-popover-inner {
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 8px;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.02), 0 2px 6px 6px rgba(0, 0, 0, 0.02), 0 2px 6px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
  }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ys-webrtc-sdk-ui .ant-popover-inner {
      box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.02), 0 2px 6px 6px rgba(0, 0, 0, 0.02), 0 2px 6px 0 rgba(0, 0, 0, 0.06);
    }
  }
  .ys-webrtc-sdk-ui .ant-popover-title {
    min-width: 160px;
    min-height: 32px;
    margin: 0;
    padding: 5px 16px 4px;
    color: #23282f;
    font-weight: 500;
    border-bottom: 1px solid #e6e9ec;
  }
  .ys-webrtc-sdk-ui .ant-popover-inner-content {
    padding: 12px 16px;
    color: #23282f;
  }
  .ys-webrtc-sdk-ui .ant-popover-message {
    position: relative;
    padding: 4px 0 12px;
    color: #23282f;
    font-size: 14px;
  }
  .ys-webrtc-sdk-ui .ant-popover-message > .anticon {
    position: absolute;
    top: 8.0005px;
    color: #ffb400;
    font-size: 14px;
  }
  .ys-webrtc-sdk-ui .ant-popover-message-title {
    padding-left: 22px;
  }
  .ys-webrtc-sdk-ui .ant-popover-buttons {
    margin-bottom: 4px;
    text-align: right;
  }
  .ys-webrtc-sdk-ui .ant-popover-buttons button {
    margin-left: 8px;
  }
  .ys-webrtc-sdk-ui .ant-popover-arrow {
    position: absolute;
    display: block;
    width: 20.14213562px;
    height: 20.14213562px;
    overflow: hidden;
    background: transparent;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-popover-arrow-content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 10px;
    height: 10px;
    margin: auto;
    background: #fff;
    content: '';
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
  .ys-webrtc-sdk-ui .ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
  .ys-webrtc-sdk-ui .ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
    bottom: -11.14213562px;
  }
  .ys-webrtc-sdk-ui .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow > .ant-popover-arrow-content,
  .ys-webrtc-sdk-ui .ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow > .ant-popover-arrow-content,
  .ys-webrtc-sdk-ui .ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow > .ant-popover-arrow-content {
    border-radius: 0 0 3px 0;
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
    -webkit-transform: translateY(-10.07106781px) rotate(45deg);
            transform: translateY(-10.07106781px) rotate(45deg);
  }
  .ys-webrtc-sdk-ui .ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .ys-webrtc-sdk-ui .ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow {
    left: 13px;
  }
  .ys-webrtc-sdk-ui .ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
    right: 13px;
  }
  .ys-webrtc-sdk-ui .ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow,
  .ys-webrtc-sdk-ui .ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow,
  .ys-webrtc-sdk-ui .ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
    left: -11.14213562px;
  }
  .ys-webrtc-sdk-ui .ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow > .ant-popover-arrow-content,
  .ys-webrtc-sdk-ui .ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow > .ant-popover-arrow-content,
  .ys-webrtc-sdk-ui .ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow > .ant-popover-arrow-content {
    border-radius: 0 0 0 3px;
    box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
    -webkit-transform: translateX(10.07106781px) rotate(45deg);
            transform: translateX(10.07106781px) rotate(45deg);
  }
  .ys-webrtc-sdk-ui .ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow {
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .ys-webrtc-sdk-ui .ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow {
    top: 5px;
  }
  .ys-webrtc-sdk-ui .ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
    bottom: 5px;
  }
  .ys-webrtc-sdk-ui .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
  .ys-webrtc-sdk-ui .ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
  .ys-webrtc-sdk-ui .ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
    top: -11.14213562px;
  }
  .ys-webrtc-sdk-ui .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow > .ant-popover-arrow-content,
  .ys-webrtc-sdk-ui .ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow > .ant-popover-arrow-content,
  .ys-webrtc-sdk-ui .ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow > .ant-popover-arrow-content {
    border-radius: 3px 0 0 0;
    box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
    -webkit-transform: translateY(10.07106781px) rotate(45deg);
            transform: translateY(10.07106781px) rotate(45deg);
  }
  .ys-webrtc-sdk-ui .ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .ys-webrtc-sdk-ui .ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow {
    left: 13px;
  }
  .ys-webrtc-sdk-ui .ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
    right: 13px;
  }
  .ys-webrtc-sdk-ui .ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow,
  .ys-webrtc-sdk-ui .ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow,
  .ys-webrtc-sdk-ui .ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
    right: -11.14213562px;
  }
  .ys-webrtc-sdk-ui .ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow > .ant-popover-arrow-content,
  .ys-webrtc-sdk-ui .ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow > .ant-popover-arrow-content,
  .ys-webrtc-sdk-ui .ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow > .ant-popover-arrow-content {
    border-radius: 0 3px 0 0;
    box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
    -webkit-transform: translateX(-10.07106781px) rotate(45deg);
            transform: translateX(-10.07106781px) rotate(45deg);
  }
  .ys-webrtc-sdk-ui .ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow {
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .ys-webrtc-sdk-ui .ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow {
    top: 5px;
  }
  .ys-webrtc-sdk-ui .ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
    bottom: 5px;
  }
  .ys-webrtc-sdk-ui .ant-popover-rtl {
    direction: rtl;
    text-align: right;
  }
  .ys-webrtc-sdk-ui .ant-popover-rtl .ant-popover-message-title {
    padding-right: 22px;
    padding-left: 16px;
  }
  .ys-webrtc-sdk-ui .ant-popover-rtl .ant-popover-buttons {
    text-align: left;
  }
  .ys-webrtc-sdk-ui .ant-popover-rtl .ant-popover-buttons button {
    margin-right: 8px;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ysd-popover .ant-popover-title {
    padding: 16px 24px 0;
    color: #23282f;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    border: none;
  }
  .ys-webrtc-sdk-ui .ysd-popover .ant-popover-title + .ant-popover-inner-content {
    padding: 8px 24px 16px;
  }
  .ys-webrtc-sdk-ui .ysd-popover .ant-popover-inner {
    min-width: 160px;
    max-width: 480px;
    max-height: 320px;
    overflow-y: auto;
  }
  .ys-webrtc-sdk-ui .ysd-popover .ant-popover-inner-content {
    padding: 8px 12px;
    color: #23282f;
  }
  .ys-webrtc-sdk-ui .ysd-popover-small .ant-popover-title {
    font-size: 14px;
  }
  .ys-webrtc-sdk-ui .ysd-popover-small .ant-popover-inner {
    min-width: 120px;
    max-width: 400px;
    max-height: 240px;
  }
  .ys-webrtc-sdk-ui .ysd-popover-small .ant-popover-inner-content {
    font-size: 12px;
  }
  
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  .ys-webrtc-sdk-ui .scroll-bar-style {
    scrollbar-width: thin;
    scrollbar-color: #e6e9ec #fff;
    /* 定义滚动条轨道内阴影 */
    /* 定义滑块内阴影 */
    /* 滚动条 */
  }
  .ys-webrtc-sdk-ui .scroll-bar-style::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .scroll-bar-style::-webkit-scrollbar-thumb {
    background-color: #e6e9ec;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .scroll-bar-style::-webkit-scrollbar {
    width: 6px;
    padding-right: 50%;
    background-color: #fff;
    border-radius: 3px;
  }
  .ys-webrtc-sdk-ui .ant-avatar {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #23282f;
    font-variant: tabular-nums;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    font-size: 14px;
    line-height: 1.57142857;
    position: relative;
    display: inline-block;
    overflow: hidden;
    color: #fff;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
    background: #ccc;
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 50%;
  }
  .ys-webrtc-sdk-ui .ant-avatar-image {
    background: transparent;
  }
  .ys-webrtc-sdk-ui .ant-avatar .ant-image-img {
    display: block;
  }
  .ys-webrtc-sdk-ui .ant-avatar-string {
    position: absolute;
    left: 50%;
    -webkit-transform-origin: 0 center;
            transform-origin: 0 center;
  }
  .ys-webrtc-sdk-ui .ant-avatar.ant-avatar-icon {
    font-size: 18px;
  }
  .ys-webrtc-sdk-ui .ant-avatar.ant-avatar-icon > .anticon {
    margin: 0;
  }
  .ys-webrtc-sdk-ui .ant-avatar-lg {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
  }
  .ys-webrtc-sdk-ui .ant-avatar-lg-string {
    position: absolute;
    left: 50%;
    -webkit-transform-origin: 0 center;
            transform-origin: 0 center;
  }
  .ys-webrtc-sdk-ui .ant-avatar-lg.ant-avatar-icon {
    font-size: 24px;
  }
  .ys-webrtc-sdk-ui .ant-avatar-lg.ant-avatar-icon > .anticon {
    margin: 0;
  }
  .ys-webrtc-sdk-ui .ant-avatar-sm {
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 50%;
  }
  .ys-webrtc-sdk-ui .ant-avatar-sm-string {
    position: absolute;
    left: 50%;
    -webkit-transform-origin: 0 center;
            transform-origin: 0 center;
  }
  .ys-webrtc-sdk-ui .ant-avatar-sm.ant-avatar-icon {
    font-size: 14px;
  }
  .ys-webrtc-sdk-ui .ant-avatar-sm.ant-avatar-icon > .anticon {
    margin: 0;
  }
  .ys-webrtc-sdk-ui .ant-avatar-square {
    border-radius: 12px;
  }
  .ys-webrtc-sdk-ui .ant-avatar > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .ys-webrtc-sdk-ui .ant-avatar-group {
    display: inline-flex;
  }
  .ys-webrtc-sdk-ui .ant-avatar-group .ant-avatar {
    border: 1px solid #fff;
  }
  .ys-webrtc-sdk-ui .ant-avatar-group .ant-avatar:not(:first-child) {
    margin-left: -8px;
  }
  .ys-webrtc-sdk-ui .ant-avatar-group-popover .ant-avatar + .ant-avatar {
    margin-left: 3px;
  }
  .ys-webrtc-sdk-ui .ant-avatar-group-rtl .ant-avatar:not(:first-child) {
    margin-right: -8px;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-avatar-group-popover.ant-popover-rtl .ant-avatar + .ant-avatar {
    margin-right: 3px;
    margin-left: 0;
  }
  
  .ys-webrtc-sdk-ui .incoming-pop {
    position: absolute;
    top: 82px;
    right: 85%!important;
    width: 0;
    z-index: 2;
    display: inline-block;
    flex-direction: column;
    align-items: flex-end;
  }
  .ys-webrtc-sdk-ui .incoming-pop.show .incoming-item {
    width: 304px;
    border-radius: 4px;
    margin-bottom: 12px;
    background: #333;
    color: #fff;
    -webkit-animation: 0.5s incoming-normal-show ease-in-out;
            animation: 0.5s incoming-normal-show ease-in-out;
  }
  .ys-webrtc-sdk-ui .incoming-pop.show .incoming-item .incoming-head {
    text-align: right;
    height: 30px;
    line-height: 30px;
  }
  .ys-webrtc-sdk-ui .incoming-pop.show .incoming-item .incoming-head span {
    display: inline-block;
    width: 200px;
    word-break: keep-all;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 8px;
    font-size: 12px;
    font-weight: 400;
    color: #A5A5A5;
    line-height: 28px;
  }
  .ys-webrtc-sdk-ui .incoming-pop.show .incoming-item .incoming-detail {
    line-height: 0;
    position: relative;
  }
  .ys-webrtc-sdk-ui .incoming-pop.show .incoming-item .incoming-detail .avatar {
    background-color: #fff;
    width: 44px;
    height: 44px;
    margin: 0 12px 0 28px;
    border-radius: 30px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .ys-webrtc-sdk-ui .incoming-pop.show .incoming-item .incoming-detail .avatar .ant-avatar {
    width: 44px;
    height: 44px;
    border-radius: 30px;
    cursor: pointer;
  }
  .ys-webrtc-sdk-ui .incoming-pop.show .incoming-item .incoming-detail .info .name {
    font-weight: 900;
  }
  .ys-webrtc-sdk-ui .incoming-pop.show .incoming-item .incoming-detail .info .phone {
    font-weight: 400;
  }
  .ys-webrtc-sdk-ui .incoming-pop.show .incoming-item .incoming-detail .info .phone,
  .ys-webrtc-sdk-ui .incoming-pop.show .incoming-item .incoming-detail .info .name {
    width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .ys-webrtc-sdk-ui .incoming-pop.show .incoming-item .incoming-detail .info.avatar,
  .ys-webrtc-sdk-ui .incoming-pop.show .incoming-item .incoming-detail .info.info {
    display: inline-block;
    vertical-align: middle;
    line-height: 22px;
    margin-left: 85px;
  }
  .ys-webrtc-sdk-ui .incoming-pop.show .incoming-item .incoming-btn .incoming-btn-box {
    width: 32px;
    height: 32px;
    line-height: 37px;
  }
  .ys-webrtc-sdk-ui .incoming-pop.show .incoming-item .incoming-btn .incoming-btn-box .rtc-icon {
    font-size: 20px;
    color: #fff;
  }
  .ys-webrtc-sdk-ui .incoming-pop.show .incoming-item .incoming-btn .incoming-btn-box.icon-hangup {
    background: #E6492D;
  }
  .ys-webrtc-sdk-ui .incoming-pop.show .incoming-item .incoming-btn .incoming-btn-box.icon-hangup:hover {
    background: #c82a23;
  }
  .ys-webrtc-sdk-ui .incoming-pop.show .incoming-item .incoming-btn .incoming-btn-box.icon-audio,
  .ys-webrtc-sdk-ui .incoming-pop.show .incoming-item .incoming-btn .incoming-btn-box.icon-video {
    background: #1EBA62;
  }
  .ys-webrtc-sdk-ui .incoming-pop.show .incoming-item .incoming-btn .incoming-btn-box.icon-audio:hover,
  .ys-webrtc-sdk-ui .incoming-pop.show .incoming-item .incoming-btn .incoming-btn-box.icon-video:hover {
    background: #1c822d;
  }
  @-webkit-keyframes incoming-normal-show {
    0% {
      width: 0;
      -webkit-transform: translateX(304px);
              transform: translateX(304px);
    }
    100% {
      width: 304px;
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  @keyframes incoming-normal-show {
    0% {
      width: 0;
      -webkit-transform: translateX(304px);
              transform: translateX(304px);
    }
    100% {
      width: 304px;
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  .ys-webrtc-sdk-ui .incoming-pop.hide .incoming-item {
    width: 0;
    -webkit-animation: 0.2s incoming-normal-hide linear;
            animation: 0.2s incoming-normal-hide linear;
  }
  .ys-webrtc-sdk-ui .incoming-pop.hide .incoming-btn .incoming-btn-box {
    width: 0;
    height: 0;
    line-height: 0;
  }
  @-webkit-keyframes incoming-normal-hide {
    0% {
      width: 304px;
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      width: 0;
      -webkit-transform: translateX(304px);
              transform: translateX(304px);
    }
  }
  @keyframes incoming-normal-hide {
    0% {
      width: 304px;
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      width: 0;
      -webkit-transform: translateX(304px);
              transform: translateX(304px);
    }
  }
  .ys-webrtc-sdk-ui .incoming-pop .incoming-btn {
    text-align: right;
    line-height: 0;
  }
  .ys-webrtc-sdk-ui .incoming-pop .incoming-btn .incoming-btn-box {
    display: inline-block;
    width: 0;
    height: 0;
    line-height: 37px;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
    text-align: center;
    margin: 15px 10px 15px 6px;
  }
  .ys-webrtc-sdk-ui .normal-width {
    width: 304px;
  }
  
  .ys-webrtc-sdk-ui .rtc-wra {
    background: #333;
    color: #fff;
    z-index: 1;
    box-shadow: 5px 5px 15px #666;
    border-radius: 4px;
    left: -30%!important;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra {
    border-radius: 4px;
    padding-bottom: 88px;
    position: relative;
    width: 100%;
    min-height: 479px;
    height: 100%;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-header {
    display: flex;
    height: 34px;
    line-height: 31px;
    font-size: 14px;
    background: #1f1f1f;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-header .hdr-lt {
    flex: 1;
    overflow: hidden;
    cursor: move;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-header .hdr-lt .hdr-lt-box {
    display: flex;
    width: 100%;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-header .hdr-lt .hdr-lt-box .hdr-name {
    position: relative;
    padding: 0 16px;
    width: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-header .hdr-lt .hdr-lt-box .hdr-name::after {
    content: '';
    position: absolute;
    top: 8px;
    right: 0;
    width: 1px;
    height: 16px;
    background: rgba(255, 255, 255, 0.3);
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-header .hdr-lt .hdr-lt-box .hdr-phone {
    max-width: 160;
    padding: 0 7px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-header .hdr-lt .hdr-lt-box .empty {
    flex: 1;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-header .hdr-lt .hdr-lt-box .record-wra {
    width: 36px;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-header .hdr-lt .hdr-lt-box .record-wra .record-area-box {
    margin-top: 3px;
    position: relative;
    width: 24px;
    height: 24px;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-header .hdr-lt .hdr-lt-box .record-wra .record-area-box .dot {
    position: absolute;
    top: 7px;
    left: 7px;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #E6492D;
    filter: alpha(opacity=100);
    -webkit-animation: hide 2s ease-out both 1s infinite;
    animation: hide 2s ease-out both 1s infinite;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-header .hdr-lt .hdr-lt-box .record-wra .record-area-box .pulse {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    border: 2px solid #E6492D;
    border-radius: 50%;
    background: #a2a9b4;
    box-shadow: 0 0 2px #E6492D, 0 0 8px #E6492D inset;
    opacity: 0.6;
    filter: alpha(opacity=0);
    -webkit-animation: warn 2s ease-out both;
    animation: warn 2s ease-out both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
  @-webkit-keyframes hide {
    0% {
      opacity: 1;
    }
    10% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes hide {
    0% {
      opacity: 1;
    }
    10% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @-webkit-keyframes warn {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 0;
    }
  }
  @keyframes warn {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 0;
    }
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-header .hdr-lt .hdr-lt-box .record-wra .record-area-box .delay-01 {
    -webkit-animation-delay: 0.8s;
            animation-delay: 0.8s;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-header .hdr-lt .hdr-lt-box .record-wra .record-area-box .delay-02 {
    -webkit-animation-delay: 1.2s;
            animation-delay: 1.2s;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-header .hdr-lt .hdr-lt-box .record-wra .icon-pause {
    margin-top: 5px;
    font-size: 23px;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-header .hdr-rt {
    width: 160;
    padding-right: 6px;
    text-align: right;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-header .hdr-rt .icon-box {
    margin-top: 5px;
    margin-left: 5px;
    display: inline-block;
    width: 27px;
    height: 23px;
    line-height: 23px;
    background: #4C4C4C;
    border-radius: 2px;
    text-align: center;
    cursor: pointer;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-header .hdr-rt .icon-box .rtc-icon {
    font-size: 16px;
    color: #C0BEBE;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-header .hdr-rt .icon-box .rtc-icon:hover {
    color: #fff;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content {
    position: relative;
    width: 100%;
    height: calc(100% - 31px);
    min-height: 360px;
    display: flex;
    align-items: center;
    overflow: hidden;
    background-color: #333;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .media-wra {
    flex: 1;
    overflow: hidden;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .media-wra .avatar-wra {
    margin: 0 auto;
    width: 190px;
    height: 190px;
    border-radius: 50%;
    border: 23px solid #363636;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .media-wra .avatar-wra .img-wra {
    width: 144px;
    height: 144px;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;
    border: 14px solid #404040;
    background: #fff;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .media-wra .avatar-wra > img {
    display: block;
    width: 144px;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .media-wra .name {
    margin: 17px auto 8px;
    width: 80%;
    line-height: 22px;
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .media-wra .status {
    line-height: 24px;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .media-wra .status.hold {
    color: #E6492D;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .media-wra .full-height {
    height: 100%;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .media-wra .small-video-offset .small-video {
    right: 33%;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .media-wra .small-video-offset .change-video-size-contanier {
    right: 34%;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .media-wra .small-video {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 20%;
    border: 1px solid #5D5D5D;
    z-index: 1;
    cursor: pointer;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .media-wra .small-video .small-video-avatar {
    background: #fff;
    text-align: center;
    font-size: 28px;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .media-wra .small-video img {
    margin: 0 auto;
    display: block;
    width: 100%;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .media-wra .transfer-video {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .media-wra .change-video-size-contanier {
    background: rgba(0, 0, 0, 0.4);
    opacity: 1;
    width: 24px;
    height: 24px;
    z-index: 3;
    position: absolute;
    top: 21px;
    right: 21px;
    border-radius: 4px;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .media-wra .change-video-size-contanier .change-video-size {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 14px;
    z-index: 2;
    color: #C0BEBE;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .media-wra .change-video-size-contanier .change-video-size:hover {
    color: #fff;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .media-wra .current-video {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: auto;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .media-wra .video-status {
    position: absolute;
    bottom: 7px;
    left: 9px;
    right: 9px;
    line-height: 19px;
    font-size: 16px;
    font-weight: 300;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .transfer-info-wra {
    position: absolute;
    top: 16px;
    left: 23px;
    width: 44%;
    height: 40px;
    overflow: hidden;
    display: flex;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .transfer-info-wra .item-avatar-wra {
    position: relative;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 50%;
    overflow: hidden;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .transfer-info-wra .item-avatar-wra .transfer-dialog {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    line-height: 44px;
    text-align: center;
    background: rgba(11, 11, 11, 0.5);
    z-index: 2;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .transfer-info-wra .item-avatar-wra .transfer-dialog .item-transfer-icon {
    font-size: 18px;
    color: #fff;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .transfer-info-wra .item-avatar-wra .item-img-wra {
    width: 38px;
    height: 38px;
    line-height: 38px;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .transfer-info-wra .item-rt {
    width: 82%;
    text-align: left;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .transfer-info-wra .item-rt .item-info {
    line-height: 20px;
    font-weight: 600;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .transfer-info-wra .item-rt .item-info.hold {
    color: #E6492D;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .content-rt {
    width: 0;
    height: 100%;
    transition: width 0.3s;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .content-rt .new-call-wra {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 0;
    height: 100%;
    background: #1d1d1d;
    z-index: 3;
    overflow: hidden;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .content-rt .new-call-wra::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: #474747;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .content-rt .new-call-wra::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #474747;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .content-rt .new-call-wra::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: #474747;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .content-rt .new-call-wra.new-call-block {
    width: 32%;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .content-rt .new-call-wra .title {
    padding: 12px 8px 16px 17px;
    line-height: 14px;
    font-size: 12px;
    font-weight: 300;
    color: #fff;
    zoom: 1;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .content-rt .new-call-wra .title .close-icon {
    float: right;
    font-size: 12px;
    cursor: pointer;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .content-rt .new-call-wra .ipt-box {
    margin-bottom: 12px;
    padding: 0 14px;
    line-height: 25px;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .content-rt .new-call-wra .ipt-box .item-ipt {
    height: 25px;
    background: #222;
    border: 1px solid #4C4C4C;
    color: #fff;
    border-radius: 0;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .content-rt .new-call-wra .ipt-box .item-ipt:focus {
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .content-rt .new-call-wra .dtmf-keyboard-pop {
    flex-wrap: wrap;
    margin: 20% 10%;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .content-rt .new-call-wra .dtmf-keyboard-pop .dtmf-keyboard-pop-col {
    text-align: center;
    text-align: -webkit-center;
    text-align: -moz-center;
    text-align: -o-center;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .content-rt .new-call-wra .dtmf-keyboard-pop .dtmf-keyboard-pop-col .item {
    margin-bottom: 10px;
    width: 48px;
    height: 48px;
    line-height: 48px;
    font-size: 24px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    color: #9EA0A5;
    vertical-align: middle;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .content-rt .new-call-wra .dtmf-keyboard-pop .dtmf-keyboard-pop-col .item:hover {
    background: #1d1d1d;
    box-shadow: #292929 -2px -2px 5px, #1111 2px 2px 5px, #1111 2px 2px 5px, #292929 -2px -2px 5px;
    border-radius: 4px;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .content-rt .new-call-wra .dtmf-keyboard-pop .dtmf-keyboard-pop-col .item:active {
    background-size: 48px 48px;
    color: #0081cc;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .content-rt .new-call-wra .dtmf-keyboard-pop .dtmf-keyboard-pop-col .item .item-num {
    height: 32px;
    line-height: 38px;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .content-rt .new-call-wra .dtmf-keyboard-pop .dtmf-keyboard-pop-col .item .item-letter {
    width: 48px;
    height: 16px;
    font-size: 10px;
    line-height: 10px;
    font-weight: 100;
    color: #9ea0a566;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .content-rt .new-call-wra .search-box {
    margin-bottom: 12px;
    padding-right: 8px;
    width: 100%;
    line-height: 25px;
    zoom: 1;
    padding-left: 14px;
    display: flex;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .content-rt .new-call-wra .search-box .rtc-input-wra {
    display: flex;
    flex: 1;
    position: relative;
    padding-right: 10px;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .content-rt .new-call-wra .search-box .rtc-input-wra .search-ipt {
    width: 86%;
    height: 26px;
    border-radius: unset;
    background-color: #222;
    color: #999;
    flex: 1;
    padding-right: 26px;
    font-size: 12px;
    font-weight: 400;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .content-rt .new-call-wra .search-box .rtc-input-wra .search-ipt:focus,
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .content-rt .new-call-wra .search-box .rtc-input-wra .search-ipt:hover {
    border-color: #fff;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .content-rt .new-call-wra .search-box .rtc-input-wra .anticon {
    position: absolute;
    right: 16px;
    top: 4px;
    font-size: 16px;
    color: #fff;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .content-rt .new-call-wra .search-box .handle-call {
    float: right;
    cursor: pointer;
    font-size: 0;
    color: #C0BEBE;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .content-rt .new-call-wra .search-box .handle-call:hover {
    color: #fff;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .content-rt.new-call-ani .new-call-wra {
    transition: width 0.3s;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .content-rt.content-rt-block {
    width: 32%;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-content .content-rt.content-rt-block .new-call-wra .search-box .handle-call {
    font-size: 22px;
    /* stylelint-disable-next-line value-keyword-case */
    -webkit-animation: showCall 0.3s ease-out;
    /* stylelint-disable-next-line value-keyword-case */
    animation: showCall 0.3s ease-out;
  }
  @-webkit-keyframes showCall {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
      font-size: 0;
    }
    90% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
      font-size: 22px;
    }
  }
  @keyframes showCall {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
      font-size: 0;
    }
    90% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
      font-size: 22px;
    }
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    display: flex;
    padding-left: 12px;
    padding-right: 12px;
    height: 88px;
    background: #1f1f1f;
    text-align: center;
    align-items: center;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-footer .transfer-dropdown {
    top: auto !important;
    bottom: 0;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-footer .transfer-dropdown .ant-dropdown-menu {
    border-radius: 4px;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-footer .transfer-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
    padding: 4px 12px !important;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-footer .transfer-dropdown .ant-dropdown-menu .ant-dropdown-menu-item .dropdown-item {
    display: flex;
    align-items: center;
    height: 40px;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-footer .transfer-dropdown .ant-dropdown-menu .ant-dropdown-menu-item .dropdown-item .anticon {
    margin-right: 12px;
    font-size: 18px;
    color: #3D4A59;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-footer .rtc-opera-btn {
    min-width: 56px;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-footer .rtc-opera-btn.can-no-use {
    opacity: 0.3;
    pointer-events: none;
    cursor: default;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-footer .rtc-opera-btn .opera-icon-wra {
    margin: 0 auto;
    width: 32px;
    height: 32px;
    line-height: 37px;
    border-radius: 50%;
    background: #a5a5a5;
    cursor: pointer;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-footer .rtc-opera-btn .opera-icon-wra.active {
    background: #e7e8f0;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-footer .rtc-opera-btn .opera-icon-wra:hover {
    background: #e7e8f0;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-footer .rtc-opera-btn .opera-icon-wra.icon-end {
    background: #E6492D;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-footer .rtc-opera-btn .opera-icon-wra.icon-end:hover {
    background: #c82a23;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-footer .rtc-opera-btn .opera-icon-wra.icon-end .rtc-icon {
    color: #fff;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-footer .rtc-opera-btn .opera-icon-wra.transfer-icon {
    background: #1EBA62;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-footer .rtc-opera-btn .opera-icon-wra.transfer-icon .rtc-icon {
    color: #fff;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-footer .rtc-opera-btn .opera-icon-wra .ant-dropdown-menu-item {
    padding: 8px 15px !important;
    line-height: 22px !important;
    color: #3E3F42;
    font-size: 12px;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-footer .rtc-opera-btn .opera-icon-wra .video-locked-icon {
    color: #FDCA40 !important;
    margin-right: 5px;
    position: relative;
    top: -50px;
    left: 16px;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-footer .rtc-opera-btn .opera-icon-wra .video-locked-icon.icon-beta {
    font-size: 28px;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-footer .rtc-opera-btn .opera-icon-wra .video-locked-icon.icon-locked {
    font-size: 18px;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-footer .rtc-opera-btn .item-name {
    color: #a5a5a5;
    margin-top: 6px;
    line-height: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 300;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-footer .item-rt {
    flex: 1;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-footer .item-rt .opera-btn-box {
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-icon {
    font-size: 20px;
    color: #333;
    vertical-align: text-bottom;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-wra .rtc-icon.active {
    color: #0081cc;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-tab-wra {
    position: absolute;
    top: 36px;
    left: -156px;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-tab-wra .item-tab {
    position: relative;
    margin-bottom: 17px;
    display: flex;
    width: 170px;
    height: 66px;
    border-top-left-radius: 33px;
    border-bottom-left-radius: 33px;
    background: #1f1f1f;
    align-items: center;
    cursor: pointer;
    left: -14px;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-tab-wra .item-tab:last-child {
    margin-bottom: 0;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-tab-wra .item-tab.unChangeTab {
    cursor: no-drop;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-tab-wra .item-tab.active {
    position: relative;
    background: #0081cc;
    left: -19px;
    z-index: -1;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transform: scale(1.1, 1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-tab-wra .item-tab.active .avatar-box.bg-bule {
    background: #fff;
    color: #0081cc;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-tab-wra .item-tab .avatar-box {
    position: relative;
    width: 40px;
    height: 40px;
    margin-left: 14px;
    margin-right: 10px;
    border-radius: 50%;
    background: #fff;
    overflow: hidden;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-tab-wra .item-tab .avatar-box.bg-bule {
    background: #0081cc;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-tab-wra .item-tab .avatar-box .ant-avatar {
    display: block;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-tab-wra .item-tab .avatar-box .transfer-dialog {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 40px;
    line-height: 44px;
    text-align: center;
    background: rgba(11, 11, 11, 0.5);
    z-index: 2;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-tab-wra .item-tab .avatar-box .transfer-dialog .item-transfer-icon {
    font-size: 18px;
    color: #fff;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-tab-wra .item-tab .item-rt {
    flex: 1;
    overflow: hidden;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-tab-wra .item-tab .item-rt .name {
    line-height: 22px;
    font-size: 12px;
    color: #fff;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 900;
    max-width: 102px;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-tab-wra .item-tab .item-rt .status {
    line-height: 22px;
    font-size: 12px;
    color: #fff;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-tab-wra .item-tab .item-rt .status.hold {
    color: #E6492D;
  }
  .ys-webrtc-sdk-ui .rtc-wra .session-tab-wra .item-tab .item-rt .status.ringing {
    color: #6DD400;
  }
  .ys-webrtc-sdk-ui .width-prior .session-wra .current-video {
    display: block;
    height: 100%;
    width: auto;
  }
  .ys-webrtc-sdk-ui .hidden {
    display: none !important;
  }
  
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  .ys-webrtc-sdk-ui .scroll-bar-style {
    scrollbar-width: thin;
    scrollbar-color: #e6e9ec #fff;
    /* 定义滚动条轨道内阴影 */
    /* 定义滑块内阴影 */
    /* 滚动条 */
  }
  .ys-webrtc-sdk-ui .scroll-bar-style::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .scroll-bar-style::-webkit-scrollbar-thumb {
    background-color: #e6e9ec;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .scroll-bar-style::-webkit-scrollbar {
    width: 6px;
    padding-right: 50%;
    background-color: #fff;
    border-radius: 3px;
  }
  .ys-webrtc-sdk-ui .ant-row {
    display: flex;
    flex-flow: row wrap;
  }
  .ys-webrtc-sdk-ui .ant-row::before,
  .ys-webrtc-sdk-ui .ant-row::after {
    display: flex;
  }
  .ys-webrtc-sdk-ui .ant-row-no-wrap {
    flex-wrap: nowrap;
  }
  .ys-webrtc-sdk-ui .ant-row-start {
    justify-content: flex-start;
  }
  .ys-webrtc-sdk-ui .ant-row-center {
    justify-content: center;
  }
  .ys-webrtc-sdk-ui .ant-row-end {
    justify-content: flex-end;
  }
  .ys-webrtc-sdk-ui .ant-row-space-between {
    justify-content: space-between;
  }
  .ys-webrtc-sdk-ui .ant-row-space-around {
    justify-content: space-around;
  }
  .ys-webrtc-sdk-ui .ant-row-top {
    align-items: flex-start;
  }
  .ys-webrtc-sdk-ui .ant-row-middle {
    align-items: center;
  }
  .ys-webrtc-sdk-ui .ant-row-bottom {
    align-items: flex-end;
  }
  .ys-webrtc-sdk-ui .ant-col {
    position: relative;
    max-width: 100%;
    min-height: 1px;
  }
  .ys-webrtc-sdk-ui .ant-col-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ys-webrtc-sdk-ui .ant-col-push-24 {
    left: 100%;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-24 {
    right: 100%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-24 {
    margin-left: 100%;
  }
  .ys-webrtc-sdk-ui .ant-col-order-24 {
    order: 24;
  }
  .ys-webrtc-sdk-ui .ant-col-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-push-23 {
    left: 95.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-23 {
    right: 95.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-23 {
    margin-left: 95.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-order-23 {
    order: 23;
  }
  .ys-webrtc-sdk-ui .ant-col-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-push-22 {
    left: 91.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-22 {
    right: 91.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-22 {
    margin-left: 91.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-order-22 {
    order: 22;
  }
  .ys-webrtc-sdk-ui .ant-col-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-push-21 {
    left: 87.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-21 {
    right: 87.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-21 {
    margin-left: 87.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-order-21 {
    order: 21;
  }
  .ys-webrtc-sdk-ui .ant-col-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-push-20 {
    left: 83.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-20 {
    right: 83.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-20 {
    margin-left: 83.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-order-20 {
    order: 20;
  }
  .ys-webrtc-sdk-ui .ant-col-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-push-19 {
    left: 79.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-19 {
    right: 79.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-19 {
    margin-left: 79.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-order-19 {
    order: 19;
  }
  .ys-webrtc-sdk-ui .ant-col-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ys-webrtc-sdk-ui .ant-col-push-18 {
    left: 75%;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-18 {
    right: 75%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-18 {
    margin-left: 75%;
  }
  .ys-webrtc-sdk-ui .ant-col-order-18 {
    order: 18;
  }
  .ys-webrtc-sdk-ui .ant-col-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-push-17 {
    left: 70.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-17 {
    right: 70.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-17 {
    margin-left: 70.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-order-17 {
    order: 17;
  }
  .ys-webrtc-sdk-ui .ant-col-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-push-16 {
    left: 66.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-16 {
    right: 66.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-16 {
    margin-left: 66.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-order-16 {
    order: 16;
  }
  .ys-webrtc-sdk-ui .ant-col-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-push-15 {
    left: 62.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-15 {
    right: 62.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-15 {
    margin-left: 62.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-order-15 {
    order: 15;
  }
  .ys-webrtc-sdk-ui .ant-col-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-push-14 {
    left: 58.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-14 {
    right: 58.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-14 {
    margin-left: 58.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-order-14 {
    order: 14;
  }
  .ys-webrtc-sdk-ui .ant-col-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-push-13 {
    left: 54.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-13 {
    right: 54.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-13 {
    margin-left: 54.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-order-13 {
    order: 13;
  }
  .ys-webrtc-sdk-ui .ant-col-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ys-webrtc-sdk-ui .ant-col-push-12 {
    left: 50%;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-12 {
    right: 50%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-12 {
    margin-left: 50%;
  }
  .ys-webrtc-sdk-ui .ant-col-order-12 {
    order: 12;
  }
  .ys-webrtc-sdk-ui .ant-col-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-push-11 {
    left: 45.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-11 {
    right: 45.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-11 {
    margin-left: 45.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-order-11 {
    order: 11;
  }
  .ys-webrtc-sdk-ui .ant-col-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-push-10 {
    left: 41.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-10 {
    right: 41.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-10 {
    margin-left: 41.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-order-10 {
    order: 10;
  }
  .ys-webrtc-sdk-ui .ant-col-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-push-9 {
    left: 37.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-9 {
    right: 37.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-9 {
    margin-left: 37.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-order-9 {
    order: 9;
  }
  .ys-webrtc-sdk-ui .ant-col-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-push-8 {
    left: 33.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-8 {
    right: 33.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-8 {
    margin-left: 33.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-order-8 {
    order: 8;
  }
  .ys-webrtc-sdk-ui .ant-col-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-push-7 {
    left: 29.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-7 {
    right: 29.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-7 {
    margin-left: 29.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-order-7 {
    order: 7;
  }
  .ys-webrtc-sdk-ui .ant-col-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ys-webrtc-sdk-ui .ant-col-push-6 {
    left: 25%;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-6 {
    right: 25%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-6 {
    margin-left: 25%;
  }
  .ys-webrtc-sdk-ui .ant-col-order-6 {
    order: 6;
  }
  .ys-webrtc-sdk-ui .ant-col-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-push-5 {
    left: 20.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-5 {
    right: 20.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-5 {
    margin-left: 20.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-order-5 {
    order: 5;
  }
  .ys-webrtc-sdk-ui .ant-col-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-push-4 {
    left: 16.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-4 {
    right: 16.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-4 {
    margin-left: 16.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-order-4 {
    order: 4;
  }
  .ys-webrtc-sdk-ui .ant-col-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-push-3 {
    left: 12.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-3 {
    right: 12.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-3 {
    margin-left: 12.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-order-3 {
    order: 3;
  }
  .ys-webrtc-sdk-ui .ant-col-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-push-2 {
    left: 8.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-2 {
    right: 8.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-2 {
    margin-left: 8.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-order-2 {
    order: 2;
  }
  .ys-webrtc-sdk-ui .ant-col-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-push-1 {
    left: 4.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-1 {
    right: 4.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-1 {
    margin-left: 4.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-order-1 {
    order: 1;
  }
  .ys-webrtc-sdk-ui .ant-col-0 {
    display: none;
  }
  .ys-webrtc-sdk-ui .ant-col-push-0 {
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-0 {
    right: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-push-0 {
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-0 {
    right: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-0 {
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-order-0 {
    order: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ys-webrtc-sdk-ui .ant-col-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-24 {
    left: 100%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-24 {
    right: 100%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-24 {
    margin-left: 100%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-order-24 {
    order: 24;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-23 {
    left: 95.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-23 {
    right: 95.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-23 {
    margin-left: 95.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-order-23 {
    order: 23;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-22 {
    left: 91.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-22 {
    right: 91.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-22 {
    margin-left: 91.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-order-22 {
    order: 22;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-21 {
    left: 87.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-21 {
    right: 87.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-21 {
    margin-left: 87.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-order-21 {
    order: 21;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-20 {
    left: 83.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-20 {
    right: 83.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-20 {
    margin-left: 83.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-order-20 {
    order: 20;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-19 {
    left: 79.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-19 {
    right: 79.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-19 {
    margin-left: 79.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-order-19 {
    order: 19;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-18 {
    left: 75%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-18 {
    right: 75%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-18 {
    margin-left: 75%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-order-18 {
    order: 18;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-17 {
    left: 70.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-17 {
    right: 70.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-17 {
    margin-left: 70.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-order-17 {
    order: 17;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-16 {
    left: 66.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-16 {
    right: 66.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-16 {
    margin-left: 66.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-order-16 {
    order: 16;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-15 {
    left: 62.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-15 {
    right: 62.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-15 {
    margin-left: 62.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-order-15 {
    order: 15;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-14 {
    left: 58.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-14 {
    right: 58.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-14 {
    margin-left: 58.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-order-14 {
    order: 14;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-13 {
    left: 54.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-13 {
    right: 54.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-13 {
    margin-left: 54.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-order-13 {
    order: 13;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-12 {
    left: 50%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-12 {
    right: 50%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-12 {
    margin-left: 50%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-order-12 {
    order: 12;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-11 {
    left: 45.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-11 {
    right: 45.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-11 {
    margin-left: 45.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-order-11 {
    order: 11;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-10 {
    left: 41.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-10 {
    right: 41.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-10 {
    margin-left: 41.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-order-10 {
    order: 10;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-9 {
    left: 37.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-9 {
    right: 37.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-9 {
    margin-left: 37.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-order-9 {
    order: 9;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-8 {
    left: 33.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-8 {
    right: 33.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-8 {
    margin-left: 33.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-order-8 {
    order: 8;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-7 {
    left: 29.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-7 {
    right: 29.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-7 {
    margin-left: 29.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-order-7 {
    order: 7;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-6 {
    left: 25%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-6 {
    right: 25%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-6 {
    margin-left: 25%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-order-6 {
    order: 6;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-5 {
    left: 20.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-5 {
    right: 20.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-5 {
    margin-left: 20.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-order-5 {
    order: 5;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-4 {
    left: 16.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-4 {
    right: 16.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-4 {
    margin-left: 16.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-order-4 {
    order: 4;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-3 {
    left: 12.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-3 {
    right: 12.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-3 {
    margin-left: 12.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-order-3 {
    order: 3;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-2 {
    left: 8.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-2 {
    right: 8.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-2 {
    margin-left: 8.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-order-2 {
    order: 2;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-1 {
    left: 4.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-1 {
    right: 4.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-1 {
    margin-left: 4.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-order-1 {
    order: 1;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-0 {
    display: none;
  }
  .ys-webrtc-sdk-ui .ant-col-push-0 {
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-0 {
    right: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-0 {
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-0 {
    right: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-0 {
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-order-0 {
    order: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-0.ant-col-rtl {
    right: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-0.ant-col-rtl {
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ys-webrtc-sdk-ui .ant-col-xs-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
  @media (min-width: 576px) {
    .ys-webrtc-sdk-ui .ant-col-sm-24 {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-24 {
      left: 100%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-24 {
      right: 100%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-24 {
      margin-left: 100%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-order-24 {
      order: 24;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-23 {
      display: block;
      flex: 0 0 95.83333333%;
      max-width: 95.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-23 {
      left: 95.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-23 {
      right: 95.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-23 {
      margin-left: 95.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-order-23 {
      order: 23;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-22 {
      display: block;
      flex: 0 0 91.66666667%;
      max-width: 91.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-22 {
      left: 91.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-22 {
      right: 91.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-22 {
      margin-left: 91.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-order-22 {
      order: 22;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-21 {
      display: block;
      flex: 0 0 87.5%;
      max-width: 87.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-21 {
      left: 87.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-21 {
      right: 87.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-21 {
      margin-left: 87.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-order-21 {
      order: 21;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-20 {
      display: block;
      flex: 0 0 83.33333333%;
      max-width: 83.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-20 {
      left: 83.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-20 {
      right: 83.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-20 {
      margin-left: 83.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-order-20 {
      order: 20;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-19 {
      display: block;
      flex: 0 0 79.16666667%;
      max-width: 79.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-19 {
      left: 79.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-19 {
      right: 79.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-19 {
      margin-left: 79.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-order-19 {
      order: 19;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-18 {
      display: block;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-18 {
      left: 75%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-18 {
      right: 75%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-18 {
      margin-left: 75%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-order-18 {
      order: 18;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-17 {
      display: block;
      flex: 0 0 70.83333333%;
      max-width: 70.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-17 {
      left: 70.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-17 {
      right: 70.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-17 {
      margin-left: 70.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-order-17 {
      order: 17;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-16 {
      display: block;
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-16 {
      left: 66.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-16 {
      right: 66.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-16 {
      margin-left: 66.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-order-16 {
      order: 16;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-15 {
      display: block;
      flex: 0 0 62.5%;
      max-width: 62.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-15 {
      left: 62.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-15 {
      right: 62.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-15 {
      margin-left: 62.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-order-15 {
      order: 15;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-14 {
      display: block;
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-14 {
      left: 58.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-14 {
      right: 58.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-14 {
      margin-left: 58.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-order-14 {
      order: 14;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-13 {
      display: block;
      flex: 0 0 54.16666667%;
      max-width: 54.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-13 {
      left: 54.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-13 {
      right: 54.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-13 {
      margin-left: 54.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-order-13 {
      order: 13;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-12 {
      display: block;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-12 {
      left: 50%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-12 {
      right: 50%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-12 {
      margin-left: 50%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-order-12 {
      order: 12;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-11 {
      display: block;
      flex: 0 0 45.83333333%;
      max-width: 45.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-11 {
      left: 45.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-11 {
      right: 45.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-11 {
      margin-left: 45.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-order-11 {
      order: 11;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-10 {
      display: block;
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-10 {
      left: 41.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-10 {
      right: 41.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-10 {
      margin-left: 41.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-order-10 {
      order: 10;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-9 {
      display: block;
      flex: 0 0 37.5%;
      max-width: 37.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-9 {
      left: 37.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-9 {
      right: 37.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-9 {
      margin-left: 37.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-order-9 {
      order: 9;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-8 {
      display: block;
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-8 {
      left: 33.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-8 {
      right: 33.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-8 {
      margin-left: 33.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-order-8 {
      order: 8;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-7 {
      display: block;
      flex: 0 0 29.16666667%;
      max-width: 29.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-7 {
      left: 29.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-7 {
      right: 29.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-7 {
      margin-left: 29.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-order-7 {
      order: 7;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-6 {
      display: block;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-6 {
      left: 25%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-6 {
      right: 25%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-6 {
      margin-left: 25%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-order-6 {
      order: 6;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-5 {
      display: block;
      flex: 0 0 20.83333333%;
      max-width: 20.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-5 {
      left: 20.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-5 {
      right: 20.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-5 {
      margin-left: 20.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-order-5 {
      order: 5;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-4 {
      display: block;
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-4 {
      left: 16.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-4 {
      right: 16.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-4 {
      margin-left: 16.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-order-4 {
      order: 4;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-3 {
      display: block;
      flex: 0 0 12.5%;
      max-width: 12.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-3 {
      left: 12.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-3 {
      right: 12.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-3 {
      margin-left: 12.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-order-3 {
      order: 3;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-2 {
      display: block;
      flex: 0 0 8.33333333%;
      max-width: 8.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-2 {
      left: 8.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-2 {
      right: 8.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-2 {
      margin-left: 8.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-order-2 {
      order: 2;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-1 {
      display: block;
      flex: 0 0 4.16666667%;
      max-width: 4.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-1 {
      left: 4.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-1 {
      right: 4.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-1 {
      margin-left: 4.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-order-1 {
      order: 1;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-0 {
      display: none;
    }
    .ys-webrtc-sdk-ui .ant-col-push-0 {
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-pull-0 {
      right: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-0 {
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-0 {
      right: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-0 {
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-order-0 {
      order: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-push-0.ant-col-rtl {
      right: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-pull-0.ant-col-rtl {
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-0.ant-col-rtl {
      right: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-0.ant-col-rtl {
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-0.ant-col-rtl {
      margin-right: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-1.ant-col-rtl {
      right: 4.16666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-1.ant-col-rtl {
      right: auto;
      left: 4.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-1.ant-col-rtl {
      margin-right: 4.16666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-2.ant-col-rtl {
      right: 8.33333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-2.ant-col-rtl {
      right: auto;
      left: 8.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-2.ant-col-rtl {
      margin-right: 8.33333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-3.ant-col-rtl {
      right: 12.5%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-3.ant-col-rtl {
      right: auto;
      left: 12.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-3.ant-col-rtl {
      margin-right: 12.5%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-4.ant-col-rtl {
      right: 16.66666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-4.ant-col-rtl {
      right: auto;
      left: 16.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-4.ant-col-rtl {
      margin-right: 16.66666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-5.ant-col-rtl {
      right: 20.83333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-5.ant-col-rtl {
      right: auto;
      left: 20.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-5.ant-col-rtl {
      margin-right: 20.83333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-6.ant-col-rtl {
      right: 25%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-6.ant-col-rtl {
      right: auto;
      left: 25%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-6.ant-col-rtl {
      margin-right: 25%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-7.ant-col-rtl {
      right: 29.16666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-7.ant-col-rtl {
      right: auto;
      left: 29.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-7.ant-col-rtl {
      margin-right: 29.16666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-8.ant-col-rtl {
      right: 33.33333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-8.ant-col-rtl {
      right: auto;
      left: 33.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-8.ant-col-rtl {
      margin-right: 33.33333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-9.ant-col-rtl {
      right: 37.5%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-9.ant-col-rtl {
      right: auto;
      left: 37.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-9.ant-col-rtl {
      margin-right: 37.5%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-10.ant-col-rtl {
      right: 41.66666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-10.ant-col-rtl {
      right: auto;
      left: 41.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-10.ant-col-rtl {
      margin-right: 41.66666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-11.ant-col-rtl {
      right: 45.83333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-11.ant-col-rtl {
      right: auto;
      left: 45.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-11.ant-col-rtl {
      margin-right: 45.83333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-12.ant-col-rtl {
      right: 50%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-12.ant-col-rtl {
      right: auto;
      left: 50%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-12.ant-col-rtl {
      margin-right: 50%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-13.ant-col-rtl {
      right: 54.16666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-13.ant-col-rtl {
      right: auto;
      left: 54.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-13.ant-col-rtl {
      margin-right: 54.16666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-14.ant-col-rtl {
      right: 58.33333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-14.ant-col-rtl {
      right: auto;
      left: 58.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-14.ant-col-rtl {
      margin-right: 58.33333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-15.ant-col-rtl {
      right: 62.5%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-15.ant-col-rtl {
      right: auto;
      left: 62.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-15.ant-col-rtl {
      margin-right: 62.5%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-16.ant-col-rtl {
      right: 66.66666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-16.ant-col-rtl {
      right: auto;
      left: 66.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-16.ant-col-rtl {
      margin-right: 66.66666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-17.ant-col-rtl {
      right: 70.83333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-17.ant-col-rtl {
      right: auto;
      left: 70.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-17.ant-col-rtl {
      margin-right: 70.83333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-18.ant-col-rtl {
      right: 75%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-18.ant-col-rtl {
      right: auto;
      left: 75%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-18.ant-col-rtl {
      margin-right: 75%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-19.ant-col-rtl {
      right: 79.16666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-19.ant-col-rtl {
      right: auto;
      left: 79.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-19.ant-col-rtl {
      margin-right: 79.16666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-20.ant-col-rtl {
      right: 83.33333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-20.ant-col-rtl {
      right: auto;
      left: 83.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-20.ant-col-rtl {
      margin-right: 83.33333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-21.ant-col-rtl {
      right: 87.5%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-21.ant-col-rtl {
      right: auto;
      left: 87.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-21.ant-col-rtl {
      margin-right: 87.5%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-22.ant-col-rtl {
      right: 91.66666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-22.ant-col-rtl {
      right: auto;
      left: 91.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-22.ant-col-rtl {
      margin-right: 91.66666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-23.ant-col-rtl {
      right: 95.83333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-23.ant-col-rtl {
      right: auto;
      left: 95.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-23.ant-col-rtl {
      margin-right: 95.83333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-push-24.ant-col-rtl {
      right: 100%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-pull-24.ant-col-rtl {
      right: auto;
      left: 100%;
    }
    .ys-webrtc-sdk-ui .ant-col-sm-offset-24.ant-col-rtl {
      margin-right: 100%;
      margin-left: 0;
    }
  }
  @media (min-width: 768px) {
    .ys-webrtc-sdk-ui .ant-col-md-24 {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-24 {
      left: 100%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-24 {
      right: 100%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-24 {
      margin-left: 100%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-order-24 {
      order: 24;
    }
    .ys-webrtc-sdk-ui .ant-col-md-23 {
      display: block;
      flex: 0 0 95.83333333%;
      max-width: 95.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-23 {
      left: 95.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-23 {
      right: 95.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-23 {
      margin-left: 95.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-order-23 {
      order: 23;
    }
    .ys-webrtc-sdk-ui .ant-col-md-22 {
      display: block;
      flex: 0 0 91.66666667%;
      max-width: 91.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-22 {
      left: 91.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-22 {
      right: 91.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-22 {
      margin-left: 91.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-order-22 {
      order: 22;
    }
    .ys-webrtc-sdk-ui .ant-col-md-21 {
      display: block;
      flex: 0 0 87.5%;
      max-width: 87.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-21 {
      left: 87.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-21 {
      right: 87.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-21 {
      margin-left: 87.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-order-21 {
      order: 21;
    }
    .ys-webrtc-sdk-ui .ant-col-md-20 {
      display: block;
      flex: 0 0 83.33333333%;
      max-width: 83.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-20 {
      left: 83.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-20 {
      right: 83.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-20 {
      margin-left: 83.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-order-20 {
      order: 20;
    }
    .ys-webrtc-sdk-ui .ant-col-md-19 {
      display: block;
      flex: 0 0 79.16666667%;
      max-width: 79.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-19 {
      left: 79.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-19 {
      right: 79.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-19 {
      margin-left: 79.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-order-19 {
      order: 19;
    }
    .ys-webrtc-sdk-ui .ant-col-md-18 {
      display: block;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-18 {
      left: 75%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-18 {
      right: 75%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-18 {
      margin-left: 75%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-order-18 {
      order: 18;
    }
    .ys-webrtc-sdk-ui .ant-col-md-17 {
      display: block;
      flex: 0 0 70.83333333%;
      max-width: 70.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-17 {
      left: 70.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-17 {
      right: 70.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-17 {
      margin-left: 70.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-order-17 {
      order: 17;
    }
    .ys-webrtc-sdk-ui .ant-col-md-16 {
      display: block;
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-16 {
      left: 66.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-16 {
      right: 66.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-16 {
      margin-left: 66.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-order-16 {
      order: 16;
    }
    .ys-webrtc-sdk-ui .ant-col-md-15 {
      display: block;
      flex: 0 0 62.5%;
      max-width: 62.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-15 {
      left: 62.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-15 {
      right: 62.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-15 {
      margin-left: 62.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-order-15 {
      order: 15;
    }
    .ys-webrtc-sdk-ui .ant-col-md-14 {
      display: block;
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-14 {
      left: 58.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-14 {
      right: 58.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-14 {
      margin-left: 58.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-order-14 {
      order: 14;
    }
    .ys-webrtc-sdk-ui .ant-col-md-13 {
      display: block;
      flex: 0 0 54.16666667%;
      max-width: 54.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-13 {
      left: 54.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-13 {
      right: 54.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-13 {
      margin-left: 54.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-order-13 {
      order: 13;
    }
    .ys-webrtc-sdk-ui .ant-col-md-12 {
      display: block;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-12 {
      left: 50%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-12 {
      right: 50%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-12 {
      margin-left: 50%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-order-12 {
      order: 12;
    }
    .ys-webrtc-sdk-ui .ant-col-md-11 {
      display: block;
      flex: 0 0 45.83333333%;
      max-width: 45.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-11 {
      left: 45.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-11 {
      right: 45.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-11 {
      margin-left: 45.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-order-11 {
      order: 11;
    }
    .ys-webrtc-sdk-ui .ant-col-md-10 {
      display: block;
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-10 {
      left: 41.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-10 {
      right: 41.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-10 {
      margin-left: 41.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-order-10 {
      order: 10;
    }
    .ys-webrtc-sdk-ui .ant-col-md-9 {
      display: block;
      flex: 0 0 37.5%;
      max-width: 37.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-9 {
      left: 37.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-9 {
      right: 37.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-9 {
      margin-left: 37.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-order-9 {
      order: 9;
    }
    .ys-webrtc-sdk-ui .ant-col-md-8 {
      display: block;
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-8 {
      left: 33.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-8 {
      right: 33.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-8 {
      margin-left: 33.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-order-8 {
      order: 8;
    }
    .ys-webrtc-sdk-ui .ant-col-md-7 {
      display: block;
      flex: 0 0 29.16666667%;
      max-width: 29.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-7 {
      left: 29.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-7 {
      right: 29.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-7 {
      margin-left: 29.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-order-7 {
      order: 7;
    }
    .ys-webrtc-sdk-ui .ant-col-md-6 {
      display: block;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-6 {
      left: 25%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-6 {
      right: 25%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-6 {
      margin-left: 25%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-order-6 {
      order: 6;
    }
    .ys-webrtc-sdk-ui .ant-col-md-5 {
      display: block;
      flex: 0 0 20.83333333%;
      max-width: 20.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-5 {
      left: 20.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-5 {
      right: 20.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-5 {
      margin-left: 20.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-order-5 {
      order: 5;
    }
    .ys-webrtc-sdk-ui .ant-col-md-4 {
      display: block;
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-4 {
      left: 16.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-4 {
      right: 16.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-4 {
      margin-left: 16.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-order-4 {
      order: 4;
    }
    .ys-webrtc-sdk-ui .ant-col-md-3 {
      display: block;
      flex: 0 0 12.5%;
      max-width: 12.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-3 {
      left: 12.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-3 {
      right: 12.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-3 {
      margin-left: 12.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-order-3 {
      order: 3;
    }
    .ys-webrtc-sdk-ui .ant-col-md-2 {
      display: block;
      flex: 0 0 8.33333333%;
      max-width: 8.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-2 {
      left: 8.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-2 {
      right: 8.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-2 {
      margin-left: 8.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-order-2 {
      order: 2;
    }
    .ys-webrtc-sdk-ui .ant-col-md-1 {
      display: block;
      flex: 0 0 4.16666667%;
      max-width: 4.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-1 {
      left: 4.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-1 {
      right: 4.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-1 {
      margin-left: 4.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-order-1 {
      order: 1;
    }
    .ys-webrtc-sdk-ui .ant-col-md-0 {
      display: none;
    }
    .ys-webrtc-sdk-ui .ant-col-push-0 {
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-pull-0 {
      right: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-0 {
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-0 {
      right: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-0 {
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-md-order-0 {
      order: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-push-0.ant-col-rtl {
      right: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-pull-0.ant-col-rtl {
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-0.ant-col-rtl {
      right: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-0.ant-col-rtl {
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-0.ant-col-rtl {
      margin-right: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-1.ant-col-rtl {
      right: 4.16666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-1.ant-col-rtl {
      right: auto;
      left: 4.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-1.ant-col-rtl {
      margin-right: 4.16666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-2.ant-col-rtl {
      right: 8.33333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-2.ant-col-rtl {
      right: auto;
      left: 8.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-2.ant-col-rtl {
      margin-right: 8.33333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-3.ant-col-rtl {
      right: 12.5%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-3.ant-col-rtl {
      right: auto;
      left: 12.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-3.ant-col-rtl {
      margin-right: 12.5%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-4.ant-col-rtl {
      right: 16.66666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-4.ant-col-rtl {
      right: auto;
      left: 16.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-4.ant-col-rtl {
      margin-right: 16.66666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-5.ant-col-rtl {
      right: 20.83333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-5.ant-col-rtl {
      right: auto;
      left: 20.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-5.ant-col-rtl {
      margin-right: 20.83333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-6.ant-col-rtl {
      right: 25%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-6.ant-col-rtl {
      right: auto;
      left: 25%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-6.ant-col-rtl {
      margin-right: 25%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-7.ant-col-rtl {
      right: 29.16666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-7.ant-col-rtl {
      right: auto;
      left: 29.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-7.ant-col-rtl {
      margin-right: 29.16666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-8.ant-col-rtl {
      right: 33.33333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-8.ant-col-rtl {
      right: auto;
      left: 33.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-8.ant-col-rtl {
      margin-right: 33.33333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-9.ant-col-rtl {
      right: 37.5%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-9.ant-col-rtl {
      right: auto;
      left: 37.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-9.ant-col-rtl {
      margin-right: 37.5%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-10.ant-col-rtl {
      right: 41.66666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-10.ant-col-rtl {
      right: auto;
      left: 41.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-10.ant-col-rtl {
      margin-right: 41.66666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-11.ant-col-rtl {
      right: 45.83333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-11.ant-col-rtl {
      right: auto;
      left: 45.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-11.ant-col-rtl {
      margin-right: 45.83333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-12.ant-col-rtl {
      right: 50%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-12.ant-col-rtl {
      right: auto;
      left: 50%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-12.ant-col-rtl {
      margin-right: 50%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-13.ant-col-rtl {
      right: 54.16666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-13.ant-col-rtl {
      right: auto;
      left: 54.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-13.ant-col-rtl {
      margin-right: 54.16666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-14.ant-col-rtl {
      right: 58.33333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-14.ant-col-rtl {
      right: auto;
      left: 58.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-14.ant-col-rtl {
      margin-right: 58.33333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-15.ant-col-rtl {
      right: 62.5%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-15.ant-col-rtl {
      right: auto;
      left: 62.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-15.ant-col-rtl {
      margin-right: 62.5%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-16.ant-col-rtl {
      right: 66.66666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-16.ant-col-rtl {
      right: auto;
      left: 66.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-16.ant-col-rtl {
      margin-right: 66.66666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-17.ant-col-rtl {
      right: 70.83333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-17.ant-col-rtl {
      right: auto;
      left: 70.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-17.ant-col-rtl {
      margin-right: 70.83333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-18.ant-col-rtl {
      right: 75%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-18.ant-col-rtl {
      right: auto;
      left: 75%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-18.ant-col-rtl {
      margin-right: 75%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-19.ant-col-rtl {
      right: 79.16666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-19.ant-col-rtl {
      right: auto;
      left: 79.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-19.ant-col-rtl {
      margin-right: 79.16666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-20.ant-col-rtl {
      right: 83.33333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-20.ant-col-rtl {
      right: auto;
      left: 83.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-20.ant-col-rtl {
      margin-right: 83.33333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-21.ant-col-rtl {
      right: 87.5%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-21.ant-col-rtl {
      right: auto;
      left: 87.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-21.ant-col-rtl {
      margin-right: 87.5%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-22.ant-col-rtl {
      right: 91.66666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-22.ant-col-rtl {
      right: auto;
      left: 91.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-22.ant-col-rtl {
      margin-right: 91.66666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-23.ant-col-rtl {
      right: 95.83333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-23.ant-col-rtl {
      right: auto;
      left: 95.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-23.ant-col-rtl {
      margin-right: 95.83333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-md-push-24.ant-col-rtl {
      right: 100%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-md-pull-24.ant-col-rtl {
      right: auto;
      left: 100%;
    }
    .ys-webrtc-sdk-ui .ant-col-md-offset-24.ant-col-rtl {
      margin-right: 100%;
      margin-left: 0;
    }
  }
  @media (min-width: 992px) {
    .ys-webrtc-sdk-ui .ant-col-lg-24 {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-24 {
      left: 100%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-24 {
      right: 100%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-24 {
      margin-left: 100%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-order-24 {
      order: 24;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-23 {
      display: block;
      flex: 0 0 95.83333333%;
      max-width: 95.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-23 {
      left: 95.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-23 {
      right: 95.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-23 {
      margin-left: 95.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-order-23 {
      order: 23;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-22 {
      display: block;
      flex: 0 0 91.66666667%;
      max-width: 91.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-22 {
      left: 91.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-22 {
      right: 91.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-22 {
      margin-left: 91.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-order-22 {
      order: 22;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-21 {
      display: block;
      flex: 0 0 87.5%;
      max-width: 87.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-21 {
      left: 87.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-21 {
      right: 87.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-21 {
      margin-left: 87.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-order-21 {
      order: 21;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-20 {
      display: block;
      flex: 0 0 83.33333333%;
      max-width: 83.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-20 {
      left: 83.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-20 {
      right: 83.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-20 {
      margin-left: 83.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-order-20 {
      order: 20;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-19 {
      display: block;
      flex: 0 0 79.16666667%;
      max-width: 79.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-19 {
      left: 79.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-19 {
      right: 79.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-19 {
      margin-left: 79.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-order-19 {
      order: 19;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-18 {
      display: block;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-18 {
      left: 75%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-18 {
      right: 75%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-18 {
      margin-left: 75%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-order-18 {
      order: 18;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-17 {
      display: block;
      flex: 0 0 70.83333333%;
      max-width: 70.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-17 {
      left: 70.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-17 {
      right: 70.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-17 {
      margin-left: 70.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-order-17 {
      order: 17;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-16 {
      display: block;
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-16 {
      left: 66.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-16 {
      right: 66.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-16 {
      margin-left: 66.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-order-16 {
      order: 16;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-15 {
      display: block;
      flex: 0 0 62.5%;
      max-width: 62.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-15 {
      left: 62.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-15 {
      right: 62.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-15 {
      margin-left: 62.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-order-15 {
      order: 15;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-14 {
      display: block;
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-14 {
      left: 58.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-14 {
      right: 58.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-14 {
      margin-left: 58.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-order-14 {
      order: 14;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-13 {
      display: block;
      flex: 0 0 54.16666667%;
      max-width: 54.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-13 {
      left: 54.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-13 {
      right: 54.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-13 {
      margin-left: 54.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-order-13 {
      order: 13;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-12 {
      display: block;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-12 {
      left: 50%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-12 {
      right: 50%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-12 {
      margin-left: 50%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-order-12 {
      order: 12;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-11 {
      display: block;
      flex: 0 0 45.83333333%;
      max-width: 45.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-11 {
      left: 45.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-11 {
      right: 45.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-11 {
      margin-left: 45.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-order-11 {
      order: 11;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-10 {
      display: block;
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-10 {
      left: 41.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-10 {
      right: 41.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-10 {
      margin-left: 41.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-order-10 {
      order: 10;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-9 {
      display: block;
      flex: 0 0 37.5%;
      max-width: 37.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-9 {
      left: 37.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-9 {
      right: 37.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-9 {
      margin-left: 37.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-order-9 {
      order: 9;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-8 {
      display: block;
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-8 {
      left: 33.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-8 {
      right: 33.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-8 {
      margin-left: 33.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-order-8 {
      order: 8;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-7 {
      display: block;
      flex: 0 0 29.16666667%;
      max-width: 29.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-7 {
      left: 29.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-7 {
      right: 29.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-7 {
      margin-left: 29.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-order-7 {
      order: 7;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-6 {
      display: block;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-6 {
      left: 25%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-6 {
      right: 25%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-6 {
      margin-left: 25%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-order-6 {
      order: 6;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-5 {
      display: block;
      flex: 0 0 20.83333333%;
      max-width: 20.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-5 {
      left: 20.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-5 {
      right: 20.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-5 {
      margin-left: 20.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-order-5 {
      order: 5;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-4 {
      display: block;
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-4 {
      left: 16.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-4 {
      right: 16.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-4 {
      margin-left: 16.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-order-4 {
      order: 4;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-3 {
      display: block;
      flex: 0 0 12.5%;
      max-width: 12.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-3 {
      left: 12.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-3 {
      right: 12.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-3 {
      margin-left: 12.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-order-3 {
      order: 3;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-2 {
      display: block;
      flex: 0 0 8.33333333%;
      max-width: 8.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-2 {
      left: 8.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-2 {
      right: 8.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-2 {
      margin-left: 8.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-order-2 {
      order: 2;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-1 {
      display: block;
      flex: 0 0 4.16666667%;
      max-width: 4.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-1 {
      left: 4.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-1 {
      right: 4.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-1 {
      margin-left: 4.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-order-1 {
      order: 1;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-0 {
      display: none;
    }
    .ys-webrtc-sdk-ui .ant-col-push-0 {
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-pull-0 {
      right: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-0 {
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-0 {
      right: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-0 {
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-order-0 {
      order: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-push-0.ant-col-rtl {
      right: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-pull-0.ant-col-rtl {
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-0.ant-col-rtl {
      right: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-0.ant-col-rtl {
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-0.ant-col-rtl {
      margin-right: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-1.ant-col-rtl {
      right: 4.16666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-1.ant-col-rtl {
      right: auto;
      left: 4.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-1.ant-col-rtl {
      margin-right: 4.16666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-2.ant-col-rtl {
      right: 8.33333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-2.ant-col-rtl {
      right: auto;
      left: 8.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-2.ant-col-rtl {
      margin-right: 8.33333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-3.ant-col-rtl {
      right: 12.5%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-3.ant-col-rtl {
      right: auto;
      left: 12.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-3.ant-col-rtl {
      margin-right: 12.5%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-4.ant-col-rtl {
      right: 16.66666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-4.ant-col-rtl {
      right: auto;
      left: 16.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-4.ant-col-rtl {
      margin-right: 16.66666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-5.ant-col-rtl {
      right: 20.83333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-5.ant-col-rtl {
      right: auto;
      left: 20.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-5.ant-col-rtl {
      margin-right: 20.83333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-6.ant-col-rtl {
      right: 25%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-6.ant-col-rtl {
      right: auto;
      left: 25%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-6.ant-col-rtl {
      margin-right: 25%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-7.ant-col-rtl {
      right: 29.16666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-7.ant-col-rtl {
      right: auto;
      left: 29.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-7.ant-col-rtl {
      margin-right: 29.16666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-8.ant-col-rtl {
      right: 33.33333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-8.ant-col-rtl {
      right: auto;
      left: 33.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-8.ant-col-rtl {
      margin-right: 33.33333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-9.ant-col-rtl {
      right: 37.5%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-9.ant-col-rtl {
      right: auto;
      left: 37.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-9.ant-col-rtl {
      margin-right: 37.5%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-10.ant-col-rtl {
      right: 41.66666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-10.ant-col-rtl {
      right: auto;
      left: 41.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-10.ant-col-rtl {
      margin-right: 41.66666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-11.ant-col-rtl {
      right: 45.83333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-11.ant-col-rtl {
      right: auto;
      left: 45.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-11.ant-col-rtl {
      margin-right: 45.83333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-12.ant-col-rtl {
      right: 50%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-12.ant-col-rtl {
      right: auto;
      left: 50%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-12.ant-col-rtl {
      margin-right: 50%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-13.ant-col-rtl {
      right: 54.16666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-13.ant-col-rtl {
      right: auto;
      left: 54.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-13.ant-col-rtl {
      margin-right: 54.16666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-14.ant-col-rtl {
      right: 58.33333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-14.ant-col-rtl {
      right: auto;
      left: 58.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-14.ant-col-rtl {
      margin-right: 58.33333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-15.ant-col-rtl {
      right: 62.5%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-15.ant-col-rtl {
      right: auto;
      left: 62.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-15.ant-col-rtl {
      margin-right: 62.5%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-16.ant-col-rtl {
      right: 66.66666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-16.ant-col-rtl {
      right: auto;
      left: 66.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-16.ant-col-rtl {
      margin-right: 66.66666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-17.ant-col-rtl {
      right: 70.83333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-17.ant-col-rtl {
      right: auto;
      left: 70.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-17.ant-col-rtl {
      margin-right: 70.83333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-18.ant-col-rtl {
      right: 75%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-18.ant-col-rtl {
      right: auto;
      left: 75%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-18.ant-col-rtl {
      margin-right: 75%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-19.ant-col-rtl {
      right: 79.16666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-19.ant-col-rtl {
      right: auto;
      left: 79.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-19.ant-col-rtl {
      margin-right: 79.16666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-20.ant-col-rtl {
      right: 83.33333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-20.ant-col-rtl {
      right: auto;
      left: 83.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-20.ant-col-rtl {
      margin-right: 83.33333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-21.ant-col-rtl {
      right: 87.5%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-21.ant-col-rtl {
      right: auto;
      left: 87.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-21.ant-col-rtl {
      margin-right: 87.5%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-22.ant-col-rtl {
      right: 91.66666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-22.ant-col-rtl {
      right: auto;
      left: 91.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-22.ant-col-rtl {
      margin-right: 91.66666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-23.ant-col-rtl {
      right: 95.83333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-23.ant-col-rtl {
      right: auto;
      left: 95.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-23.ant-col-rtl {
      margin-right: 95.83333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-push-24.ant-col-rtl {
      right: 100%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-pull-24.ant-col-rtl {
      right: auto;
      left: 100%;
    }
    .ys-webrtc-sdk-ui .ant-col-lg-offset-24.ant-col-rtl {
      margin-right: 100%;
      margin-left: 0;
    }
  }
  @media (min-width: 1200px) {
    .ys-webrtc-sdk-ui .ant-col-xl-24 {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-24 {
      left: 100%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-24 {
      right: 100%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-24 {
      margin-left: 100%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-order-24 {
      order: 24;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-23 {
      display: block;
      flex: 0 0 95.83333333%;
      max-width: 95.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-23 {
      left: 95.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-23 {
      right: 95.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-23 {
      margin-left: 95.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-order-23 {
      order: 23;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-22 {
      display: block;
      flex: 0 0 91.66666667%;
      max-width: 91.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-22 {
      left: 91.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-22 {
      right: 91.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-22 {
      margin-left: 91.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-order-22 {
      order: 22;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-21 {
      display: block;
      flex: 0 0 87.5%;
      max-width: 87.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-21 {
      left: 87.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-21 {
      right: 87.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-21 {
      margin-left: 87.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-order-21 {
      order: 21;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-20 {
      display: block;
      flex: 0 0 83.33333333%;
      max-width: 83.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-20 {
      left: 83.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-20 {
      right: 83.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-20 {
      margin-left: 83.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-order-20 {
      order: 20;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-19 {
      display: block;
      flex: 0 0 79.16666667%;
      max-width: 79.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-19 {
      left: 79.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-19 {
      right: 79.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-19 {
      margin-left: 79.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-order-19 {
      order: 19;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-18 {
      display: block;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-18 {
      left: 75%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-18 {
      right: 75%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-18 {
      margin-left: 75%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-order-18 {
      order: 18;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-17 {
      display: block;
      flex: 0 0 70.83333333%;
      max-width: 70.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-17 {
      left: 70.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-17 {
      right: 70.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-17 {
      margin-left: 70.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-order-17 {
      order: 17;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-16 {
      display: block;
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-16 {
      left: 66.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-16 {
      right: 66.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-16 {
      margin-left: 66.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-order-16 {
      order: 16;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-15 {
      display: block;
      flex: 0 0 62.5%;
      max-width: 62.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-15 {
      left: 62.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-15 {
      right: 62.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-15 {
      margin-left: 62.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-order-15 {
      order: 15;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-14 {
      display: block;
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-14 {
      left: 58.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-14 {
      right: 58.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-14 {
      margin-left: 58.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-order-14 {
      order: 14;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-13 {
      display: block;
      flex: 0 0 54.16666667%;
      max-width: 54.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-13 {
      left: 54.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-13 {
      right: 54.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-13 {
      margin-left: 54.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-order-13 {
      order: 13;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-12 {
      display: block;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-12 {
      left: 50%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-12 {
      right: 50%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-12 {
      margin-left: 50%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-order-12 {
      order: 12;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-11 {
      display: block;
      flex: 0 0 45.83333333%;
      max-width: 45.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-11 {
      left: 45.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-11 {
      right: 45.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-11 {
      margin-left: 45.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-order-11 {
      order: 11;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-10 {
      display: block;
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-10 {
      left: 41.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-10 {
      right: 41.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-10 {
      margin-left: 41.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-order-10 {
      order: 10;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-9 {
      display: block;
      flex: 0 0 37.5%;
      max-width: 37.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-9 {
      left: 37.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-9 {
      right: 37.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-9 {
      margin-left: 37.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-order-9 {
      order: 9;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-8 {
      display: block;
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-8 {
      left: 33.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-8 {
      right: 33.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-8 {
      margin-left: 33.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-order-8 {
      order: 8;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-7 {
      display: block;
      flex: 0 0 29.16666667%;
      max-width: 29.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-7 {
      left: 29.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-7 {
      right: 29.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-7 {
      margin-left: 29.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-order-7 {
      order: 7;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-6 {
      display: block;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-6 {
      left: 25%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-6 {
      right: 25%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-6 {
      margin-left: 25%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-order-6 {
      order: 6;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-5 {
      display: block;
      flex: 0 0 20.83333333%;
      max-width: 20.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-5 {
      left: 20.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-5 {
      right: 20.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-5 {
      margin-left: 20.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-order-5 {
      order: 5;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-4 {
      display: block;
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-4 {
      left: 16.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-4 {
      right: 16.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-4 {
      margin-left: 16.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-order-4 {
      order: 4;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-3 {
      display: block;
      flex: 0 0 12.5%;
      max-width: 12.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-3 {
      left: 12.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-3 {
      right: 12.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-3 {
      margin-left: 12.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-order-3 {
      order: 3;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-2 {
      display: block;
      flex: 0 0 8.33333333%;
      max-width: 8.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-2 {
      left: 8.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-2 {
      right: 8.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-2 {
      margin-left: 8.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-order-2 {
      order: 2;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-1 {
      display: block;
      flex: 0 0 4.16666667%;
      max-width: 4.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-1 {
      left: 4.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-1 {
      right: 4.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-1 {
      margin-left: 4.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-order-1 {
      order: 1;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-0 {
      display: none;
    }
    .ys-webrtc-sdk-ui .ant-col-push-0 {
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-pull-0 {
      right: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-0 {
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-0 {
      right: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-0 {
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-order-0 {
      order: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-push-0.ant-col-rtl {
      right: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-pull-0.ant-col-rtl {
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-0.ant-col-rtl {
      right: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-0.ant-col-rtl {
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-0.ant-col-rtl {
      margin-right: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-1.ant-col-rtl {
      right: 4.16666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-1.ant-col-rtl {
      right: auto;
      left: 4.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-1.ant-col-rtl {
      margin-right: 4.16666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-2.ant-col-rtl {
      right: 8.33333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-2.ant-col-rtl {
      right: auto;
      left: 8.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-2.ant-col-rtl {
      margin-right: 8.33333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-3.ant-col-rtl {
      right: 12.5%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-3.ant-col-rtl {
      right: auto;
      left: 12.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-3.ant-col-rtl {
      margin-right: 12.5%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-4.ant-col-rtl {
      right: 16.66666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-4.ant-col-rtl {
      right: auto;
      left: 16.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-4.ant-col-rtl {
      margin-right: 16.66666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-5.ant-col-rtl {
      right: 20.83333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-5.ant-col-rtl {
      right: auto;
      left: 20.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-5.ant-col-rtl {
      margin-right: 20.83333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-6.ant-col-rtl {
      right: 25%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-6.ant-col-rtl {
      right: auto;
      left: 25%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-6.ant-col-rtl {
      margin-right: 25%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-7.ant-col-rtl {
      right: 29.16666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-7.ant-col-rtl {
      right: auto;
      left: 29.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-7.ant-col-rtl {
      margin-right: 29.16666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-8.ant-col-rtl {
      right: 33.33333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-8.ant-col-rtl {
      right: auto;
      left: 33.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-8.ant-col-rtl {
      margin-right: 33.33333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-9.ant-col-rtl {
      right: 37.5%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-9.ant-col-rtl {
      right: auto;
      left: 37.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-9.ant-col-rtl {
      margin-right: 37.5%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-10.ant-col-rtl {
      right: 41.66666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-10.ant-col-rtl {
      right: auto;
      left: 41.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-10.ant-col-rtl {
      margin-right: 41.66666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-11.ant-col-rtl {
      right: 45.83333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-11.ant-col-rtl {
      right: auto;
      left: 45.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-11.ant-col-rtl {
      margin-right: 45.83333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-12.ant-col-rtl {
      right: 50%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-12.ant-col-rtl {
      right: auto;
      left: 50%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-12.ant-col-rtl {
      margin-right: 50%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-13.ant-col-rtl {
      right: 54.16666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-13.ant-col-rtl {
      right: auto;
      left: 54.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-13.ant-col-rtl {
      margin-right: 54.16666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-14.ant-col-rtl {
      right: 58.33333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-14.ant-col-rtl {
      right: auto;
      left: 58.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-14.ant-col-rtl {
      margin-right: 58.33333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-15.ant-col-rtl {
      right: 62.5%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-15.ant-col-rtl {
      right: auto;
      left: 62.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-15.ant-col-rtl {
      margin-right: 62.5%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-16.ant-col-rtl {
      right: 66.66666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-16.ant-col-rtl {
      right: auto;
      left: 66.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-16.ant-col-rtl {
      margin-right: 66.66666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-17.ant-col-rtl {
      right: 70.83333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-17.ant-col-rtl {
      right: auto;
      left: 70.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-17.ant-col-rtl {
      margin-right: 70.83333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-18.ant-col-rtl {
      right: 75%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-18.ant-col-rtl {
      right: auto;
      left: 75%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-18.ant-col-rtl {
      margin-right: 75%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-19.ant-col-rtl {
      right: 79.16666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-19.ant-col-rtl {
      right: auto;
      left: 79.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-19.ant-col-rtl {
      margin-right: 79.16666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-20.ant-col-rtl {
      right: 83.33333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-20.ant-col-rtl {
      right: auto;
      left: 83.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-20.ant-col-rtl {
      margin-right: 83.33333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-21.ant-col-rtl {
      right: 87.5%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-21.ant-col-rtl {
      right: auto;
      left: 87.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-21.ant-col-rtl {
      margin-right: 87.5%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-22.ant-col-rtl {
      right: 91.66666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-22.ant-col-rtl {
      right: auto;
      left: 91.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-22.ant-col-rtl {
      margin-right: 91.66666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-23.ant-col-rtl {
      right: 95.83333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-23.ant-col-rtl {
      right: auto;
      left: 95.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-23.ant-col-rtl {
      margin-right: 95.83333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-push-24.ant-col-rtl {
      right: 100%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-pull-24.ant-col-rtl {
      right: auto;
      left: 100%;
    }
    .ys-webrtc-sdk-ui .ant-col-xl-offset-24.ant-col-rtl {
      margin-right: 100%;
      margin-left: 0;
    }
  }
  @media (min-width: 1600px) {
    .ys-webrtc-sdk-ui .ant-col-xxl-24 {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-24 {
      left: 100%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-24 {
      right: 100%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-24 {
      margin-left: 100%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-order-24 {
      order: 24;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-23 {
      display: block;
      flex: 0 0 95.83333333%;
      max-width: 95.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-23 {
      left: 95.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-23 {
      right: 95.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-23 {
      margin-left: 95.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-order-23 {
      order: 23;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-22 {
      display: block;
      flex: 0 0 91.66666667%;
      max-width: 91.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-22 {
      left: 91.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-22 {
      right: 91.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-22 {
      margin-left: 91.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-order-22 {
      order: 22;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-21 {
      display: block;
      flex: 0 0 87.5%;
      max-width: 87.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-21 {
      left: 87.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-21 {
      right: 87.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-21 {
      margin-left: 87.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-order-21 {
      order: 21;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-20 {
      display: block;
      flex: 0 0 83.33333333%;
      max-width: 83.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-20 {
      left: 83.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-20 {
      right: 83.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-20 {
      margin-left: 83.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-order-20 {
      order: 20;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-19 {
      display: block;
      flex: 0 0 79.16666667%;
      max-width: 79.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-19 {
      left: 79.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-19 {
      right: 79.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-19 {
      margin-left: 79.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-order-19 {
      order: 19;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-18 {
      display: block;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-18 {
      left: 75%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-18 {
      right: 75%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-18 {
      margin-left: 75%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-order-18 {
      order: 18;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-17 {
      display: block;
      flex: 0 0 70.83333333%;
      max-width: 70.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-17 {
      left: 70.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-17 {
      right: 70.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-17 {
      margin-left: 70.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-order-17 {
      order: 17;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-16 {
      display: block;
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-16 {
      left: 66.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-16 {
      right: 66.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-16 {
      margin-left: 66.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-order-16 {
      order: 16;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-15 {
      display: block;
      flex: 0 0 62.5%;
      max-width: 62.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-15 {
      left: 62.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-15 {
      right: 62.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-15 {
      margin-left: 62.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-order-15 {
      order: 15;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-14 {
      display: block;
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-14 {
      left: 58.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-14 {
      right: 58.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-14 {
      margin-left: 58.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-order-14 {
      order: 14;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-13 {
      display: block;
      flex: 0 0 54.16666667%;
      max-width: 54.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-13 {
      left: 54.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-13 {
      right: 54.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-13 {
      margin-left: 54.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-order-13 {
      order: 13;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-12 {
      display: block;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-12 {
      left: 50%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-12 {
      right: 50%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-12 {
      margin-left: 50%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-order-12 {
      order: 12;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-11 {
      display: block;
      flex: 0 0 45.83333333%;
      max-width: 45.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-11 {
      left: 45.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-11 {
      right: 45.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-11 {
      margin-left: 45.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-order-11 {
      order: 11;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-10 {
      display: block;
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-10 {
      left: 41.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-10 {
      right: 41.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-10 {
      margin-left: 41.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-order-10 {
      order: 10;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-9 {
      display: block;
      flex: 0 0 37.5%;
      max-width: 37.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-9 {
      left: 37.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-9 {
      right: 37.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-9 {
      margin-left: 37.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-order-9 {
      order: 9;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-8 {
      display: block;
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-8 {
      left: 33.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-8 {
      right: 33.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-8 {
      margin-left: 33.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-order-8 {
      order: 8;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-7 {
      display: block;
      flex: 0 0 29.16666667%;
      max-width: 29.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-7 {
      left: 29.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-7 {
      right: 29.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-7 {
      margin-left: 29.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-order-7 {
      order: 7;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-6 {
      display: block;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-6 {
      left: 25%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-6 {
      right: 25%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-6 {
      margin-left: 25%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-order-6 {
      order: 6;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-5 {
      display: block;
      flex: 0 0 20.83333333%;
      max-width: 20.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-5 {
      left: 20.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-5 {
      right: 20.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-5 {
      margin-left: 20.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-order-5 {
      order: 5;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-4 {
      display: block;
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-4 {
      left: 16.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-4 {
      right: 16.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-4 {
      margin-left: 16.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-order-4 {
      order: 4;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-3 {
      display: block;
      flex: 0 0 12.5%;
      max-width: 12.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-3 {
      left: 12.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-3 {
      right: 12.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-3 {
      margin-left: 12.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-order-3 {
      order: 3;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-2 {
      display: block;
      flex: 0 0 8.33333333%;
      max-width: 8.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-2 {
      left: 8.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-2 {
      right: 8.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-2 {
      margin-left: 8.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-order-2 {
      order: 2;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-1 {
      display: block;
      flex: 0 0 4.16666667%;
      max-width: 4.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-1 {
      left: 4.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-1 {
      right: 4.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-1 {
      margin-left: 4.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-order-1 {
      order: 1;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-0 {
      display: none;
    }
    .ys-webrtc-sdk-ui .ant-col-push-0 {
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-pull-0 {
      right: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-0 {
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-0 {
      right: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-0 {
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-order-0 {
      order: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-push-0.ant-col-rtl {
      right: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-pull-0.ant-col-rtl {
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-0.ant-col-rtl {
      right: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-0.ant-col-rtl {
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-0.ant-col-rtl {
      margin-right: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-1.ant-col-rtl {
      right: 4.16666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-1.ant-col-rtl {
      right: auto;
      left: 4.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-1.ant-col-rtl {
      margin-right: 4.16666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-2.ant-col-rtl {
      right: 8.33333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-2.ant-col-rtl {
      right: auto;
      left: 8.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-2.ant-col-rtl {
      margin-right: 8.33333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-3.ant-col-rtl {
      right: 12.5%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-3.ant-col-rtl {
      right: auto;
      left: 12.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-3.ant-col-rtl {
      margin-right: 12.5%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-4.ant-col-rtl {
      right: 16.66666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-4.ant-col-rtl {
      right: auto;
      left: 16.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-4.ant-col-rtl {
      margin-right: 16.66666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-5.ant-col-rtl {
      right: 20.83333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-5.ant-col-rtl {
      right: auto;
      left: 20.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-5.ant-col-rtl {
      margin-right: 20.83333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-6.ant-col-rtl {
      right: 25%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-6.ant-col-rtl {
      right: auto;
      left: 25%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-6.ant-col-rtl {
      margin-right: 25%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-7.ant-col-rtl {
      right: 29.16666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-7.ant-col-rtl {
      right: auto;
      left: 29.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-7.ant-col-rtl {
      margin-right: 29.16666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-8.ant-col-rtl {
      right: 33.33333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-8.ant-col-rtl {
      right: auto;
      left: 33.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-8.ant-col-rtl {
      margin-right: 33.33333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-9.ant-col-rtl {
      right: 37.5%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-9.ant-col-rtl {
      right: auto;
      left: 37.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-9.ant-col-rtl {
      margin-right: 37.5%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-10.ant-col-rtl {
      right: 41.66666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-10.ant-col-rtl {
      right: auto;
      left: 41.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-10.ant-col-rtl {
      margin-right: 41.66666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-11.ant-col-rtl {
      right: 45.83333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-11.ant-col-rtl {
      right: auto;
      left: 45.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-11.ant-col-rtl {
      margin-right: 45.83333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-12.ant-col-rtl {
      right: 50%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-12.ant-col-rtl {
      right: auto;
      left: 50%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-12.ant-col-rtl {
      margin-right: 50%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-13.ant-col-rtl {
      right: 54.16666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-13.ant-col-rtl {
      right: auto;
      left: 54.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-13.ant-col-rtl {
      margin-right: 54.16666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-14.ant-col-rtl {
      right: 58.33333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-14.ant-col-rtl {
      right: auto;
      left: 58.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-14.ant-col-rtl {
      margin-right: 58.33333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-15.ant-col-rtl {
      right: 62.5%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-15.ant-col-rtl {
      right: auto;
      left: 62.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-15.ant-col-rtl {
      margin-right: 62.5%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-16.ant-col-rtl {
      right: 66.66666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-16.ant-col-rtl {
      right: auto;
      left: 66.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-16.ant-col-rtl {
      margin-right: 66.66666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-17.ant-col-rtl {
      right: 70.83333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-17.ant-col-rtl {
      right: auto;
      left: 70.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-17.ant-col-rtl {
      margin-right: 70.83333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-18.ant-col-rtl {
      right: 75%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-18.ant-col-rtl {
      right: auto;
      left: 75%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-18.ant-col-rtl {
      margin-right: 75%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-19.ant-col-rtl {
      right: 79.16666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-19.ant-col-rtl {
      right: auto;
      left: 79.16666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-19.ant-col-rtl {
      margin-right: 79.16666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-20.ant-col-rtl {
      right: 83.33333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-20.ant-col-rtl {
      right: auto;
      left: 83.33333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-20.ant-col-rtl {
      margin-right: 83.33333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-21.ant-col-rtl {
      right: 87.5%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-21.ant-col-rtl {
      right: auto;
      left: 87.5%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-21.ant-col-rtl {
      margin-right: 87.5%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-22.ant-col-rtl {
      right: 91.66666667%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-22.ant-col-rtl {
      right: auto;
      left: 91.66666667%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-22.ant-col-rtl {
      margin-right: 91.66666667%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-23.ant-col-rtl {
      right: 95.83333333%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-23.ant-col-rtl {
      right: auto;
      left: 95.83333333%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-23.ant-col-rtl {
      margin-right: 95.83333333%;
      margin-left: 0;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-push-24.ant-col-rtl {
      right: 100%;
      left: auto;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-pull-24.ant-col-rtl {
      right: auto;
      left: 100%;
    }
    .ys-webrtc-sdk-ui .ant-col-xxl-offset-24.ant-col-rtl {
      margin-right: 100%;
      margin-left: 0;
    }
  }
  .ys-webrtc-sdk-ui .ant-row-rtl {
    direction: rtl;
  }
  
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  /* stylelint-disable no-duplicate-selectors */
  /* stylelint-disable */
  /* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
  /* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
  .ys-webrtc-sdk-ui .scroll-bar-style {
    scrollbar-width: thin;
    scrollbar-color: #e6e9ec #fff;
    /* 定义滚动条轨道内阴影 */
    /* 定义滑块内阴影 */
    /* 滚动条 */
  }
  .ys-webrtc-sdk-ui .scroll-bar-style::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .scroll-bar-style::-webkit-scrollbar-thumb {
    background-color: #e6e9ec;
    border-radius: 3px;
    -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .scroll-bar-style::-webkit-scrollbar {
    width: 6px;
    padding-right: 50%;
    background-color: #fff;
    border-radius: 3px;
  }
  .ys-webrtc-sdk-ui .ant-menu-item-danger.ant-menu-item {
    color: #e6492d;
  }
  .ys-webrtc-sdk-ui .ant-menu-item-danger.ant-menu-item:hover,
  .ys-webrtc-sdk-ui .ant-menu-item-danger.ant-menu-item-active {
    color: #e6492d;
  }
  .ys-webrtc-sdk-ui .ant-menu-item-danger.ant-menu-item:active {
    background: #fff5f0;
  }
  .ys-webrtc-sdk-ui .ant-menu-item-danger.ant-menu-item-selected {
    color: #e6492d;
  }
  .ys-webrtc-sdk-ui .ant-menu-item-danger.ant-menu-item-selected > a,
  .ys-webrtc-sdk-ui .ant-menu-item-danger.ant-menu-item-selected > a:hover {
    color: #e6492d;
  }
  .ys-webrtc-sdk-ui .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
    background-color: #fff5f0;
  }
  .ys-webrtc-sdk-ui .ant-menu-inline .ant-menu-item-danger.ant-menu-item::after {
    border-right-color: #e6492d;
  }
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item-danger.ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item-danger.ant-menu-item:hover,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item-danger.ant-menu-item > a {
    color: #e6492d;
  }
  .ys-webrtc-sdk-ui .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
    color: #fff;
    background-color: #e6492d;
  }
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item-danger.ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item-danger.ant-menu-item:hover,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item-danger.ant-menu-item > a {
    color: #e6492d;
  }
  .ys-webrtc-sdk-ui .ant-menu-blue.ant-menu-blue:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
    color: #fff;
    background-color: #e6492d;
  }
  .ys-webrtc-sdk-ui .ant-menu {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: #23282f;
    font-variant: tabular-nums;
    -webkit-font-feature-settings: 'tnum';
            font-feature-settings: 'tnum';
    line-height: 1.57142857;
    margin-bottom: 0;
    padding-left: 0;
    color: #778090;
    font-size: 14px;
    line-height: 0;
    text-align: left;
    list-style: none;
    background: #fff;
    outline: none;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.02), 0 2px 6px 6px rgba(0, 0, 0, 0.02), 0 2px 6px 0 rgba(0, 0, 0, 0.06);
    transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
  }
  .ys-webrtc-sdk-ui .ant-menu::before {
    display: table;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-menu::after {
    display: table;
    clear: both;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-menu ul,
  .ys-webrtc-sdk-ui .ant-menu ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .ys-webrtc-sdk-ui .ant-menu-hidden {
    display: none;
  }
  .ys-webrtc-sdk-ui .ant-menu-item-group-title {
    height: 1.5715;
    padding: 8px 16px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    line-height: 1.5715;
    transition: all 0.3s;
  }
  .ys-webrtc-sdk-ui .ant-menu-horizontal .ant-menu-submenu {
    transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ys-webrtc-sdk-ui .ant-menu-submenu,
  .ys-webrtc-sdk-ui .ant-menu-submenu-inline {
    transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ys-webrtc-sdk-ui .ant-menu-submenu-selected {
    color: #006ee1;
  }
  .ys-webrtc-sdk-ui .ant-menu-item:active,
  .ys-webrtc-sdk-ui .ant-menu-submenu-title:active {
    background: #e6f6ff;
  }
  .ys-webrtc-sdk-ui .ant-menu-submenu .ant-menu-sub {
    cursor: initial;
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ys-webrtc-sdk-ui .ant-menu-item a {
    color: #778090;
  }
  .ys-webrtc-sdk-ui .ant-menu-item a:hover {
    color: #006ee1;
  }
  .ys-webrtc-sdk-ui .ant-menu-item a::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-menu-item > .ant-badge a {
    color: #778090;
  }
  .ys-webrtc-sdk-ui .ant-menu-item > .ant-badge a:hover {
    color: #006ee1;
  }
  .ys-webrtc-sdk-ui .ant-menu-item-divider {
    height: 1px;
    overflow: hidden;
    line-height: 0;
    background-color: #e6e9ec;
  }
  .ys-webrtc-sdk-ui .ant-menu-item:hover,
  .ys-webrtc-sdk-ui .ant-menu-item-active,
  .ys-webrtc-sdk-ui .ant-menu:not(.ant-menu-inline).ant-menu:not(.ant-menu-blue) .ant-menu-submenu-open,
  .ys-webrtc-sdk-ui .ant-menu-submenu-active,
  .ys-webrtc-sdk-ui .ant-menu-submenu-title:hover {
    color: #006ee1;
  }
  .ys-webrtc-sdk-ui .ant-menu-horizontal .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-horizontal .ant-menu-submenu {
    margin-top: -1px;
  }
  .ys-webrtc-sdk-ui .ant-menu-horizontal > .ant-menu-item:hover,
  .ys-webrtc-sdk-ui .ant-menu-horizontal > .ant-menu-item-active,
  .ys-webrtc-sdk-ui .ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
    background-color: transparent;
  }
  .ys-webrtc-sdk-ui .ant-menu-item-selected {
    color: #006ee1;
  }
  .ys-webrtc-sdk-ui .ant-menu-item-selected a,
  .ys-webrtc-sdk-ui .ant-menu-item-selected a:hover {
    color: #006ee1;
  }
  .ys-webrtc-sdk-ui .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #e6f6ff;
  }
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed.ant-menu-vertical:not(.ant-menu-dark):not(.ant-menu-blue) .ant-menu-item-selected,
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed.ant-menu-vertical:not(.ant-menu-blue):not(.ant-menu-dark) .ant-menu-item-selected,
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed.ant-menu-vertical:not(.ant-menu-dark):not(.ant-menu-blue) .ant-menu-submenu-selected,
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed.ant-menu-vertical:not(.ant-menu-blue):not(.ant-menu-dark) .ant-menu-submenu-selected {
    position: relative;
    background-color: #e6f6ff;
  }
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed.ant-menu-vertical:not(.ant-menu-dark):not(.ant-menu-blue) .ant-menu-item-selected::after,
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed.ant-menu-vertical:not(.ant-menu-blue):not(.ant-menu-dark) .ant-menu-item-selected::after,
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed.ant-menu-vertical:not(.ant-menu-dark):not(.ant-menu-blue) .ant-menu-submenu-selected::after,
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed.ant-menu-vertical:not(.ant-menu-blue):not(.ant-menu-dark) .ant-menu-submenu-selected::after {
    position: absolute;
    top: 0;
    right: calc(100% - 3px);
    bottom: 0;
    border-right: 3px solid #006ee1;
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    opacity: 1;
    transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-menu-inline,
  .ys-webrtc-sdk-ui .ant-menu-vertical,
  .ys-webrtc-sdk-ui .ant-menu-vertical-left {
    border-right: 1px solid #e6e9ec;
  }
  .ys-webrtc-sdk-ui .ant-menu-vertical-right {
    border-left: 1px solid #e6e9ec;
  }
  .ys-webrtc-sdk-ui .ant-menu-vertical.ant-menu-sub,
  .ys-webrtc-sdk-ui .ant-menu-vertical-left.ant-menu-sub,
  .ys-webrtc-sdk-ui .ant-menu-vertical-right.ant-menu-sub {
    min-width: 160px;
    max-height: calc(100vh - 100px);
    padding: 0;
    overflow: hidden;
    border-right: 0;
  }
  .ys-webrtc-sdk-ui .ant-menu-vertical.ant-menu-sub:not([class*='-active']),
  .ys-webrtc-sdk-ui .ant-menu-vertical-left.ant-menu-sub:not([class*='-active']),
  .ys-webrtc-sdk-ui .ant-menu-vertical-right.ant-menu-sub:not([class*='-active']) {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .ys-webrtc-sdk-ui .ant-menu-vertical.ant-menu-sub .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
    left: 0;
    margin-left: 0;
    border-right: 0;
  }
  .ys-webrtc-sdk-ui .ant-menu-vertical.ant-menu-sub .ant-menu-item::after,
  .ys-webrtc-sdk-ui .ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
  .ys-webrtc-sdk-ui .ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after {
    border-right: 0;
  }
  .ys-webrtc-sdk-ui .ant-menu-vertical.ant-menu-sub > .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
  .ys-webrtc-sdk-ui .ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
  .ys-webrtc-sdk-ui .ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
  }
  .ys-webrtc-sdk-ui .ant-menu-horizontal.ant-menu-sub {
    min-width: 114px;
  }
  .ys-webrtc-sdk-ui .ant-menu-horizontal .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-horizontal .ant-menu-submenu-title {
    transition: border-color 0.3s, background 0.3s;
  }
  .ys-webrtc-sdk-ui .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-submenu-title {
    position: relative;
    display: block;
    margin: 0;
    padding: 0 20px;
    white-space: nowrap;
    cursor: pointer;
    transition: border-color 0.3s, background 0.3s, padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ys-webrtc-sdk-ui .ant-menu-item .ant-menu-item-icon,
  .ys-webrtc-sdk-ui .ant-menu-submenu-title .ant-menu-item-icon,
  .ys-webrtc-sdk-ui .ant-menu-item .anticon,
  .ys-webrtc-sdk-ui .ant-menu-submenu-title .anticon {
    min-width: 16px;
    margin-right: 12px;
    font-size: 16px;
    transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
  }
  .ys-webrtc-sdk-ui .ant-menu-item .ant-menu-item-icon + span,
  .ys-webrtc-sdk-ui .ant-menu-submenu-title .ant-menu-item-icon + span,
  .ys-webrtc-sdk-ui .ant-menu-item .anticon + span,
  .ys-webrtc-sdk-ui .ant-menu-submenu-title .anticon + span {
    opacity: 1;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
  }
  .ys-webrtc-sdk-ui .ant-menu-item.ant-menu-item-only-child > .anticon,
  .ys-webrtc-sdk-ui .ant-menu-submenu-title.ant-menu-item-only-child > .anticon,
  .ys-webrtc-sdk-ui .ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
  .ys-webrtc-sdk-ui .ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon {
    margin-right: 0;
  }
  .ys-webrtc-sdk-ui .ant-menu > .ant-menu-item-divider {
    height: 1px;
    margin: 1px 0;
    padding: 0;
    overflow: hidden;
    line-height: 0;
    background-color: #e6e9ec;
  }
  .ys-webrtc-sdk-ui .ant-menu-submenu-popup {
    position: absolute;
    z-index: 1050;
    background: transparent;
    border-radius: 12px;
    box-shadow: none;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
  }
  .ys-webrtc-sdk-ui .ant-menu-submenu-popup::before {
    position: absolute;
    top: -7px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0.0001;
    content: ' ';
  }
  .ys-webrtc-sdk-ui .ant-menu-submenu-popup .ant-menu-item {
    color: #23282f;
  }
  .ys-webrtc-sdk-ui .ant-menu-submenu-popup .ant-menu-item:hover {
    background-color: #f6f8fa !important;
  }
  .ys-webrtc-sdk-ui .ant-menu-submenu-placement-rightTop::before {
    top: 0;
    left: -7px;
  }
  .ys-webrtc-sdk-ui .ant-menu-submenu > .ant-menu {
    background-color: #fff;
    border-radius: 12px;
  }
  .ys-webrtc-sdk-ui .ant-menu-submenu > .ant-menu-submenu-title::after {
    transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ys-webrtc-sdk-ui .ant-menu-submenu-popup > .ant-menu {
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ant-menu-submenu-expand-icon,
  .ys-webrtc-sdk-ui .ant-menu-submenu-arrow {
    position: absolute;
    top: 50%;
    right: 16px;
    width: 10px;
    color: #778090;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ys-webrtc-sdk-ui .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu-submenu-arrow::after {
    position: absolute;
    width: 6px;
    height: 1.5px;
    background-color: currentColor;
    border-radius: 2px;
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-menu-submenu-arrow::before {
    -webkit-transform: rotate(45deg) translateY(-2.5px);
            transform: rotate(45deg) translateY(-2.5px);
  }
  .ys-webrtc-sdk-ui .ant-menu-submenu-arrow::after {
    -webkit-transform: rotate(-45deg) translateY(2.5px);
            transform: rotate(-45deg) translateY(2.5px);
  }
  .ys-webrtc-sdk-ui .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
  .ys-webrtc-sdk-ui .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: #006ee1;
  }
  .ys-webrtc-sdk-ui .ant-menu-submenu-inline .ant-menu-submenu-arrow::before {
    -webkit-transform: rotate(-45deg) translateX(2.5px);
            transform: rotate(-45deg) translateX(2.5px);
  }
  .ys-webrtc-sdk-ui .ant-menu-submenu-inline .ant-menu-submenu-arrow::after {
    -webkit-transform: rotate(45deg) translateX(-2.5px);
            transform: rotate(45deg) translateX(-2.5px);
  }
  .ys-webrtc-sdk-ui .ant-menu-submenu-horizontal .ant-menu-submenu-arrow {
    right: -13px;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  .ys-webrtc-sdk-ui .ant-menu-submenu-open.ant-menu-submenu-horizontal > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    color: #006ee1;
    -webkit-transform: rotate(90deg) translateX(1px);
            transform: rotate(90deg) translateX(1px);
  }
  .ys-webrtc-sdk-ui .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }
  .ys-webrtc-sdk-ui .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
    -webkit-transform: rotate(-45deg) translateX(-2.5px);
            transform: rotate(-45deg) translateX(-2.5px);
  }
  .ys-webrtc-sdk-ui .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
    -webkit-transform: rotate(45deg) translateX(2.5px);
            transform: rotate(45deg) translateX(2.5px);
  }
  .ys-webrtc-sdk-ui .ant-menu-vertical .ant-menu-submenu-selected,
  .ys-webrtc-sdk-ui .ant-menu-vertical-left .ant-menu-submenu-selected,
  .ys-webrtc-sdk-ui .ant-menu-vertical-right .ant-menu-submenu-selected {
    color: #006ee1;
  }
  .ys-webrtc-sdk-ui .ant-menu-horizontal {
    line-height: 52px;
    border: 0;
    border-bottom: 1px solid #e6e9ec;
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-menu-horizontal:not(.ant-menu-dark).ant-menu-horizontal:not(.ant-menu-blue) > .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-horizontal:not(.ant-menu-dark).ant-menu-horizontal:not(.ant-menu-blue) > .ant-menu-submenu {
    margin: 0 20px;
    margin-top: -1px;
    margin-bottom: 0;
    padding: 0 20px;
    padding-right: 0;
    padding-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-menu-horizontal:not(.ant-menu-dark).ant-menu-horizontal:not(.ant-menu-blue) > .ant-menu-item:hover,
  .ys-webrtc-sdk-ui .ant-menu-horizontal:not(.ant-menu-dark).ant-menu-horizontal:not(.ant-menu-blue) > .ant-menu-submenu:hover,
  .ys-webrtc-sdk-ui .ant-menu-horizontal:not(.ant-menu-dark).ant-menu-horizontal:not(.ant-menu-blue) > .ant-menu-item-active,
  .ys-webrtc-sdk-ui .ant-menu-horizontal:not(.ant-menu-dark).ant-menu-horizontal:not(.ant-menu-blue) > .ant-menu-submenu-active,
  .ys-webrtc-sdk-ui .ant-menu-horizontal:not(.ant-menu-dark).ant-menu-horizontal:not(.ant-menu-blue) > .ant-menu-item-open,
  .ys-webrtc-sdk-ui .ant-menu-horizontal:not(.ant-menu-dark).ant-menu-horizontal:not(.ant-menu-blue) > .ant-menu-submenu-open,
  .ys-webrtc-sdk-ui .ant-menu-horizontal:not(.ant-menu-dark).ant-menu-horizontal:not(.ant-menu-blue) > .ant-menu-item-selected,
  .ys-webrtc-sdk-ui .ant-menu-horizontal:not(.ant-menu-dark).ant-menu-horizontal:not(.ant-menu-blue) > .ant-menu-submenu-selected {
    color: #006ee1;
    border-bottom: 2px solid #006ee1;
  }
  .ys-webrtc-sdk-ui .ant-menu-horizontal > .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-horizontal > .ant-menu-submenu {
    position: relative;
    top: 1px;
    display: inline-block;
    vertical-align: bottom;
    border-bottom: 2px solid transparent;
  }
  .ys-webrtc-sdk-ui .ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 0;
  }
  .ys-webrtc-sdk-ui .ant-menu-horizontal > .ant-menu-item a {
    color: #778090;
  }
  .ys-webrtc-sdk-ui .ant-menu-horizontal > .ant-menu-item a:hover {
    color: #006ee1;
  }
  .ys-webrtc-sdk-ui .ant-menu-horizontal > .ant-menu-item a::before {
    bottom: -2px;
  }
  .ys-webrtc-sdk-ui .ant-menu-horizontal > .ant-menu-item-selected a {
    color: #006ee1;
  }
  .ys-webrtc-sdk-ui .ant-menu-horizontal::after {
    display: block;
    clear: both;
    height: 0;
    content: '\20';
  }
  .ys-webrtc-sdk-ui .ant-menu-vertical .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-vertical-left .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-vertical-right .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-inline .ant-menu-item {
    position: relative;
  }
  .ys-webrtc-sdk-ui .ant-menu-vertical .ant-menu-item::after,
  .ys-webrtc-sdk-ui .ant-menu-vertical-left .ant-menu-item::after,
  .ys-webrtc-sdk-ui .ant-menu-vertical-right .ant-menu-item::after,
  .ys-webrtc-sdk-ui .ant-menu-inline .ant-menu-item::after {
    position: absolute;
    top: 0;
    right: calc(100% - 3px);
    bottom: 0;
    border-right: 3px solid #006ee1;
    -webkit-transform: scaleY(0.0001);
            transform: scaleY(0.0001);
    opacity: 0;
    transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    content: '';
  }
  .ys-webrtc-sdk-ui .ant-menu-vertical .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-vertical-left .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-vertical-right .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-inline .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-vertical .ant-menu-submenu-title,
  .ys-webrtc-sdk-ui .ant-menu-vertical-left .ant-menu-submenu-title,
  .ys-webrtc-sdk-ui .ant-menu-vertical-right .ant-menu-submenu-title,
  .ys-webrtc-sdk-ui .ant-menu-inline .ant-menu-submenu-title {
    height: 48px;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0 16px;
    overflow: hidden;
    line-height: 48px;
    text-overflow: ellipsis;
  }
  .ys-webrtc-sdk-ui .ant-menu-vertical .ant-menu-submenu,
  .ys-webrtc-sdk-ui .ant-menu-vertical-left .ant-menu-submenu,
  .ys-webrtc-sdk-ui .ant-menu-vertical-right .ant-menu-submenu,
  .ys-webrtc-sdk-ui .ant-menu-inline .ant-menu-submenu {
    padding-bottom: 0.02px;
  }
  .ys-webrtc-sdk-ui .ant-menu-vertical .ant-menu-item:not(:last-child),
  .ys-webrtc-sdk-ui .ant-menu-vertical-left .ant-menu-item:not(:last-child),
  .ys-webrtc-sdk-ui .ant-menu-vertical-right .ant-menu-item:not(:last-child),
  .ys-webrtc-sdk-ui .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 0;
  }
  .ys-webrtc-sdk-ui .ant-menu-vertical > .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-vertical-left > .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-vertical-right > .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-inline > .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
  .ys-webrtc-sdk-ui .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
  .ys-webrtc-sdk-ui .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
  .ys-webrtc-sdk-ui .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 48px;
    line-height: 48px;
  }
  .ys-webrtc-sdk-ui .ant-menu-vertical .ant-menu-submenu-title {
    padding-right: 34px;
  }
  .ys-webrtc-sdk-ui .ant-menu-inline {
    width: 100%;
  }
  .ys-webrtc-sdk-ui .ant-menu-inline .ant-menu-selected::after,
  .ys-webrtc-sdk-ui .ant-menu-inline .ant-menu-item-selected::after {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    opacity: 1;
    transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .ys-webrtc-sdk-ui .ant-menu-inline .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-inline .ant-menu-submenu-title {
    width: calc(100% + 1px);
  }
  .ys-webrtc-sdk-ui .ant-menu-inline .ant-menu-submenu-title {
    padding-right: 34px;
  }
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed {
    width: 52px;
  }
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed > .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    left: 0;
    padding: 0 calc(50% - 16px / 2);
    text-overflow: clip;
  }
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
    display: none;
  }
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon,
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed > .ant-menu-item .anticon,
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
    margin: 0;
    font-size: 16px;
    line-height: 48px;
  }
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon + span,
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon + span,
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span,
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span,
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
    display: inline-block;
    max-width: 0;
    opacity: 0;
  }
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed .ant-menu-item-icon,
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed .anticon {
    display: inline-block;
  }
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed-tooltip {
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed-tooltip .ant-menu-item-icon,
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed-tooltip .anticon {
    display: none;
  }
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed-tooltip a {
    color: rgba(255, 255, 255, 0.87);
  }
  .ys-webrtc-sdk-ui .ant-menu-inline-collapsed .ant-menu-item-group-title {
    padding-right: 4px;
    padding-left: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .ys-webrtc-sdk-ui .ant-menu-item-group-list {
    margin: 0;
    padding: 0;
  }
  .ys-webrtc-sdk-ui .ant-menu-item-group-list .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-item-group-list .ant-menu-submenu-title {
    padding: 0 16px 0 28px;
  }
  .ys-webrtc-sdk-ui .ant-menu-root.ant-menu-vertical,
  .ys-webrtc-sdk-ui .ant-menu-root.ant-menu-vertical-left,
  .ys-webrtc-sdk-ui .ant-menu-root.ant-menu-vertical-right,
  .ys-webrtc-sdk-ui .ant-menu-root.ant-menu-inline {
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-menu-root.ant-menu-inline-collapsed .ant-menu-item > .ant-menu-inline-collapsed-noicon,
  .ys-webrtc-sdk-ui .ant-menu-root.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title > .ant-menu-inline-collapsed-noicon {
    font-size: 16px;
    text-align: center;
  }
  .ys-webrtc-sdk-ui .ant-menu-sub.ant-menu-inline {
    padding: 0;
    background: #fff;
    border: 0;
    border-radius: 0;
    box-shadow: none;
  }
  .ys-webrtc-sdk-ui .ant-menu-sub.ant-menu-inline > .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 48px;
    line-height: 48px;
    list-style-position: inside;
    list-style-type: disc;
  }
  .ys-webrtc-sdk-ui .ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
    padding-left: 32px;
  }
  .ys-webrtc-sdk-ui .ant-menu-item-disabled,
  .ys-webrtc-sdk-ui .ant-menu-submenu-disabled {
    color: #b1b9c3 !important;
    background: none;
    border-color: transparent !important;
    cursor: not-allowed;
  }
  .ys-webrtc-sdk-ui .ant-menu-item-disabled a,
  .ys-webrtc-sdk-ui .ant-menu-submenu-disabled a {
    color: #b1b9c3 !important;
    pointer-events: none;
  }
  .ys-webrtc-sdk-ui .ant-menu-item-disabled > .ant-menu-submenu-title,
  .ys-webrtc-sdk-ui .ant-menu-submenu-disabled > .ant-menu-submenu-title {
    color: #b1b9c3 !important;
    cursor: not-allowed;
  }
  .ys-webrtc-sdk-ui .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
    background: #b1b9c3 !important;
  }
  .ys-webrtc-sdk-ui .ant-layout-header .ant-menu {
    line-height: inherit;
  }
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-dark,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-sub,
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-dark .ant-menu-sub {
    color: rgba(255, 255, 255, 0.6);
    background: #001529;
  }
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
    opacity: 0.45;
    transition: all 0.3s;
  }
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
    background: #fff;
  }
  .ys-webrtc-sdk-ui .ant-menu-dark.ant-menu-submenu-popup {
    background: transparent;
  }
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #000c17;
  }
  .ys-webrtc-sdk-ui .ant-menu-dark.ant-menu-horizontal {
    border-bottom: 0;
  }
  .ys-webrtc-sdk-ui .ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
    top: 0;
    margin-top: 0;
    padding: 0 20px;
    border-color: #001529;
    border-bottom: 0;
  }
  .ys-webrtc-sdk-ui .ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover:not(.ant-menu-item-disabled) {
    background-color: #006ee1;
  }
  .ys-webrtc-sdk-ui .ant-menu-dark.ant-menu-horizontal > .ant-menu-item > a::before {
    bottom: 0;
  }
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item-group-title,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item > a,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item > span > a {
    color: rgba(255, 255, 255, 0.6);
  }
  .ys-webrtc-sdk-ui .ant-menu-dark.ant-menu-inline,
  .ys-webrtc-sdk-ui .ant-menu-dark.ant-menu-vertical,
  .ys-webrtc-sdk-ui .ant-menu-dark.ant-menu-vertical-left,
  .ys-webrtc-sdk-ui .ant-menu-dark.ant-menu-vertical-right {
    border-right: 0;
  }
  .ys-webrtc-sdk-ui .ant-menu-dark.ant-menu-inline .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-dark.ant-menu-vertical .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
    left: 0;
    margin-left: 0;
    border-right: 0;
  }
  .ys-webrtc-sdk-ui .ant-menu-dark.ant-menu-inline .ant-menu-item::after,
  .ys-webrtc-sdk-ui .ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
  .ys-webrtc-sdk-ui .ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
  .ys-webrtc-sdk-ui .ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
    border-right: 0;
  }
  .ys-webrtc-sdk-ui .ant-menu-dark.ant-menu-inline .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
    width: 100%;
  }
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item:hover,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item-active,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-active,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-open,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-selected,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-title:hover {
    color: #fff;
    background-color: transparent;
  }
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item:hover > a,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item-active > a,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-active > a,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-open > a,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-selected > a,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-title:hover > a,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item:hover > span > a,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item-active > span > a,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-active > span > a,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-open > span > a,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-selected > span > a,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-title:hover > span > a {
    color: #fff;
  }
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow {
    opacity: 1;
  }
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before {
    background: #fff;
  }
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item:hover {
    background-color: transparent;
  }
  .ys-webrtc-sdk-ui .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #006ee1;
  }
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item-selected {
    color: #fff;
    border-right: 0;
  }
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item-selected::after {
    border-right: 0;
  }
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item-selected > a,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item-selected > span > a,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item-selected > a:hover,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item-selected > span > a:hover {
    color: #fff;
  }
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item-selected .anticon {
    color: #fff;
  }
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item-selected .anticon + span {
    color: #fff;
  }
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-dark .ant-menu-item-selected,
  .ys-webrtc-sdk-ui .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: #006ee1;
  }
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item-disabled,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-disabled,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item-disabled > a,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-disabled > a,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item-disabled > span > a,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-disabled > span > a {
    color: rgba(255, 255, 255, 0.35) !important;
    opacity: 0.8;
  }
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
    color: rgba(255, 255, 255, 0.35) !important;
  }
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
    background: rgba(255, 255, 255, 0.35) !important;
  }
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-dark.ant-menu-inline-collapsed.ant-menu-vertical .ant-menu-submenu-selected {
    background-color: #006ee1;
  }
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-blue,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-sub,
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-blue .ant-menu-sub {
    color: rgba(255, 255, 255, 0.7);
    background: #006ee1;
  }
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-blue .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-blue .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
    opacity: 0.45;
    transition: all 0.3s;
  }
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-blue .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-blue .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-blue .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-blue .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
    background: rgba(255, 255, 255, 0.7);
  }
  .ys-webrtc-sdk-ui .ant-menu-blue.ant-menu-submenu-popup {
    background: transparent;
  }
  .ys-webrtc-sdk-ui .ant-menu-blue.ant-menu-submenu-popup .ant-menu-sub.ant-menu-vertical {
    color: #23282f;
    background-color: #fff;
  }
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-inline.ant-menu-sub {
    background: #006ee1;
  }
  .ys-webrtc-sdk-ui .ant-menu-blue.ant-menu-horizontal {
    border-bottom: 0;
  }
  .ys-webrtc-sdk-ui .ant-menu-blue.ant-menu-horizontal > .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-blue.ant-menu-horizontal > .ant-menu-submenu {
    top: 0;
    margin-top: 0;
    padding: 0 20px;
    border-color: #006ee1;
    border-bottom: 0;
  }
  .ys-webrtc-sdk-ui .ant-menu-blue.ant-menu-horizontal > .ant-menu-item:hover:not(.ant-menu-item-disabled) {
    background-color: #e6e9ec;
  }
  .ys-webrtc-sdk-ui .ant-menu-blue.ant-menu-horizontal > .ant-menu-item > a::before {
    bottom: 0;
  }
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item-group-title,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item > a,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item > span > a {
    color: rgba(255, 255, 255, 0.7);
  }
  .ys-webrtc-sdk-ui .ant-menu-blue.ant-menu-inline,
  .ys-webrtc-sdk-ui .ant-menu-blue.ant-menu-vertical,
  .ys-webrtc-sdk-ui .ant-menu-blue.ant-menu-vertical-left,
  .ys-webrtc-sdk-ui .ant-menu-blue.ant-menu-vertical-right {
    border-right: 0;
  }
  .ys-webrtc-sdk-ui .ant-menu-blue.ant-menu-inline .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-blue.ant-menu-vertical .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-blue.ant-menu-vertical-left .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-blue.ant-menu-vertical-right .ant-menu-item {
    left: 0;
    margin-left: 0;
    border-right: 0;
  }
  .ys-webrtc-sdk-ui .ant-menu-blue.ant-menu-inline .ant-menu-item::after,
  .ys-webrtc-sdk-ui .ant-menu-blue.ant-menu-vertical .ant-menu-item::after,
  .ys-webrtc-sdk-ui .ant-menu-blue.ant-menu-vertical-left .ant-menu-item::after,
  .ys-webrtc-sdk-ui .ant-menu-blue.ant-menu-vertical-right .ant-menu-item::after {
    border-right: 0;
  }
  .ys-webrtc-sdk-ui .ant-menu-blue.ant-menu-inline .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-blue.ant-menu-inline .ant-menu-submenu-title {
    width: 100%;
  }
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item:hover,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item-active,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-submenu-active,
  .ys-webrtc-sdk-ui .ant-menu-blue:not(.ant-menu-submenu-popup) .ant-menu-submenu-selected,
  .ys-webrtc-sdk-ui .ant-menu-blue:not(.ant-menu-submenu-popup) .ant-menu-submenu-title:hover {
    color: #fff;
    background-color: transparent;
  }
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item:hover > a,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item-active > a,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-submenu-active > a,
  .ys-webrtc-sdk-ui .ant-menu-blue:not(.ant-menu-submenu-popup) .ant-menu-submenu-selected > a,
  .ys-webrtc-sdk-ui .ant-menu-blue:not(.ant-menu-submenu-popup) .ant-menu-submenu-title:hover > a,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item:hover > span > a,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item-active > span > a,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-submenu-active > span > a,
  .ys-webrtc-sdk-ui .ant-menu-blue:not(.ant-menu-submenu-popup) .ant-menu-submenu-selected > span > a,
  .ys-webrtc-sdk-ui .ant-menu-blue:not(.ant-menu-submenu-popup) .ant-menu-submenu-title:hover > span > a {
    color: #fff;
  }
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-menu-blue:not(.ant-menu-submenu-popup) .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-menu-blue:not(.ant-menu-submenu-popup) .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-menu-blue:not(.ant-menu-submenu-popup) .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-menu-blue:not(.ant-menu-submenu-popup) .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow {
    opacity: 1;
  }
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu-blue:not(.ant-menu-submenu-popup) .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu-blue:not(.ant-menu-submenu-popup) .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu-blue:not(.ant-menu-submenu-popup) .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu-blue:not(.ant-menu-submenu-popup) .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu-blue:not(.ant-menu-submenu-popup) .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu-blue:not(.ant-menu-submenu-popup) .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu-blue:not(.ant-menu-submenu-popup) .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu-blue:not(.ant-menu-submenu-popup) .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before {
    background: #fff;
  }
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-blue.ant-menu-submenu-popup .ant-menu-submenu.ant-menu-submenu-vertical > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-blue.ant-menu-submenu-popup .ant-menu-submenu.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow {
    opacity: 1;
  }
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-blue.ant-menu-submenu-popup .ant-menu-submenu.ant-menu-submenu-vertical > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-blue.ant-menu-submenu-popup .ant-menu-submenu.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-blue.ant-menu-submenu-popup .ant-menu-submenu.ant-menu-submenu-vertical > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-blue.ant-menu-submenu-popup .ant-menu-submenu.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before {
    background: #c8cfd5;
  }
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-blue.ant-menu-submenu-popup .ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-blue.ant-menu-submenu-popup .ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-blue.ant-menu-submenu-popup .ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-blue.ant-menu-submenu-popup .ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow {
    opacity: 1;
  }
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-blue.ant-menu-submenu-popup .ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-blue.ant-menu-submenu-popup .ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-blue.ant-menu-submenu-popup .ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-blue.ant-menu-submenu-popup .ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-blue.ant-menu-submenu-popup .ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-blue.ant-menu-submenu-popup .ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-blue.ant-menu-submenu-popup .ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-blue.ant-menu-submenu-popup .ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before {
    background: #006ee1;
  }
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item:hover:not(.ant-menu-item-disabled) {
    background-color: #f6f8fa;
  }
  .ys-webrtc-sdk-ui .ant-menu-blue.ant-menu-blue:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #e6e9ec;
  }
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item-selected {
    color: #fff;
    border-right: 0;
  }
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item-selected::after {
    border-right: 0;
  }
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item-selected > a,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item-selected > span > a,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item-selected > a:hover,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item-selected > span > a:hover {
    color: #fff;
  }
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item-selected .ant-menu-item-icon,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item-selected .anticon {
    color: #fff;
  }
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item-selected .ant-menu-item-icon + span,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item-selected .anticon + span {
    color: #fff;
  }
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-blue .ant-menu-item-selected,
  .ys-webrtc-sdk-ui .ant-menu-submenu-popup.ant-menu-blue .ant-menu-item-selected {
    background-color: #e6e9ec;
  }
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item-disabled,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-submenu-disabled,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item-disabled > a,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-submenu-disabled > a,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item-disabled > span > a,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-submenu-disabled > span > a {
    color: #c8cfd5 !important;
    opacity: 0.8;
  }
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item-disabled > .ant-menu-submenu-title,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-submenu-disabled > .ant-menu-submenu-title {
    color: #c8cfd5 !important;
  }
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
    background: #c8cfd5 !important;
  }
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item-disabled,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item-disabled > a,
  .ys-webrtc-sdk-ui .ant-menu-blue .ant-menu-item-disabled > span > a {
    color: rgba(255, 255, 255, 0.38) !important;
    opacity: 0.8;
  }
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-blue.ant-menu-inline-collapsed.ant-menu-vertical .ant-menu-submenu-selected {
    background-color: #e6e9ec;
  }
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-blue.ant-menu-submenu-popup .ant-menu-item-group-title {
    color: #99a5b2;
  }
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-blue.ant-menu-submenu-popup .ant-menu-item {
    color: #23282f;
  }
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-blue.ant-menu-submenu-popup .ant-menu-item-selected {
    background-color: #e6f6ff;
  }
  .ys-webrtc-sdk-ui .ant-menu.ant-menu-rtl {
    direction: rtl;
    text-align: right;
  }
  .ys-webrtc-sdk-ui .ant-menu-rtl .ant-menu-item-group-title {
    text-align: right;
  }
  .ys-webrtc-sdk-ui .ant-menu-rtl.ant-menu-inline,
  .ys-webrtc-sdk-ui .ant-menu-rtl.ant-menu-vertical {
    border-right: none;
    border-left: 1px solid #e6e9ec;
  }
  .ys-webrtc-sdk-ui .ant-menu-rtl.ant-menu-dark.ant-menu-inline,
  .ys-webrtc-sdk-ui .ant-menu-rtl.ant-menu-dark.ant-menu-vertical,
  .ys-webrtc-sdk-ui .ant-menu-rtl.ant-menu-blue.ant-menu-inline,
  .ys-webrtc-sdk-ui .ant-menu-rtl.ant-menu-blue.ant-menu-vertical {
    border-left: none;
  }
  .ys-webrtc-sdk-ui .ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
  .ys-webrtc-sdk-ui .ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
  .ys-webrtc-sdk-ui .ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
    -webkit-transform-origin: top right;
            transform-origin: top right;
  }
  .ys-webrtc-sdk-ui .ant-menu-rtl .ant-menu-item .ant-menu-item-icon,
  .ys-webrtc-sdk-ui .ant-menu-rtl .ant-menu-submenu-title .ant-menu-item-icon,
  .ys-webrtc-sdk-ui .ant-menu-rtl .ant-menu-item .anticon,
  .ys-webrtc-sdk-ui .ant-menu-rtl .ant-menu-submenu-title .anticon {
    margin-right: auto;
    margin-left: 12px;
  }
  .ys-webrtc-sdk-ui .ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
  .ys-webrtc-sdk-ui .ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon,
  .ys-webrtc-sdk-ui .ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .anticon,
  .ys-webrtc-sdk-ui .ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .anticon {
    margin-left: 0;
  }
  .ys-webrtc-sdk-ui .ant-menu-submenu-rtl.ant-menu-submenu-popup {
    -webkit-transform-origin: 100% 0;
            transform-origin: 100% 0;
  }
  .ys-webrtc-sdk-ui .ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
  .ys-webrtc-sdk-ui .ant-menu-rtl .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
    right: auto;
    left: 16px;
  }
  .ys-webrtc-sdk-ui .ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
  .ys-webrtc-sdk-ui .ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
    -webkit-transform: rotate(-45deg) translateY(-2px);
            transform: rotate(-45deg) translateY(-2px);
  }
  .ys-webrtc-sdk-ui .ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
  .ys-webrtc-sdk-ui .ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
    -webkit-transform: rotate(45deg) translateY(2px);
            transform: rotate(45deg) translateY(2px);
  }
  .ys-webrtc-sdk-ui .ant-menu-rtl.ant-menu-vertical .ant-menu-item::after,
  .ys-webrtc-sdk-ui .ant-menu-rtl.ant-menu-vertical-left .ant-menu-item::after,
  .ys-webrtc-sdk-ui .ant-menu-rtl.ant-menu-vertical-right .ant-menu-item::after,
  .ys-webrtc-sdk-ui .ant-menu-rtl.ant-menu-inline .ant-menu-item::after {
    right: auto;
    left: 0;
  }
  .ys-webrtc-sdk-ui .ant-menu-rtl.ant-menu-vertical .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-rtl.ant-menu-vertical-left .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-rtl.ant-menu-vertical-right .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-rtl.ant-menu-inline .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title,
  .ys-webrtc-sdk-ui .ant-menu-rtl.ant-menu-vertical-left .ant-menu-submenu-title,
  .ys-webrtc-sdk-ui .ant-menu-rtl.ant-menu-vertical-right .ant-menu-submenu-title,
  .ys-webrtc-sdk-ui .ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
    text-align: right;
  }
  .ys-webrtc-sdk-ui .ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
    padding-right: 0;
    padding-left: 34px;
  }
  .ys-webrtc-sdk-ui .ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title {
    padding-right: 16px;
    padding-left: 34px;
  }
  .ys-webrtc-sdk-ui .ant-menu-rtl.ant-menu-inline-collapsed.ant-menu-vertical .ant-menu-submenu-title {
    padding: 0 calc(50% - 16px / 2);
  }
  .ys-webrtc-sdk-ui .ant-menu-rtl .ant-menu-item-group-list .ant-menu-item,
  .ys-webrtc-sdk-ui .ant-menu-rtl .ant-menu-item-group-list .ant-menu-submenu-title {
    padding: 0 28px 0 16px;
  }
  .ys-webrtc-sdk-ui .ant-menu-sub.ant-menu-inline {
    border: 0;
  }
  .ys-webrtc-sdk-ui .ant-menu-rtl.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
    padding-right: 32px;
    padding-left: 0;
  }
  
  .ys-webrtc-sdk-ui {
    margin: 0;
    color: #23282f;
    font-size: 14px;
    font-family: -apple-system, 'Helvetica Neue', 'Helvetica', 'PingFang SC', 'SegoeUI', 'Microsoft YaHei', '微软雅黑', 'Noto Sans CJK SC', 'Arial', sans-serif;
    font-variant: tabular-nums;
    line-height: 1.57142857;
    background-color: #fff;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: 'scrollbar';
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .ys-webrtc-sdk-ui .ys-message-container {
    width: 1px;
    height: 1px;
    position: fixed;
    top: 0;
    left: 0;
  }
  
  .ysd-tooltip-middle {
    display: none;
  }
  .incoming-detail .name {
    display: none;
  }
  .incoming-detail .title {
    display: none;
  }
  .incoming-detail .phone {
    display: none;}
  .full-page-loader {
    height         : 80dvh;
    display        : flex;
    justify-content: center;
    align-items    : center;
  }