/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

/* You can add global styles to this file, and also import other style files */
@import '~dropzone/dist/min/dropzone.min.css';

.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5)
  }

  100% {
    transform: scale(1)
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.position-right {
  position: absolute;
  right: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}

ngx-dropzone {
  border: 2px dashed var(--bs-border-color) !important;
}





//
// Dropzone
//

.dropzone {
  min-height: 230px !important;
  border: 2px dashed #eff2f7 !important;
  background: #fff !important;
  border-radius: 6px !important;
  text-align: center;

  .dz-message {
    width: 100%;
    margin: 1em 0;
  }
}

[data-dz-thumbnail] {
  width: 100%;
  height: 100%;
}

.dropzone .dz-preview .dz-image {
  border-radius: 20px;
  overflow: hidden;
  width: 120px;
  height: 120px;
  position: relative;
  display: block;
  z-index: 10;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropzone .dz-preview .dz-image img {
  display: block;
  height: 90px;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropzone .dz-preview .dz-remove {
  margin-top: 10px;
}

dropzone>.dropzone.dz-wrapper .dz-message {
  background-color: transparent !important;
  border: none !important;
  overflow: inherit !important;
  width: inherit !important;
  min-width: inherit !important;
  min-height: 180px !important;
  max-width: inherit !important;
  max-height: inherit !important;
  margin: 0px !important;
}

.dz-text {
  font-size: 1.21875rem
}

dropzone>.dropzone.dz-wrapper {
  min-height: 76px !important;
  border: none !important;
}


#component-colorpicker {

  color-picker {
    .open {
      right: 0px !important;
      left: auto !important;
      z-index: 9999 !important;
    }
  }
}

.color-picker {
  .arrow.arrow-bottom {
    right: 20px !important;
    left: auto !important;
  }
}

.switch.switch-small>.switch-pane>span {
  font-size: 12px !important;
}

.modal-dialog {
  top: auto !important;
  bottom: auto !important;
}

.slick-dots {
  margin-top: 20px;
  bottom: -40px !important;


  li.slick-active button:before {
    color: #556ee6 !important;
    font-size: x-small !important;
  }
}

.slick-dots li button:before {
  color: #556ee6;
  font-size: x-small !important;
}

.slick-slide img {
  width: 100%;
}

.dropup {
  .dropdown-menu {
    inset: auto auto auto 0px !important;
    transform: translateY(-110px) !important;
  }
}

.ngx-slider .ngx-slider-tick.ngx-slider-selected {
  background-color: #556ee6 !important;
}

/* set the background color of many elements across the grid */
.ag-theme-quartz {
  --ag-background-color: #ddd;
}

/* change the font style of a single UI component */
.ag-theme-quartz .ag-header-cell-label {
  font-style: bold;
}

/* Container Styling */
.table_grid_fixed_height {
  height: calc(90vh - 100px)
}

.modal-header {
  background-color: #556ee6;
  color: #fff;
}

.flag-img {
  width: 32px;
  margin-right: 0.5rem;
  position: relative;
  display: inline-block;
}


.error-msg {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: var(--bs-form-invalid-color);
}

em {
  color: #f00;
}

.invalid-select {
  .ng-select-container {
    border-color: var(--bs-form-invalid-border-color);
    padding-right: calc(1.5em + 0.94rem);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23e31480'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23e31480' stroke='none'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.235rem) center;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
  }
}

.full-page-loader {
  height: 80dvh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.small-spinner {
  height: 1rem !important;
  width: 1rem !important;
  border-width: 2px !important;
}

.block-btn {
  display: block;
  width: 100%;
}

.grid_fixed_height {
  height: 65vh;
  overflow-y: scroll;
}

.calling_profile_img {
  margin: 0 auto;
  width: 10em;
  height: 10em;

  img {
    margin: 0 auto;
    width: 100%;
    border-radius: 50%;
  }


}

body::-webkit-scrollbar {
  width: 7px;
}

body::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
}

body::-webkit-scrollbar-thumb {
  background-color: #556ee6;
  border-radius: 50px;

}

.ml-3 {
  margin-left: 1em;
}

.page-heading {
  width: 100%;
  padding: 10px;
  border-radius: 5px 5px 0 0;
}

.form_heading {
  float: left;
  width: 100%;
  background-color: #e7e7e7;
  padding: 5px 10px;

  h4 {
    margin-bottom: 0;
  }
}


.invalid-control {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: var(--bs-form-invalid-color);
}

.valid-control {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: var(--bs-form-valid-color);
}


.invalid-control {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: var(--bs-form-invalid-color);
}

.valid-control {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: var(--bs-form-valid-color);
}

.ysd-tooltip-middle {

  display: block !important;

}

.info {
  display: block !important;
  color: white !important;
  width: auto !important;
  height: auto !important;
}

.phone {
  display: block !important;
  color: white !important;
  width: auto !important;
  height: auto !important;
}

#callingdialler_call_hide_show_button {
  position: fixed;
  padding: 10px;
  z-index: 10;
  top: 0;
  right: 0;
  display: none;
  background: rgb(163, 245, 213);
  border-radius: 40%;
  transition: background-color 0.3s ease;
  animation: color-change 3s infinite alternate;
  cursor: pointer;
}

@keyframes color-change {
  0% {
   color : rgb(243, 52, 52);
  }

  25% {
   color : rgb(55, 55, 215);
  }

  50% {
   color : rgb(64, 227, 64);
  }

  100% {
    color : rgb(138, 27, 8);

  }
  
}

#callingdialler_call_hide_show_button > i {
  font-size: 45px !important;
}