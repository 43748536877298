// Lightbox
@import "~ngx-lightbox/lightbox.css";

// Select 2 
@import "~@ng-select/ng-select/themes/default.theme.css";

// Date Picker
@import '~flatpickr/dist/flatpickr.css';

// LeafLet Chart
@import 'leaflet/dist/leaflet.css';

// Emoji Picker
// @import '~@ctrl/ngx-emoji-mart/picker';
.emoji-mart {
    position: absolute;
    bottom: 20px;
    left: -80px;
}

.emoji-mart-preview {
    display: none;
}



// dropdown
.dropdown-toggle {
    &:after {
        display: none;
    }
}

.modal-backdrop {
    z-index: 1002 !important;
}

.dropdown-toggle::after {
    display: none;
}

.dropzone .dz-message {
    font-size: 24px;
    min-height: 150px !important;
    border: none !important;
    // background: #fff !important;
    color: #495057 !important;
}

// ----- Advance Ui --------//
// Rating
.stars {
    font-size: 30px;
    color: var(--bs-gray-600);
}

.star {
    position: relative;
    display: inline-block;
}

.star_fill {
    color: $gray-500;
}

.full {
    color: #b0c4de;
}

.half {
    position: absolute;
    display: inline-block;
    overflow: hidden;
    color: var(--bs-gray-600);
}

.filled {
    color: #1e90ff;
}

.bad {
    color: #deb0b0;
}

.filled.bad {
    color: #ff1e1e;
}

.halfHeart {
    position: absolute;
    display: inline-block;
    overflow: hidden;
    color: red;
}

// Calendar
.flatpickr-calendar.open {
    z-index: 1061;
}

// Swich Btn
.switch.switch-small.checked>.switch-pane>span {
    color: #fff;
}

.switch.switch-small>.switch-pane>span {
    font-size: 14px;
}

// Ck Editer
.ck-editor__editable {
    min-height: 245px !important;
}

.custom-accordion {
    .accordion-list {
        padding: 0px;
        background-color: transparent;
        margin-bottom: 20px
    }
}

[data-bs-theme="dark"] {
    .ng-select .ng-select-container {
        background-color: #2a3042 !important;
        color: #c3cbe4 !important;
        border-color: #353d55 !important;
    }

    .ng-dropdown-panel {
        background-color: #2a3042 !important;
        border-color: #353d55 !important;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
        background-color: #2a3042 !important;
        border-color: #353d55 !important;

        color: #c3cbe4 !important;
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
        background-color: #393f55 !important;
        color: #d6dbeb !important;
    }
}

//dropzone
.dropzone>.dropzone.dz-wrapper .dz-message {
    background: var(--#{$prefix}custom-white) !important;
    color: var(--#{$prefix}body-color) !important;
}

// ck editor
.ck.ck-toolbar {
    background-color: var(--#{$prefix}light) !important;
    border: 1px solid $input-border-color !important;
}

.ck-reset_all :not(.ck-reset_all-excluded *),
.ck.ck-reset_all {
    color: var(--#{$prefix}body-color) !important;
}

.ck.ck-editor__main>.ck-editor__editable {
    background-color: var(--#{$prefix}custom-white) !important;
    color: var(--#{$prefix}body-color) !important;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    border-color: $input-border-color !important;
}

.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover,
.ck.ck-button.ck-on,
a.ck.ck-button.ck-on {
    background-color: var(--#{$prefix}light) !important;
}

.ck.ck-toolbar .ck.ck-toolbar__separator {
    background: $input-border-color !important;
}


// Dark Mode Css
[data-bs-theme="dark"] {
    .bs-datepicker {
        box-shadow: none !important;
    }

    .bs-datepicker-container {
        background-color: #32394e !important;

    }

    .bs-datepicker-head button {
        color: #c3cbe4 !important;
    }

    .bs-datepicker-body {
        border: 1px solid transparent;
    }

    .auth-logo .auth-logo-light {
        display: block;
    }

    .auth-logo .auth-logo-dark {
        display: none;
    }

    // Accordion
    .accordion {
        --bs-accordion-color: #fff;
        --bs-accordion-bg: transparent;
    }

    .accordion-button {
        color: var(--bs-body-color);
    }
}

.page-link {
    display: inline-table;
}


//   vertical tab

.vertical-tab {
    ul {
        display: inline-block;
        position: relative;
        z-index: 1;
        border: 0;

        @media (max-width: 1024px) {
            display: block;
        }

        li {
            a {
                padding: 8px 50px;

                @media (max-width: 1440px) {
                    padding: 8px 30px;
                }
            }
        }
    }

    .tab-content {
        position: absolute;
        top: 0;

        @media (max-width: 1024px) {
            position: inherit;
        }

    }
}


.ecommerce-checkout-tab {
    ul {
        display: inline-block;
        // float: left;
        margin-right: 20px;
        border: 0;

        @media (max-width: 600px) {
            display: block;
            float: inherit;
        }
    }

    li {
        a {
            padding: 1px 80px;
        }
    }
}

.product-detai-imgs {
    ul {
        border: 0;

        a {
            border: 0 !important;
            width: 130px;

            @media (max-width: 425px) {
                width: 50px;
            }
        }
    }
}

.tooltip,
.tooltip-arrow {
    position: absolute;
}

[data-layout-size="boxed"] {
    .vertical-tab {
        ul {
            display: inline-block;
            position: relative;
            z-index: 1;
            border: 0;

            @media (max-width: 1024px) {
                display: block;
            }

            li {
                a {
                    padding: 8px 50px;
                }
            }
        }
    }

}

.bs-datepicker-head {
    background-color: transparent !important;

}

.bs-datepicker-head button {
    color: black !important;
}

.owl-theme .owl-nav [class*=owl-] {
    width: 30px;
    height: 30px;
    line-height: 28px !important;
    font-size: 30px !important;
    border-radius: 50% !important;
    background-color: transparent !important;
    color: #ffff !important;
    justify-content: center !important;
    margin: 4px 8px !important;
    display: inline-flex !important;
    align-items: center !important;
}

.tab-bg {
    .nav-tabs {
        background-color: var(--#{$prefix}light);
        margin-bottom: 20px;
    }

}


// popover
.bs-popover-top,
.bs-popover-bottom {
    margin-left: -8px !important;
}

.bs-popover-left .arrow,
.bs-popover-right .arrow {
    margin-top: -8px !important;
}

.custom-widget-nav {
    .nav-tabs {
        justify-content: flex-end;
        margin-top: -1.5rem;
    }
}

.custom-vertical-tabs {
    display: flex;
    align-items: flex-start;
    gap: 16px;


    .nav-tabs {
        flex-direction: column;

        @media (min-width: 992px) {
            flex-shrink: 0;
        }
    }

    .tab-content {
        @media (min-width: 992px) {
            flex-grow: 1;
        }
    }

    @media (max-width: 991px) {
        flex-direction: column;
        gap: 16px;

        .nav-tabs,
        .tab-content {
            width: 100%;
        }
    }
}


// nav-justify-center

.nav-justify-center {
    .nav-tabs {
        justify-content: center;
    }
}

.ngx-slider .ngx-slider-bar {
    background-color: var(--#{$prefix}tertiary-bg) !important;
}

#backdrop {
    &.offcanvas-backdrop {
        display: none;

        &.show {
            display: inline-block;
        }
    }
}

.start-90 {
    right: 90% !important;
}

.lead_filter_panel {
    width: 100%;
    overflow-x: auto;
    display: flex;
}

.account_amt {
    background-color: #fff;
    border-radius: 5px;
    min-width: 170px;
    justify-content: center;
    align-items: center;
    margin: 5px;
    padding: 5px;
    text-align: center;


    .account_amt_title {
        font-size: 0.75rem;
        display: block;
        width: 100%;
        border-bottom: 1px solid #e7e7e7;
    }

    .account_currency {
        font-size: 0.9rem;
        font-weight: 700;
        display: block;
        width: 100%;
    }

}


$complete: #556ee6;
$active: #556ee6;
$mute: #e6e6e6;



.stepsby {
    list-style: none;
    margin: 0;
    padding: 0;
    display: table;
    table-layout: fixed;
    width: 100%;
    color: darken($mute, 33%);
    height: 4rem;

    >.step {
        position: relative;
        display: table-cell;
        text-align: center;
        font-size: 12px;
        color: #6D6875;
        text-wrap: wrap;

        &:before {
            content: attr(data-step);
            display: block;
            margin: 0 auto;
            background: #ffffff;
            border: 2px solid $mute;
            color: $mute;
            width: 1.5rem;
            height: 1.5rem;
            text-align: center;
            margin-bottom: 10px;
            line-height: 22px;
            border-radius: 100%;
            position: relative;
            z-index: 1;
            font-weight: 700;
            font-size: 0.8rem;
        }

        &:after {
            content: '';
            position: absolute;
            display: block;
            background: $mute;
            width: 100%;
            height: 0.125rem;
            top: 0.7rem;
            left: 50%;
        }

        &:last-child:after {
            display: none;
        }

        &.is-complete {
            color: #6D6875;

            &:before {
                content: '\2713';
                color: $complete;
                background: #fef0e2;
                border: 2px solid $complete;
            }

            &:after {
                background: $complete;
            }
        }

        &.is-active {
            //font-size:1.5rem;
            color: #556ee6;

            &:before {
                color: #FFF;
                border: 2px solid $complete;
                background: $active;
                margin-bottom: 10px;
            }
        }
    }
}



.small-spinner {
    height: 1rem !important;
    width: 1rem !important;
    border-width: 2px !important;
}

ul.manage_list {
    li a {
        padding: 10px 12px !important;
    }

    li a:hover {
        color: #556ee6 !important;
    }
}

.btn_sm {
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
}

.bottom_social_section a {
    display: inline-block;
    border-radius: 50%;
    color: #556ee6;
    height: 36px;
    width: 36px;
    line-height: 36px;
    text-align: center;
    background-color: #dde2fa;
    margin: 0 6px;
    cursor: pointer;
    font-size: 20px;
}

.bottom_social_section a:hover {
    background-color: #556ee6;
    color: #fff;
}

/****Application module new ui CSS********/
ul.ul_list {
    padding: 0;
    margin: 0;

    li {
        width: 95%;
        margin: 8px 18px
    }

    li::marker {
        color: #556ee6;
    }
}

.status_band {
    width: 100%;
    padding: 5px;
    text-align: center;
    color: #fff;
    font-weight: 600;
}

.bg_rejected {
    background-color: #f9d2d3;
    color: #dd2120;
}

.bg_pending {
    background-color: #ffedcd;
    color: #ffa90c;
}

.bg_approved {
    background-color: #cfeadb;
    color: #0f984a;
}

.bg_under_review {
    background-color: #dde1fa;
    color: #556ee5;
}

p.leftside_list2 {
    margin: 0;
    padding: 8px;
    text-align: left;

    i {
        text-align: right;
        float: right;
    }
}

p.leftside_list3 {
    margin: 0;
    padding: 8px;
    text-align: left;

    i {
        text-align: left;
        float: left;
        margin: 3px 5px;
    }
}

.ecommerce-checkout-tab-message2 {
    ul {
        float: left;
        margin-right: 20px;
        border: 0;
        width: 250px;

        @media (max-width: 600px) {
            display: block;
            float: inherit;
        }
    }

    li {
        a {
            padding: 0;
        }
    }
}

/****Create Campaign module CSS Srj********/
.capaignTemplate_panel {
    float: left;
    width: 100%;
    height: 500px;
}

.CreateCampaignTitle {
    height: 40px;
    float: left;
    font-size: 14px;
    width: 100%;
}

i.msg_upload_icon {
    float: left;
    margin-top: 5px;
    margin-right: 10px;
}

.btnUploadImg {
    background-color: #259984;
    border-bottom: #259984;
    color: #fff;
}

.btnUploadImg:hover {
    background-color: #075e55;
    border-bottom: #075e55;
    color: #fff;
}

.campaignTemplate_preview {
    width: 95%;
    margin: 0 auto;
    background-color: #fff;
    padding: 10px;
    border: 1px solid #e7e7e7;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.capaignTemplate_panel_inner {
    padding: 15px;
}

img.campaignWhatsapp_icon {
    float: left;
    position: relative;
    top: -22px;
    left: -19px;
    width: 30px;
    height: 30px;
}

.about_visionway {
    display: block;
    width: 100%;

}

a.link_contact {
    display: block;
    width: 100%;
    padding: 10px;
    border-top: 1px solid #e7e7e7;
    text-align: center;
    word-wrap: break-word;
}

.templateImgPreview {
    display: block;
    padding: 10px;
}

.templateImgPreview img {
    margin: 0 auto;
    width: 100%;
}

.footer_campaign {
    width: 100%;
    display: block;
    margin-top: 3empx;
}

.campaign_message_body {
    display: block;
    width: 100%;
    margin-top: 10px;
}

.modal-header .btn-close {
    background-color: #fff !important;
    opacity: 1 !important;
    border-radius: 50%;
}


.conversation-list {
    max-width: 60%;
    display: block;
    margin-bottom: 15px;
}


img.chat_image_msg {
    width: 100%;
}

video.chat_image_msg {
    width: 100%;
}


/*********Internal chat css srj*****************/
.chatImgProfile {
    text-align: center;

    img {
        width: 140px;
        height: 140px;
        padding: 5px;
        background-color: #e7e7e7e7;
    }
}

.card_chat_profile {
    min-height: 450px;
    margin-bottom: 0;
}

.cat_gallery_img {
    width: 100%;
    margin: 0 auto;

    img {
        width: auto;
        height: 150px;
    }
}

.message-body {
    word-wrap: break-word;
    white-space: break-spaces;
}

.chat-conversation .right .conversation-list .ctext-wrap {
    a.fileNameLink {
        color: #fff !important;
    }
}

.chat-conversation .conversation-list .ctext-wrap {
    a.fileNameLink {
        color: #000 !important;
    }
}